import classNames from 'classnames';
import React from 'react';
import styles from './styles.module.scss';

const Subtitle = ({ children, className, ...otherProps }) => {
  return (
    <h2 className={classNames(styles.subtitle, className)} {...otherProps}>
      {children}
    </h2>
  );
};

export default Subtitle;
