import React from 'react';
import { t } from '../../../i18n';
import Modal from '../../modal';
import Button from '../../button';
import { ticketImage } from '../../assets';
import styles from './styles.module.scss';

const PaymentSentModal = ({ onResolve }) => (
  <Modal
    image={ticketImage}
    title={t('INVOICE_SENT_TITLE')}
    message={
      <>
        <div>{t('INVOICE_SENT_SUCCESS_1')}</div>
        <div className={styles.subtitle}>{t('INVOICE_SENT_SUCCESS_2')}</div>
      </>
    }
    onClose={onResolve}
    buttons={<Button text={t('OK')} type="green" onClick={onResolve} className={styles.okButton} />}
  />
);
export default PaymentSentModal;
