import React from 'react';
import { t } from '../../../../i18n';
import FilePreview from './components/file-preview';
import './styles.scss';

const ProfileMaterials = ({ materials }) => {
  return (
    !!materials.length && (
      <>
        <p className="profile-materials__title">{t('PUBLIC_UPLOADS')}</p>
        {materials.map(material => (
          <FilePreview key={material.id} data={material} />
        ))}
      </>
    )
  );
};

export default ProfileMaterials;
