import { takeLatest, put } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import get from 'lodash/get';
import Storage from '../storage';
import { t } from '../i18n';
import { logWarn, flushLogs } from '../utilities/log';
import { login } from '../backend-api';
import { LOGIN, loginFailed, loginSuccess, LOGOUT } from './actions';
import CurrentUserService from './services/CurrentUserService';

function* logIn({ email, password, role, rememberMe, returnUrl }) {
  const userName = `${role}_${email}`;
  try {
    const result = yield login(userName, password);
    yield put(loginSuccess(result.data));

    Storage.rememberMe = rememberMe;
    Storage.authToken = result.data.access_token;
    Storage.refreshToken = result.data.refresh_token;
    const { data: profile } = yield CurrentUserService.getUser();
    Storage.role = profile.type;

    if (returnUrl) {
      yield put(push(returnUrl));
    } else if (profile.type === 'Trainer') {
      yield put(push('/kalender'));
    } else if (profile.type === 'Administrator') {
      yield put(push('/admin'));
    } else {
      Storage.authToken = null;
      Storage.refreshToken = null;
      yield put(
        loginFailed({
          response: { data: { error: 'Du måste gå som tränare' } },
        }),
      );
    }
  } catch (err) {
    const error =
      err.response.status === 400 ? t('LOGIN_ERROR') : get(err, 'response.data.error_description', t('SERVER_ERROR'));
    logWarn('login', 'Failed login', { userName, error });
    flushLogs();

    yield put(loginFailed(error));
  }
}

function* logOut({ returnUrl }) {
  Storage.authToken = null;
  Storage.refreshToken = null;
  CurrentUserService.logout();
  yield put(push(returnUrl || '/'));
}

export default function* saga() {
  yield takeLatest(LOGIN, logIn);
  yield takeLatest(LOGOUT, logOut);
}
