/**
 * @typedef {{
 *   addListener(handler: (event: TEvent) => void): () => void,
 *   emitEvent(event: TEvent): void,
 * }} EventEmitter
 * @template TEvent
 */

export default function createEventEmitter() {
  const listeners = [];

  return {
    addListener(handler) {
      listeners.push(handler);
      return () => {
        const handlerIndex = listeners.indexOf(handler);
        if (handlerIndex !== -1) {
          listeners.splice(handlerIndex, 1);
        }
      };
    },
    emitEvent(...args) {
      listeners.forEach(handler => handler(...args));
    },
  };
}
