import React from 'react';
import classNames from 'classnames';
import { t } from 'i18n-js';
import { Button } from '../../../../components';
import '../multi-select-list/styles.scss';

const ResetFilterButton = ({ className, onClick }) => (
  <Button
    onClick={onClick}
    text={t('SEARCH_SCREEN.CLEAR_FILTER')}
    className={classNames('multi-select-list__toggle-button', 'dropdown-item', 'toggle-item-component', className)}
  />
);

export default ResetFilterButton;
