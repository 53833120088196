import { useEffect, useState } from 'react';
import { getProfileData } from '../../backend-api';
import createEventEmitter from '../../utilities/createEventEmitter';
import Storage from '../../storage';
import { createLogger } from '../../utilities/log';

/**
 * @typedef {{
 *   id: string,
 *   type: 'Rider' | 'Trainer' | 'Administrator',
 *   name: string,
 *   email: string,
 *   photoAltSizes: {
 *     photoOriginal: string?,
 *     photo72x72: string?,
 *     photo256x256: string?,
 *   },
 *   streamingUnlimited: boolean,
 *   streamingSecondsSpent: number,
 *   streamingSecondsTotal: number,
 *   price45Min: number?,
 *   currency: string?,
 *   vatPercentage: number?,
 * }} UserProfile
 */

/**
 * @typedef {{
 *   isAuthenticated: bool,
 *   data: UserProfile?,
 * }} CurrentUserValue
 */

/** @type {CurrentUserValue} */
let cachedUser = null;
let userLoadPromise = null;

const userChangedEvent = createEventEmitter();

const logger = createLogger('CurrentUserService');

const CurrentUserService = {
  async getUser() {
    if (cachedUser) {
      return cachedUser;
    }

    return await this.getUserNoCache();
  },

  async getUserNoCache() {
    if (!Storage.authToken) {
      cachedUser = { isAuthenticated: false, data: null };
      userChangedEvent.emitEvent(cachedUser);
      return cachedUser;
    }

    if (!userLoadPromise) {
      userLoadPromise = getProfileData()
        .then(user => {
          cachedUser = { isAuthenticated: true, data: user };
          userChangedEvent.emitEvent(cachedUser);
        })
        .catch(e => {
          logger.error('Error feetching current user', { e });
        })
        .finally(() => {
          userLoadPromise = null;
        });
    }

    await userLoadPromise;

    return cachedUser;
  },

  logout() {
    cachedUser = null;
    userChangedEvent.emitEvent(null);
  },
};
export default CurrentUserService;

export function useCurrentUserService() {
  const [user, setUser] = useState(cachedUser);

  useEffect(() => {
    let isUnmounted = false;
    CurrentUserService.getUser().then(user => {
      // If user fetch resolves in between useState & useEffect
      // there will be no event subscribers, and the state gets stale.
      if (isUnmounted) {
        return;
      }
      setUser(user);
    });

    const removeListener = userChangedEvent.addListener(setUser);
    return () => {
      removeListener();
      isUnmounted = true;
    };
  }, []);

  return user || { isAuthenticated: !!Storage.authToken, data: null };
}
