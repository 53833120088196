export const LOGIN = 'LOGIN';
export const RENEW_AUTH_TOKEN = 'RENEW_AUTH_TOKEN';
export const LOGOUT = 'LOGOUT';
const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const LOGIN_FAILED = 'LOGIN_FAILED';

const initialState = {
  error: null,
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return { ...initialState, loading: true };
    case LOGIN_FAILED: {
      return { ...initialState, error: action.error };
    }
    default:
      return state;
  }
};
export default reducer;

export const logIn = ({ email, password, role, rememberMe, returnUrl }) => ({
  type: LOGIN,
  email,
  password,
  role,
  rememberMe,
  returnUrl,
  isDeferred: true,
});

export const loginSuccess = data => ({ type: LOGIN_SUCCESS, data });

export const loginFailed = error => ({ type: LOGIN_FAILED, error });

export const logout = (returnUrl = null) => ({ type: LOGOUT, returnUrl });

export const renewAuthToken = () => ({ type: RENEW_AUTH_TOKEN });
