import React from 'react';
import ReadMoreAndLess from 'react-read-more-less';
import { t } from '../../i18n';

const ReadMore = ({ text }) => (
  <ReadMoreAndLess charLimit={110} readMoreText={t('SEE_ALL')} readLessText={t('SEE_LESS')}>
    {text}
  </ReadMoreAndLess>
);
export default ReadMore;
