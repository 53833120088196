import React from 'react';
import { strftime, t } from '../../i18n';
import ReadMore from './read-more';
import phone from '../assets/phone.png';
import email from '../assets/email.png';
import location from '../assets/location.png';
import UrlTo from '../../url-to';
import Button from '../button';
import ActivitiesConstants from '../../stream/ActivitiesConstants';
import './styles.scss';

const Training = ({ history, activity }) => {
  const { date } = activity;
  const dayOfWeek = strftime(new Date(date), '%a');
  const month = strftime(new Date(date), '%b');
  const day = new Date(date).getDate();
  const hour = strftime(new Date(date), '%H');
  const minute = strftime(new Date(date), '%M');
  const time = `${hour}:${minute}`;
  return (
    <div className="card">
      <div>
        <div className="card__header">
          <div className="card__dateWrap">
            <div className="__day_of_week">{dayOfWeek}</div>
            <div className="card__date">{day}</div>
            <div className="card__month">{month}</div>
          </div>
          <div className="card__timeWrap">
            <div className="card__timeRow">
              <div className="card__title card__title--small">{t('TIME')}:</div>
              <div className="card__time">{time}</div>
            </div>
            <div className="card__timeRow">
              <div className="card__title card__title--small">{t('DURATION')}:</div>
              <div className="card__time">{activity.duration} min</div>
            </div>
          </div>
          <div className={`card__sticker ${activity.type}`}>{t(`TRAINING_TYPE.${activity.type}`)}</div>
        </div>
        <div className="card__info">
          {activity.trainerId !== ActivitiesConstants.INSURANCE_TRAINER_ID && (
            <div className="card__title">{t('RIDER')}:</div>
          )}
          <div className="card__riderName">{activity.riderName}</div>

          {activity.trainerId === ActivitiesConstants.INSURANCE_TRAINER_ID && (
            <div className="card__row">
              <img className="card__icon" src={location} alt="location" />
              <div className="card__text">{activity.location || '-'}</div>
            </div>
          )}
          <div className="card__row">
            <img className="card__icon" src={phone} alt="phone" />
            <div className="card__text">{activity.riderPhone || '-'}</div>
          </div>
          <div className="card__row">
            <img className="card__icon" src={email} alt="email" />
            <div className="card__text">{activity.riderEmail || '-'}</div>
          </div>
        </div>
        {activity.riderMessage ? (
          <div className="card__section">
            <div className="card__title">
              {t(
                activity.trainerId !== ActivitiesConstants.INSURANCE_TRAINER_ID ? 'RIDER_MESSAGE' : 'CUSTOMER_MESSAGE',
              )}
              :
            </div>
            <ReadMore className="card__text" text={activity.riderMessage} />
          </div>
        ) : null}
        {activity.trainerMessage ? (
          <div className="card__section">
            <div className="card__title">{t('MY_MESSAGE')}:</div>
            <ReadMore className="card__text" text={activity.trainerMessage} />
          </div>
        ) : null}
      </div>
      {activity.type === 'training' && (
        <div className="card__btnWrap">
          <Button
            text={t('START_TRAINING')}
            type="green"
            className="card__btn"
            onClick={() => {
              history.push(UrlTo.trainer.stream(activity.id));
            }}
          />
        </div>
      )}
    </div>
  );
};
export default Training;
