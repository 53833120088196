import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';

import { API_REQUEST } from './sagas/api';
import reducer from './reducers';
import sagas from './sagas';

const sagaMiddleware = createSagaMiddleware();

const apiMiddleware = () => next => (action) => {
  if (action.type !== API_REQUEST && !action.isDeferred) {
    return next(action);
  }

  const d = deferred();
  next({ ...action, deferred: d });
  return d.promise;
};

const promise = () => next => action => (
  typeof action.then === 'function'
    ? Promise.resolve(action).then(next, (e) => { throw e; })
    : next(action)
);

function deferred() {
  const d = {};

  d.promise = new Promise((resolve, reject) => {
    d.resolve = resolve;
    d.reject = reject;
  });

  return d;
}

const reduxStore = (browserHistory) => {
  const store = createStore(
    reducer,
    composeWithDevTools(applyMiddleware(
      apiMiddleware, sagaMiddleware, promise, routerMiddleware(browserHistory), thunk,
    )),
  );
  sagas.forEach(saga => sagaMiddleware.run(saga));
  return store;
};
export default reduxStore;
