import * as actionTypes from './action-types';
import initialState from '../../../initial-state';

export default function homeReducer(state = initialState.home, action) {
  switch (action.type) {
    case actionTypes.LOAD_COURSES_HOME:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case actionTypes.LOAD_COURSES_HOME_SUCCESS:
      return {
        ...state,
        data: { ...action.result },
        isLoading: false,
      };
    case actionTypes.LOAD_COURSES_HOME_FAIL:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      };
    default:
      return state;
  }
}
