import React from 'react';
import CollapsePanel from './CollapsePanel';
import FilteringMultiSelectList from './filtering-multi-select-list';
import MultiSelectList from './multi-select-list';
import ResetFilterButton from './reset-filter-button';
import './styles.scss';

const CollapseMultiSelect = ({
  name,
  className,
  title,
  onChange,
  data,
  filterable = false,
  onClearFilter,
  filterHasSelectedItems,
}) => (
  <CollapsePanel className={className} title={title}>
    {filterable ? (
      <FilteringMultiSelectList data={data} onToggleItem={itemId => onChange({ name, value: { id: itemId } })} />
    ) : (
      <MultiSelectList data={data} onToggleItem={itemId => onChange({ name, value: { id: itemId } })} />
    )}
    {filterHasSelectedItems && <ResetFilterButton className={'mt-1'} onClick={() => onClearFilter(name)} />}
  </CollapsePanel>
);
export default CollapseMultiSelect;
