import React from 'react';
import './styles.scss';
import deleteImage from '../assets/delete.png';
import Column from '../directions/column';

const Alert = ({ image, title, message, onClose, buttons, children }) =>
  <Column className="alert-wrapper">
    <Column className="alert-wrapper-close-wrapper">
      <button type="button" className="alert-wrapper-close-button" onClick={onClose}>
        <img src={deleteImage} alt="Close" />
      </button>
    </Column>
    {title && <Column className="alert-wrapper-title">{title}</Column>}
    {image && <img className="alert-wrapper-image" src={image} alt="" />}
    {message && <Column className="alert-wrapper-message">{message}</Column>}
    {children && <Column className="alert-wrapper-content">{children}</Column>}
    {buttons && <Column className="alert-wrapper-button-wrapper">{buttons}</Column>}
  </Column>;
export default Alert;
