import { API_REQUEST } from '../store/sagas/api';
import { postInterestViewed, postTrainerBookPressed, postTrainerProfileViewed } from '../backend-api';

export const TrainerProfileViewContext = {
  search: 'Search',
  interest: 'Interest',
  highlightedCarousel: 'HighlightedCarousel',
  standardCarousel: 'StandardCarousel',
  similar: 'Similar',
};

export const logTrainerProfileViewed = (trainerId, trainerProfileViewContext) => ({
  type: API_REQUEST,
  call: () => postTrainerProfileViewed(trainerId, trainerProfileViewContext),
});

export const logTrainerBookPressed = trainerId => ({
  type: API_REQUEST,
  call: () => postTrainerBookPressed(trainerId),
});

export const logInterestViewed = interestId => ({
  type: API_REQUEST,
  call: () => postInterestViewed(interestId),
});
