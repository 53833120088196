import React from 'react';
import styles from './styles.module.scss';

const CategoryHeader = ({ category }) => (
  <div className={styles.container}>
    <div className={styles.card}>
      <img src={category.coverPicture} alt={category.displayName} />
      <h1>{category.displayName}</h1>
    </div>
  </div>
);

export default CategoryHeader;
