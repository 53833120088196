import initialState from '../../../initial-state';
import { LOAD_LESSON_TO_WATCH, LOAD_LESSON_TO_WATCH_SUCCESS, LOAD_LESSON_TO_WATCH_FAILED } from './action-types';

export default function watchLessonReducer(state = initialState.watchLesson, action) {
  switch (action.type) {
    case LOAD_LESSON_TO_WATCH:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case LOAD_LESSON_TO_WATCH_SUCCESS:
      return {
        ...state,
        lessonsOverview: action.result.overview,
        lesson: action.result.lesson,
        isLoading: false,
      };
    case LOAD_LESSON_TO_WATCH_FAILED:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      };
    default:
      return state;
  }
}
