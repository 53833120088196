import React from 'react';
import { premium, language, flag, map, marker, avatarIcon } from '../../../../components/assets';
import LanguagesList from '../../../components/languages-list';
import LikesBlock from '../../../components/trainer-card/likes-block';
import { getTrainerPhoto256x256 } from '../../../selectors';
import './styles.scss';

const AboutTrainer = ({ trainer }) => (
  <div className="trainer-profile-about-trainer">
    <div className="top-row">
      <img className="profile-photo" src={getTrainerPhoto256x256(trainer) || avatarIcon} alt={trainer.name} />
      <div>
        <div className="trainer-name-row">
          <h1 className="trainer-name">{trainer.name}</h1>
          {trainer.isHighlighted && <img src={premium} className="icon-premium" alt={trainer.name} />}
          <LikesBlock count={trainer.likes.amount} className="likes" />
          {!!trainer.trainerReviewsData?.trainerAverageReviewRating && (
            <div className="trainer-profile-about-trainer__reviews-average-rating">
              {trainer.trainerReviewsData.trainerAverageReviewRating}
            </div>
          )}
        </div>
        {trainer.companyName && <span className="company-name">{trainer.companyName}</span>}
      </div>
    </div>
    <div className="bulletin-list">
      {!!trainer.country && (
        <div className="bulletin-item">
          <img src={flag} alt="Country" />
          <span>{trainer.country}</span>
        </div>
      )}
      {!!trainer.city && (
        <div className="bulletin-item">
          <img src={map} alt="City" />
          <span>{trainer.city}</span>
        </div>
      )}
      {!!trainer.district && (
        <div className="bulletin-item">
          <img src={marker} alt="District" />
          <span>{trainer.district}</span>
        </div>
      )}
      {trainer.languages?.length > 0 && (
        <div className="bulletin-item">
          <img src={language} alt="Languages" />
          <LanguagesList languagesIds={trainer.languages} className="languages" />
        </div>
      )}
    </div>
  </div>
);
export default AboutTrainer;
