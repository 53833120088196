import classNames from 'classnames';
import React from 'react';
import styles from './styles.module.scss';

const Button = ({ text, onClick, type = "green", style, className, disabled }) => (
  <button
    type="button"
    className={classNames(styles.btn, disabled ? styles.disable : type && styles[type], className)}
    style={style || {}}
    onClick={onClick}
    disabled={disabled}
  >
    {text}
  </button>
);
export default Button;
