import React from 'react';
import { strftime, t } from '../../i18n';
import ReadMore from './read-more';
import phone from '../assets/phone.png';
import email from '../assets/email.png';
import ActivitiesConstants from '../../stream/ActivitiesConstants';
import Button from '../button';
import './styles.scss';

const Feedback = ({ activity, showFeedbackDialog }) => {
  const { date } = activity;
  const dayOfWeek = strftime(new Date(date), '%A');
  const month = strftime(new Date(date), '%b');
  const day = new Date(date).getDate();

  return (
    <div className="card">
      <div>
        <div className="card__header">
          <div className="card__dateWrap">
            <div className="card__deadline">{t('DEADLINE')}:</div>
            <div>
              <span className="card__day_of_week">{dayOfWeek}</span>
            </div>
            <div className="card__dateMonth">
              <span className="card__date">{day}</span> <span className="card__month">{month}</span>
            </div>
          </div>
          <div className="card__timeWrap">
            {activity.trainerId !== ActivitiesConstants.INSURANCE_TRAINER_ID && (
              <div className="card__title">{t('RIDER')}:</div>
            )}
            <div className="card__riderName">{activity.riderName}</div>
          </div>
          <div className="card__sticker">{t(`TRAINING_TYPE.${activity.type}`)}</div>
        </div>
        <div className="card__info">
          <div className="card__row">
            <img className="card__icon" src={phone} alt="phone" />
            <div className="card__text">{activity.riderPhone || '-'}</div>
          </div>
          <div className="card__row">
            <img className="card__icon" src={email} alt="email" />
            <div className="card__text">{activity.riderEmail || '-'}</div>
          </div>
        </div>
        <div className="card__section">
          <div className="card__title">{t('LINK_TO_VIDEO')}:</div>
          <div className="card__text">
            <a href={activity.video} target="_blank" rel="noopener noreferrer">
              {activity.video}
            </a>
          </div>
        </div>
        {activity.riderMessage ? (
          <div className="card__section">
            <div className="card__title">
              {t(
                activity.trainerId !== ActivitiesConstants.INSURANCE_TRAINER_ID ? 'RIDER_MESSAGE' : 'CUSTOMER_MESSAGE',
              )}
              :
            </div>
            <ReadMore className="card__text" text={activity.riderMessage} />
          </div>
        ) : null}
        {activity.trainerMessage ? (
          <div className="card__section">
            <div className="card__title">{t('MY_MESSAGE')}:</div>
            <ReadMore className="card__text" text={activity.trainerMessage} />
          </div>
        ) : null}
      </div>
      <div className="card__btnWrap">
        <Button text={t('WRITE_FEEDBACK')} type="green" className="card__btn" onClick={showFeedbackDialog} />
      </div>
    </div>
  );
};
export default Feedback;
