import React from 'react';
import ParentMenuItem from './ParentMenuitem';
import ChildMenuItem from './ChildMenuItem';
import { isEnglish, isSwedish, setEnglish, setSwedish } from '../../../i18n';

import './styles.scss';

const SelectLanguageMenuItem = () => (
  <>
    {isEnglish() && (
      <ParentMenuItem
        title="Eng"
        href="#language/en"
        imgSrc="https://www.ridesum.com/wp-content/plugins/sitepress-multilingual-cms/res/flags/en.png"
        dropDownMenuClassName="dropdown-menu-end"
      >
        <ChildMenuItem
          title="Sv(Swe)"
          href="#language/sv"
          onClick={setSwedish}
          imgSrc="https://www.ridesum.com/wp-content/plugins/sitepress-multilingual-cms/res/flags/sv.png"
        />
      </ParentMenuItem>
    )}
    {isSwedish() && (
      <ParentMenuItem
        title="Sv"
        href="#language/sv"
        imgSrc="https://www.ridesum.com/wp-content/plugins/sitepress-multilingual-cms/res/flags/sv.png"
        dropDownMenuClassName="dropdown-menu-end"
      >
        <ChildMenuItem
          title="Eng"
          href="#language/en"
          onClick={setEnglish}
          imgSrc="https://www.ridesum.com/wp-content/plugins/sitepress-multilingual-cms/res/flags/en.png"
        />
      </ParentMenuItem>
    )}
  </>
);

export default SelectLanguageMenuItem;
