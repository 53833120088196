import React, { useEffect, useRef } from 'react';
import debounce from 'lodash/debounce';

const getScrollTop = () =>
  window.pageYOffset !== undefined
    ? window.pageYOffset
    : (document.documentElement || document.body.parentNode || document.body).scrollTop;

const getScrollBottom = () => getScrollTop() + window.innerHeight;

const getElementBottom = el => el.getBoundingClientRect().bottom + document.documentElement.scrollTop;

const BottomScrollObserver = ({ onEndReached, children }) => {
  const childrenContainerRef = useRef(null);
  useEffect(() => {
    if (!onEndReached) {
      return () => {};
    }

    let isEffectFinished = false;
    const onEndReachedDebounced = debounce(() => onEndReached(), 200);
    const handlePageScroll = () => {
      if (!isEffectFinished && getScrollBottom() >= getElementBottom(childrenContainerRef.current)) {
        onEndReachedDebounced();
      }
    };

    window.addEventListener('scroll', handlePageScroll);
    return () => {
      isEffectFinished = true;
      window.removeEventListener('scroll', handlePageScroll);
    };
  }, [onEndReached]);

  return <div ref={childrenContainerRef}>{children}</div>;
};

export default BottomScrollObserver;
