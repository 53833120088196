import React from 'react';
import { t } from '../../../../i18n';
import { phoneSVG, checked, globe, bag } from '../../../../components/assets';
import './styles.scss';

const Contacts = ({ trainer }) => (
  <div className="trainer-profile-contacts">
    <div className="contacts-item">
      <img src={globe} alt="globe" />
      <span className="font-size14-weight400 contacts-horizontal1-margin-20">
        {trainer.website ? trainer.website : t('SEARCH_SCREEN.UNKNOWN')}
      </span>
    </div>
    <div className="contacts-item" style={{ marginLeft: 5 }}>
      <img src={phoneSVG} alt="phone" />
      <span className="font-size14-weight400 contacts-horizontal1-margin-20">
        {trainer.phone ? trainer.phone : t('SEARCH_SCREEN.UNKNOWN')}
      </span>
    </div>
    <div className="contacts-item" style={{ marginLeft: 2 }}>
      <img src={bag} alt="bag" />
      <span className="font-size14-weight400 contacts-horizontal1-margin-20">
        {trainer.companyName ? trainer.companyName : t('SEARCH_SCREEN.UNKNOWN')}
      </span>
    </div>
    {!!trainer.liabilityInsurance && (
      <div className="contacts-item" style={{ marginLeft: 2 }}>
        <img src={checked} alt="OK" />
        <span className="font-size14-weight400 contacts-horizontal1-margin-20">{t('SEARCH_SCREEN.LIABILITY')}</span>
      </div>
    )}
    {!!trainer.taxCertificate && (
      <div className="contacts-item" style={{ marginLeft: 2 }}>
        <img src={checked} alt="OK" />
        <span className="font-size14-weight400 contacts-horizontal1-margin-20">
          {t('SEARCH_SCREEN.TAX_CERTIFICATE')}
        </span>
      </div>
    )}
  </div>
);
export default Contacts;
