import { t } from 'i18n-js';
import React from 'react';
import Container from 'react-bootstrap/Container';
import MixedTrainersList from '../../../components/mixed-trainers-list';
import useTrainersOverview from '../../../useTrainersOverview';
import styles from './styles.module.scss';

const NoTrainersFound = () => {
  const { highlightedTrainers } = useTrainersOverview();

  // I hope we don't need loading more here ;)
  return (
    <Container className={styles['no-trainers-found']}>
      <h2>{t('SEARCH_RESULT_SCREEN.NO_RESULTS')}</h2>
      <MixedTrainersList trainers={highlightedTrainers} />
    </Container>
  );
};

export default NoTrainersFound;
