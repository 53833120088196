import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadSearchParameters, searchTrainers } from './actions';
import { getSearchedTrainersState, getSearchDataSource } from './selectors';
import i18n, { t } from '../i18n';

const SEARCH_BATCH_SIZE = 12;

const useTrainersSearch = () => {
  const dispatch = useDispatch();
  const searchDataSource = useSelector(getSearchDataSource);
  const searchedTrainersState = useSelector(getSearchedTrainersState);

  const [searchCondition, setSearchCondition] = useState({
    nameQuery: '',
    showResultsNearMe: false,
    countries: [],
    districts: [],
    languages: [],
    competences: [],
    priceRanges: [],
    priceValue: null,
    districtsByCountries: {},
  });

  useEffect(() => {
    dispatch(loadSearchParameters());
  }, [i18n.locale]);

  useEffect(() => {
    onDataSourceChanged({ ...searchDataSource });
  }, [searchDataSource]);

  useEffect(() => {
    const { districtsByCountries } = searchCondition;
    if (!districtsByCountries) return;
    setSearchCondition(current => ({
      ...current,
      districts: getDistrictsForSelectedCountries(
        districtsByCountries,
        current.countries,
        current.districts.filter(d => d.isSelected),
      ),
    }));
  }, [searchCondition.countries]);

  const onDataSourceChanged = ({ countries, districts, languages, competences, priceRanges }) =>
    setSearchCondition(current => ({
      ...current,
      countries: updateDataSourceForMultiSelect(current.countries, countries),
      languages: updateDataSourceForMultiSelect(current.languages, languages),
      competences: updateDataSourceForMultiSelect(current.competences, competences),
      priceRanges,
      districtsByCountries: districts,
    }));

  const updateDataSourceForMultiSelect = (currentDataSource, newDataSource) =>
    newDataSource.map(el => ({ ...el, isSelected: currentDataSource.find(curEl => curEl.id === el.id)?.isSelected }));

  const getDistrictsForSelectedCountries = (districtsByCountriesDictionary, countries, selectedDistricts) => {
    const selectedCoutries = countries.filter(el => el.isSelected).map(el => el.id);
    return selectedCoutries
      .reduce(
        (allDistricts, countryId) =>
          countryId in districtsByCountriesDictionary
            ? allDistricts.concat(districtsByCountriesDictionary[countryId])
            : allDistricts,
        [],
      )
      .map(d => ({ ...d, isSelected: selectedDistricts.some(sd => sd.id === d.id) }));
  };

  const createSearchParameters = (condition, skip = 0, take = SEARCH_BATCH_SIZE) => ({
    nameQuery: condition.nameQuery,
    competencesIds: condition.competences.filter(c => c.isSelected).map(c => c.id),
    languagesIds: condition.languages.filter(l => l.isSelected).map(l => l.id),
    countriesIds: condition.countries.filter(c => c.isSelected).map(c => c.id),
    districtsIds: condition.districts.filter(d => d.isSelected).map(d => d.id),
    price: condition.priceValue ? { ...condition.priceValue } : null,
    skip,
    take,
  });

  const handleChangeSearchCondition = event =>
    setSearchCondition(currentSearchCondition => {
      const { name, value } = event;
      const newSearchCondition = {
        ...currentSearchCondition,
        [name]: ['countries', 'districts', 'languages', 'competences'].includes(name)
          ? currentSearchCondition[name].map(el => (el.id === value.id ? { ...el, isSelected: !el.isSelected } : el))
          : value,
      };
      return newSearchCondition;
    });

  const handleSearch = () => {
    const searchParameters = createSearchParameters(searchCondition);
    dispatch(searchTrainers(searchParameters));
  };

  const handleSearchLoadMore = useCallback(() => {
    const { skip, count } = searchedTrainersState;
    const searchParameters = createSearchParameters(searchCondition, skip + count, SEARCH_BATCH_SIZE);
    dispatch(searchTrainers(searchParameters));
  }, [searchedTrainersState]);

  const handleClearFilter = name => {
    // eslint-disable-next-line no-alert
    if (window.confirm(t('SEARCH_SCREEN.CLEAR_FILTER_CONFIRMATION'))) {
      setSearchCondition(currentSearchCondition => ({
        ...currentSearchCondition,
        [name]: name !== 'priceValue' ? currentSearchCondition[name].map(el => ({ ...el, isSelected: false })) : null,
      }));
    }
  };

  const handleClearAllFilters = () => {
    // eslint-disable-next-line no-alert
    if (window.confirm(t('SEARCH_SCREEN.CLEAR_ALL_FILTERS_CONFIRMATION'))) {
      setSearchCondition(currentSearchCondition => ({
        ...currentSearchCondition,
        ...['countries', 'districts', 'languages', 'competences'].reduce((result, filter) => {
          result[filter] = currentSearchCondition[filter].map(el => ({ ...el, isSelected: false }));
          return result;
        }, {}),
        priceValue: null,
      }));
    }
  };

  return {
    handleChangeSearchCondition,
    handleSearch,
    handleSearchLoadMore,
    handleClearFilter,
    handleClearAllFilters,
    searchCondition,
  };
};
export default useTrainersSearch;
