import React from 'react';
import { t } from '../../../i18n';
import UrlTo from '../../../url-to';
import './styles.scss';

const CardVideo = ({ video }) => (
  <div className="video-card-video">
    <div className="video_name_img_location_language">
      <a
        href={UrlTo.watchCategoryVideo(video.id)}
        style={{ textDecoration: 'none', top: 130 }}
        className="button_play start-50 translate-middle "
        onClick={e => {
          if (!window.confirm(t('CARD_VIDEO.REDIRECT_CONFIRMATION'))) {
            e.preventDefault();
          }
        }}
      >
        <i className="fas fa-play" />
      </a>
      <img className="video-card-video__cover-picture" src={video.thumbnailUrl} alt={video.heading} />
      <div className="mainInfo">
        <div className="twoLineBox heading">{video.heading}</div>
        <div className="threeLineBox shortDesc">{video.shortDesc}</div>
      </div>
    </div>
  </div>
);

export default CardVideo;
