import React from 'react';
import { Link } from 'react-router-dom';
import { ButtonLink } from '../../../components';
import { avatarIcon, premium } from '../../../components/assets';
import { t } from '../../../i18n';
import UrlTo from '../../../url-to';
import { getTrainerPhoto256x256 } from '../../selectors';
import LanguagesList from '../languages-list';
import CompetencesList from './competences-list';
import LikesBlock from './likes-block';
import './styles.scss';

const HighlightedTrainerCard = ({ trainer, onSeeProfile }) => {
  const profileUrl = UrlTo.trainerProfile(trainer.id, trainer.name);
  return (
    <div className="trainers-trainer-card">
      <LikesBlock className="highlighted-trainer-likes" count={trainer.likes.amount} />
      <Link to={profileUrl}>
        <img
          className="trainers-trainer-card__cover-picture"
          src={getTrainerPhoto256x256(trainer) || avatarIcon}
          alt={trainer.name}
        />
      </Link>
      <img src={premium} className="trainers-trainer-card__premium-icon" alt="Premium" />
      {trainer.trainerReviewsData?.trainerAverageReviewRating && (
        <div className="trainers-trainer-card__reviews-average-rating trainers-trainer-card__reviews-average-rating_highlighted">
          {trainer.trainerReviewsData.trainerAverageReviewRating}
        </div>
      )}
      <div className="mainInfo">
        <b className="trainers-trainer-card__trainer-name" title={trainer.name}>
          {trainer.name}
        </b>
        <div className="oneLineBox company-name-premium">{trainer.companyName}</div>
        <div className="oneLineBox">
          <LanguagesList languagesIds={trainer.languages} />
        </div>
        <div className="oneLineBox location-premium">
          {trainer.city}, {trainer.country}
        </div>
      </div>
      <div className="trainers-trainer-card__bottom-aligned-content">
        <div className="border_line" style={{ marginBottom: 8 }} />
        <div className="margin_bottom8">
          <CompetencesList competences={trainer.competences} showBullets />
        </div>
        <div className="border_line" style={{ marginBottom: 16 }} />
        <ButtonLink
          href={profileUrl}
          text={t('SEARCH_SCREEN.SEE_PROFILE')}
          type="green"
          className="trainers-trainer-card__see-profile"
          onClick={onSeeProfile}
        />
      </div>
    </div>
  );
};
HighlightedTrainerCard.Placeholder = () => (
  <div className="trainers-trainer-card placeholder-glow" style={{ minHeight: 383 }}>
    <div className="placeholder" />
  </div>
);

export default HighlightedTrainerCard;
