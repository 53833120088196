import React from 'react';
import { t } from '../../../i18n';
import './styles.scss';

const AudioFeedbackBanner = () => (
  <div className="feedback-audio-feedback-banner">
    <span role="img" aria-label="attention">
      &#10071;
    </span>{' '}
    {t('CREATE_AUDIO_FEEDBACK_IN_APP')}
  </div>
);

export default AudioFeedbackBanner;
