import React, { useState } from 'react';
import Button from '../button';
import './styles.scss';

const Tab = ({ text, active, onClick }) => {
  if (active) { return <div className="button-group__btn"><Button disabled text={text} style={{ cursor: 'default' }} /></div>; }
  return <div className="button-group__btn"><Button text={text} onClick={onClick} type="green" /></div>;
};

const ButtonGroup = ({ tabs, onClick, defaultTabIndex }) => {
  const [selectedTab, setSelectedTab] = useState(defaultTabIndex || 0);
  const onClickCallback = (selectedIndex, selectedObject) => {
    setSelectedTab(selectedIndex);
    onClick(selectedObject);
  };
  return (
    <div className="button-group__container">
      {tabs.map((tab, index) =>
        <Tab
          tabIndex={tab}
          type="button"
          text={tab.text}
          key={tab.text}
          active={selectedTab === index}
          onClick={() => onClickCallback(index, tab)}
        />,
      )}
    </div>
  );
};
export default ButtonGroup;
