import React from 'react';
import './styles.scss';
import { formatFlagTitle, getProductTitle } from '../selectors';

const Flag = ({ activity }) => (<div className={`activity-flag activity__${activity}`}>{formatFlagTitle(activity)}</div>);
export default Flag;

export const FlagLeftBigProducts = ({ user }) => (<div className="activity-flag-left-big activity">{getProductTitle(user)}</div>);

export const FlagLeftBig = ({ activity }) => (<div className={`activity-flag-left-big activity__${activity}`}>{formatFlagTitle(activity)}</div>);
