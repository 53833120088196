import { isEnglish } from './i18n';

// Some of the paths are used in appsettings.json by API,
// so double-check before changing URL of an existing page
const UrlTo = {
  home: () => '/',
  login: ({ role, returnUrl }) => `/${queryParams({ role, returnUrl })}`,
  trainersOverview: () => '/trainers',
  searchResults: () => '/trainers/search',
  trainerProfile: (id, trainerName) => `/trainers/profile/${id}/${trainerName.replace(/\s+/g, '-')}`,
  trainerReviewDetails: reviewId => `/trainers/reviews/${reviewId}`,
  categoryInfo: (id, categoryName) => `/trainers/category/${id}/${categoryName.replace(/\s+/g, '-')}`,
  offSiteHome: () => (isEnglish() ? 'https://www.ridesum.com' : 'https://www.ridesum.se'),
  offSiteTrainers: () => (isEnglish() ? 'https://www.ridesum.com/trainers/' : 'https://www.ridesum.se/trainers/'),
  offSitePocketOffice: () =>
    isEnglish() ? 'https://www.ridesum.com/pocket-office/' : 'https://www.ridesum.se/pocket-office/',
  offSiteTrainersTutorials: () =>
    isEnglish() ? 'https://www.ridesum.com/tutorials_trainers/' : 'https://www.ridesum.se/instruktionsfilmer_tranare/',
  offSiteMarketingMaterials: () =>
    isEnglish()
      ? 'https://www.ridesum.com/marketing-material-templates/'
      : 'https://www.ridesum.se/marketing-material-templates/',
  offSitePromocode: () => '/promocode',
  offSiteRiders: () => (isEnglish() ? 'https://www.ridesum.com/riders' : 'https://www.ridesum.se/riders'),
  offSiteRidersPremium: () =>
    isEnglish() ? 'https://www.ridesum.com/riderpremium/' : 'https://www.ridesum.se/riderpremium/',
  offSiteRidersTutorials: () =>
    isEnglish() ? 'https://www.ridesum.com/tutorials_riders' : 'https://www.ridesum.se/instruktionsfilmer_ryttare/',
  offSiteContact: () => (isEnglish() ? 'https://www.ridesum.com/contact/' : 'https://www.ridesum.se/contact/'),
  offSiteNews: () => (isEnglish() ? 'https://www.ridesum.com/news/' : 'https://www.ridesum.se/nyheter/'),
  offSiteSupport: () => (isEnglish() ? 'http://support.ridesum.com/en' : 'http://support.ridesum.com'),
  offSiteCustomerSupport: () =>
    isEnglish() ? 'https://www.ridesum.com/customer-support/' : 'https://www.ridesum.se/customer-support/',
  offSiteWorkWithUs: () => (isEnglish() ? 'https://www.ridesum.com/work-with-us/' : 'https://www.ridesum.se/jobb/'),
  offSiteTeam: () => (isEnglish() ? 'https://www.ridesum.com/team/' : 'https://www.ridesum.se/team/'),
  offSiteStory: () =>
    isEnglish() ? 'https://www.ridesum.com/the-story-behind-ridesum/' : 'https://www.ridesum.se/storyn/',
  offSitePrivacyPolicy: () =>
    isEnglish() ? 'https://www.ridesum.com/privacy-policy-for-ridesum/' : 'https://www.ridesum.se/integritetspolicy/',
  offSiteTermsOfUse: () =>
    isEnglish()
      ? 'https://www.ridesum.com/terms-and-conditions-for-ridesum-users/'
      : 'https://www.ridesum.se/anvandarvillkor/',
  offSiteTrainersLogin: () => '/',
  appStoreRidesumApplication: () => 'https://apps.apple.com/us/app/ridesum-digital-ridtr%C3%A4ning/id1387794901',
  googlePlayRidesumApplication: () => 'https://play.google.com/store/apps/details?id=com.ridesum.app',
  bookTrainer: id =>
    isEnglish()
      ? `https://www.ridesum.com/trainerprofilepage/?trainerId=${id}`
      : `https://www.ridesum.se/trainerprofilepage/?trainerId=${id}`,
  watchCategoryVideo: id =>
    isEnglish() ? `https://www.ridesum.com/movie?movieid=${id}` : `https://www.ridesum.se/movie?movieid=${id}`,
  coursesHome: () => '/digitalakurser',
  coursesSpecificCourse: id => `/digitalakurser/specific-course/${id}`,
  coursesCheckout: (courseId, { email, name, promocode } = {}) =>
    `/digitalakurser/checkout/${courseId}${queryParams({ email, name, promocode })}`,
  coursesStripeCheckoutResult: (courseId, email, name) =>
    `/digitalakurser/checkout/${courseId}/result${queryParams({ email, name })}`,
  coursesSwishCheckoutResult: (courseId, email, name, paymentId) =>
    `/digitalakurser/checkout/${courseId}/swish/result${queryParams({ email, name, paymentId })}`,
  coursesGiftCardOverview: () => `/digitalakurser/presentkort`,
  coursesGiftCardCheckout: ({ email, name } = {}) => `/digitalakurser/presentkort/kopa${queryParams({ email, name })}`,
  coursesGiftCardStripeCheckoutResult: ({ email, name }) =>
    `/digitalakurser/presentkort/kopa/result${queryParams({ email, name })}`,
  coursesGiftCardSwishCheckoutResult: ({ email, name, paymentId }) =>
    `/digitalakurser/presentkort/kopa/swish/result${queryParams({ email, name, paymentId })}`,
  coursesGiftCardRedeem: ({ courseId, email, name } = {}) =>
    `/digitalakurser/presentkort/los-in${queryParams({ courseId, email, name })}`,
  coursesGiftCardActivated: () => '/digitalakurser/presentkort/succe',
  coursesWatchLesson: (courseId, lessonNumber) =>
    `/digitalakurser/watch/${courseId}${queryParams({ lesson: lessonNumber })}`,
  coursesProfile: () => `/digitalakurser/profile`,
  coursesProfileFromAppRedirect: () => '/digitalakurser/profile/redirect',
  coursesResetPassword: ({ token, returnUrl, email, role } = {}) =>
    `/digitalakurser/losenord${queryParams({ token, returnUrl, email, role })}`,
  trainer: {
    stream: activityId => `/pass${queryParams({ activityId })}`,
    dunstanNewMeeting: () => '/dunstan/new',
    dunstanNewMeetingResult: ({ meetingUrl } = {}) => `/dunstan/created${queryParams({ meetingUrl })}`,
  },
  rider: {
    stream: () => '/rider-stream',
    streamOver: () => '/rider-stream-over',
  },
  admin: {
    searchProfile: (type, term) => `/searchprofile${queryParams({ type, term })}`,
    viewProfile: userId => `/edituser/${userId}`,
    administrateTrainerReview: reviewId => `/administrate-trainer-review/${reviewId}`,
    searchedTrainersReviews: () => '/searched-trainers-reviews',
    administrateSearchedTrainerReview: reviewId => `/administrate-searched-trainer-review/${reviewId}`,
    coursesPayments: ({ userId, statuses } = {}) => `/courses-payments${queryParams({ userId, statuses })}`,
    coursePaymentDetails: paymentId => `/courses-payments/${paymentId}`,
  },
  // Any unsupported URL will work but let's use this one for consistency
  notFound: () => '/not-found',
};

const queryParams = queryParams => {
  if (!queryParams) {
    return '';
  }
  const queryValuesWhichAreNotFalse = Object.entries(queryParams).filter(i => i[1]);
  if (queryValuesWhichAreNotFalse.length === 0) {
    return '';
  }
  return '?' + new URLSearchParams(queryValuesWhichAreNotFalse);
};

export default UrlTo;
