import React from 'react';
import classNames from 'classnames';

import './styles.scss';

const SingleMenuItem = ({ href, title, className }) => (
  <>
    {/* desktop version */}
    <li className="trainer-header__menu-item-single trainer-header__menu-item-single__desktop nav-item">
      <a className="trainer-header__menu-item-single-link nav-link" href={href}>
        {title}
      </a>
    </li>
    {/* mobile version */}
    <div
      className={classNames(
        className,
        'trainer-header__menu-item-single trainer-header__menu-item-single__mobile accordion-item',
      )}
    >
      <div className="trainer-header__menu-item-single-link-wrapper accordion-header">
        <a className="trainer-header__menu-item-single-link accordion-header" id="" role="button" href={href}>
          <span>{title}</span>
        </a>
      </div>
    </div>
  </>
);

export default SingleMenuItem;
