const initialState = {
  currentUser: {
    data: null,
    isLoading: false,
    hasError: false,
  },
  home: {
    data: {
      availableCoursesDataSource: [],
      popularCoursesDataSource: [],
      latestCoursesDataSource: [],
      reviewsDataSource: [],
    },
    isLoading: false,
    hasError: false,
  },
  specificCourse: {
    data: {
      author: {},
      previewImageUrl: '',
      previewVideoUrl: '',
      hoursTotal: 0,
      lessonsTotal: 0,
      price: {},
      title: '',
      description: '',
      goalsDataSource: [],
      lessonsDataSource: [],
      reviewsDataSource: [],
      relatedCoursesDataSource: [],
      downloadableMaterialsAreAvailable: false,
      isPurchased: false,
      isStarted: false,
    },
    isLoading: true,
    hasError: false,
  },
  checkout: {
    data: {},
    isLoading: false,
    hasError: false,
  },
  giftCardCheckout: {
    data: null,
    isLoading: false,
    hasError: false,
  },
  watchLesson: {
    lessonsOverview: null,
    lesson: null,
    isLoading: false,
    hasError: false,
  },
  profile: {
    data: {
      totalHoursOfTraining: 0,
      allCoursesDataSource: [],
      activeCoursesDataSource: [],
      completedCoursesDataSource: [],
      notStartedCoursesDataSource: [],
    },
    isLoading: false,
    hasError: false,
  },
};
export default initialState;
