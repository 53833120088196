import * as actionTypes from './action-types';
import initialState from '../initial-state';
import { LOGOUT } from '../../login/actions';

export default function currentUserReducer(state = initialState.currentUser, action) {
  switch (action.type) {
    case actionTypes.LOAD_CURRENT_USER:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case actionTypes.LOAD_CURRENT_USER_SUCCESS:
      return {
        ...state,
        data: action.result,
        isLoading: false,
      };
    case actionTypes.LOAD_CURRENT_USER_FAILED:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case LOGOUT:
      return initialState.currentUser;
    default:
      return state;
  }
}
