/* eslint-disable no-console */
import { AxiosError } from 'axios';
import { getLogLevels, postLogEvents } from '../../backend-api';
import Storage from '../../storage';

const loggerState = {
  myLogLevels: undefined,
  eventsBatch: [],
  timeout: null,
  flushTimeoutMillis: 1000 * 10, // 10 seconds
  previousAuthToken: undefined,
};

const log = (logLevel, tag, message, data) => {
  const newEvent = {
    logLevel,
    tag,
    logDate: new Date().toISOString(),
    message,
    data: data ? JSON.stringify(sanitizeData(data)) : null,
  };
  loggerState.eventsBatch.push(newEvent);

  if (loggerState.timeout) {
    return;
  }

  loggerState.timeout = setTimeout(flushLogs, loggerState.flushTimeoutMillis);
};

const sanitizeData = data => {
  if (data instanceof Error) {
    return sanitizeError(data);
  }
  if (data instanceof Object) {
    if (data.e instanceof Error) {
      return {
        ...data,
        e: sanitizeError(data.e),
      };
    }
    if (data.error instanceof Error) {
      return {
        ...data,
        error: sanitizeError(data.error),
      };
    }
  }
  return data;
};

const sanitizeError = error => {
  if (error instanceof AxiosError) {
    return sanitizeAxiosError(error);
  }
  // Error properties should be assigned to a different object
  // otherwise they won't be picked up by JSON.stringify
  const obj = {};
  Object.getOwnPropertyNames(error).forEach(key => {
    obj[key] = error[key];
  });

  return obj;
};

/**
 * AxiosError usually contains sensitive request data
 * such as Authorization header and request body
 * @param {AxiosError} error
 */
const sanitizeAxiosError = error => ({
  message: error.message,
  code: error.code,
  request: `${error.config.method} ${error.config.baseURL}${error.config.url}`,
  responseStatus: error.response?.status,
  responseData: error.response?.data,
});

export const flushLogs = async () => {
  if (loggerState.previousAuthToken !== Storage.authToken || !loggerState.myLogLevels) {
    try {
      const { logLevels } = await getLogLevels();
      loggerState.myLogLevels = logLevels;
    } catch (e) {
      return; // let's retry with the next logging call
    }
    loggerState.previousAuthToken = Storage.authToken;
  }

  const eventsToSend = loggerState.eventsBatch.filter(shouldLog);
  loggerState.timeout = null;
  loggerState.eventsBatch = [];
  if (eventsToSend.length > 0) {
    try {
      await postLogEvents(eventsToSend);
    } catch (e) {
      loggerState.eventsBatch = [...eventsToSend, ...loggerState.eventsBatch];
    }
  }
};

const shouldLog = ({ tag, logLevel }) => {
  const logLevelForThisTag = loggerState.myLogLevels[tag] || loggerState.myLogLevels['*'];
  return logLevel >= logLevelForThisTag;
};

const LogLevels = {
  Trace: 1,
  Information: 2,
  Warning: 3,
  Error: 4,
  Critical: 5,
};

export const logTrace = (tag, message, data) => {
  log(LogLevels.Trace, tag, message, data);
};
export const logInfo = (tag, message, data) => {
  console.log(message, data)
  log(LogLevels.Information, tag, message, data);
};
export const logWarn = (tag, message, data) => {
  console.warn(message, data)
  log(LogLevels.Warning, tag, message, data);
};
export const logError = (tag, message, data) => {
  console.error(message, data)
  log(LogLevels.Error, tag, message, data);
};
export const logCritical = (tag, message, data) => {
  console.error(message, data)
  log(LogLevels.Critical, tag, message, data);
};

export const createLogger = tag => ({
  trace: (message, data) => logTrace(tag, message, data),
  info: (message, data) => logInfo(tag, message, data),
  warn: (message, data) => logWarn(tag, message, data),
  error: (message, data) => logError(tag, message, data),
  critical: (message, data) => logCritical(tag, message, data),
});
