import { useEffect } from 'react';

const RETRY_MILLIS = 20000;

const Reconnector = ({ onReconnect }) => {
  useEffect(() => {
    if (!onReconnect) {
      return undefined;
    }

    const reconnectInterval = setInterval(onReconnect, RETRY_MILLIS);
    onReconnect();

    return () => {
      clearInterval(reconnectInterval);
    };
  }, [onReconnect]);

  return null;
};
export default Reconnector;
