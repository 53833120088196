import React from 'react';
import './styles.scss';

const ListCard = ({ onClick, children, button, darken, className }) => {
  if (button) {
    return (
      <button
        type="button"
        className={`${className} ${darken ? 'list-card-darken' : 'list-card-default'} list-card list-card-hover`}
        onClick={onClick}
      >
        {children}
      </button>
    );
  }
  return (
    <div className={`${className} ${darken ? 'list-card-darken' : 'list-card-default'} list-card`}>{children}</div>
  );
};
export default ListCard;
