import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga4';

// If you need to remake the component for react-router-dom v6
// here's the snippet: https://stackoverflow.com/a/70502633
const RouteChangeGATracker = ({ history, location }) => {
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });

    const unlisten = history.listen(newLocation => {
      ReactGA.send({ hitType: 'pageview', page: newLocation.pathname });
    });
    return () => {
      unlisten();
    };
  }, []);

  return null;
};
export default withRouter(RouteChangeGATracker);
