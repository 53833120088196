import { API_REQUEST } from '../store/sagas/api';
import {
  listUsers,
  deleteUser as deleteUserApi,
  resetUserCache,
  getTrainerActivity,
  getAdminSearchProfile,
  getTrainerRequests,
  getTrainerUpcoming,
  declineActivity,
  acceptActivity,
  cancelActivity,
  editUserInformation,
  getTrainerStudents,
  getCompetences,
  getRiderUpcoming,
  getRiderTrainers,
  getCountries,
  getDistricts,
  getAdminComments,
  postAdminComments,
  getNewlyAddedTrainerReviews,
  approveTrainerReview,
  rejectTrainerReview,
  getFilteredTrainerReviewsForAdmin,
} from '../backend-api';

const LOADING_USERS = 'LOADING_USERS';
const LOADING_USERS_SUCCESS = 'LOADING_USERS_SUCCESS';
const LOADING_USERS_FAILED = 'LOADING_USERS_FAILED';

const LOADING_TRAINER_ACTIVITY = 'LOADING_TRAINER_ACTIVITY';
const LOADING_TRAINER_ACTIVITY_SUCCESS = 'LOADING_TRAINER_ACTIVITY_SUCCESS';

const DELETE_USER = 'DELETE_USER';
const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
const DELETE_USER_FAILED = 'DELETE_USER_FAILED';

const RESET_CACHE = 'RESET_CACHE';
const RESET_CACHE_SUCCESS = 'RESET_CACHE/SUCCESS';
const RESET_CACHE_ERROR = 'RESET_CACHE/ERROR';

const SEARCH_PROFILE = 'ADMIN/SEARCH_PROFILE';
const SEARCH_PROFILE_SUCCESS = 'ADMIN/SEARCH_PROFILE_SUCCESS';
const SEARCH_PROFILE_FAILED = 'ADMIN/SEARCH_PROFILE_FAILED';

const EDIT_USER = 'EDIT_USER';
const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
const EDIT_USER_ERROR = 'EDIT_USER_ERROR';

const LOADING_TRAINER_REQUESTS = 'LOADING_TRAINER_REQUESTS';
const LOADING_TRAINER_REQUESTS_SUCCESS = 'LOADING_TRAINER_REQUESTS_SUCCESS';
const LOADING_TRAINER_REQUESTS_FAILED = 'LOADING_TRAINER_REQUESTS_FAILED';

const LOADING_USER_UPCOMING = 'LOADING_USER_UPCOMING';
const LOADING_USER_UPCOMING_SUCCESS = 'LOADING_USER_UPCOMING_SUCCESS';
const LOADING_USER_UPCOMING_FAILED = 'LOADING_USER_UPCOMING_FAILED';

const LOADING_USER_CONNECTIONS = 'LOADING_USER_CONNECTIONS';
const LOADING_USER_CONNECTIONS_SUCCESS = 'LOADING_USER_CONNECTIONS_SUCCESS';
const LOADING_USER_CONNECTIONS_FAILED = 'LOADING_USER_CONNECTIONS_FAILED';

const COMPETENCES = 'COMPETENCES';
const COMPETENCES_SUCCESS = 'COMPETENCES_SUCCESS';
const COMPETENCES_ERROR = 'COMPETENCES_ERROR';

const CHANGE_ACTIVITY = 'CHANGE_ACTIVITY';
const CHANGE_ACTIVITY_SENT = 'CHANGE_ACTIVITY_SENT';
const CHANGE_ACTIVITY_ERROR = 'CHANGE_ACTIVITY_ERROR';

const LOADING_COUNTRIES = 'LOADING_COUNTRIES';
const LOADING_COUNTRIES_SUCCESS = 'LOADING_COUNTRIES_SUCCESS';
const LOADING_COUNTRIES_ERROR = 'LOADING_COUNTRIES_ERROR';

const LOADING_DISTRICTS = 'LOADING_DISTRICTS';
const LOADING_DISTRICTS_SUCCESS = 'LOADING_DISTRICTS_SUCCESS';
const LOADING_DISTRICTS_ERROR = 'LOADING_DISTRICTS_ERROR';

const LOADING_ADMIN_COMMENTS = 'LOADING_ADMIN_COMMENTS';
const LOADING_ADMIN_COMMENTS_SUCCESS = 'LOADING_ADMIN_COMMENTS_SUCCESS';
const LOADING_ADMIN_COMMENTS_ERROR = 'LOADING_ADMIN_COMMENTS_ERROR';

const POST_ADMIN_COMMENTS = 'POST_ADMIN_COMMENTS';
const POST_ADMIN_COMMENTS_SUCCESS = 'POST_ADMIN_COMMENTS_SUCCESS';
const POST_ADMIN_COMMENTS_ERROR = 'POST_ADMIN_COMMENTS_ERROR';

const LOAD_TRAINER_REVIEWS = 'Admin/LOAD_TRAINER_REVIEWS';
const LOAD_TRAINER_REVIEWS_SUCCESS = 'Admin/LOAD_TRAINER_REVIEWS_SUCCESS';
const LOAD_TRAINER_REVIEWS_FAIL = 'Admin/LOAD_TRAINER_REVIEWS_FAIL';

const LOAD_TRAINER_REVIEW = 'LOAD_TRAINER_REVIEW';
const LOAD_TRAINER_REVIEW_SUCCESS = 'LOAD_TRAINER_REVIEW_SUCCESS';
const LOAD_TRAINER_REVIEW_FAIL = 'LOAD_TRAINER_REVIEW_FAIL';

const APPROVE_TRAINER_REVIEW = 'APPROVE_TRAINER_REVIEW';
const APPROVE_TRAINER_REVIEW_SUCCESS = 'APPROVE_TRAINER_REVIEW_SUCCESS';
const APPROVE_TRAINER_REVIEW_FAIL = 'APPROVE_TRAINER_REVIEW_FAIL';

const REJECT_TRAINER_REVIEW = 'REJECT_TRAINER_REVIEW';
const REJECT_TRAINER_REVIEW_SUCCESS = 'REJECT_TRAINER_REVIEW_SUCCESS';
const REJECT_TRAINER_REVIEW_FAIL = 'REJECT_TRAINER_REVIEW_FAIL';

const SEARCH_TRAINER_REVIEWS = 'Admin/SEARCH_TRAINER_REVIEWS';
const SEARCH_TRAINER_REVIEWS_SUCCESS = 'Admin/SEARCH_TRAINER_REVIEWS_SUCCESS';
const SEARCH_TRAINER_REVIEWS_FAIL = 'Admin/SEARCH_TRAINER_REVIEWS_FAIL';

const initialState = {
  users: null,
  error: null,
  loading: false,
  trainer: null,
  userInfo: null,
  editUser: null,
  trainersStudents: null,
  competences: null,
  selectedUser: null,
  countries: null,
  districts: null,
  adminComments: null,
  trainerReviews: {
    data: null,
    loading: false,
    error: false,
  },
  searchedTrainerReviews: {
    data: null,
    loading: false,
    error: false,
  },
};

export default function adminReducer(state = initialState, action) {
  switch (action.type) {
    case LOADING_USERS:
      return { ...initialState, loading: true };
    case LOADING_USERS_SUCCESS:
      return {
        ...state,
        users: action.result,
        loading: false,
      };
    case LOADING_USERS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case RESET_CACHE:
      return { ...state, loading: true };
    case RESET_CACHE_SUCCESS:
      return { ...state, loading: false };
    case RESET_CACHE_ERROR:
      return { ...state, loading: false, error: action.error };

    case DELETE_USER:
      return { ...state, deleteLoading: true };
    case DELETE_USER_SUCCESS:
      return { ...state, deleteLoading: false };
    case DELETE_USER_FAILED:
      return { ...state, deleteLoading: false, error: action.error };

    case LOADING_TRAINER_ACTIVITY:
      return {
        ...state,
        loading: true,
      };
    case LOADING_TRAINER_ACTIVITY_SUCCESS:
      return {
        ...state,
        trainer: action.result,
        loading: false,
      };

    case SEARCH_PROFILE:
      return {
        ...state,
        loading: true,
      };
    case SEARCH_PROFILE_SUCCESS:
      return {
        ...state,
        userInfo: action.result,
        loading: false,
      };

    case SEARCH_PROFILE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case COMPETENCES:
      return {
        ...state,
        loading: true,
      };
    case COMPETENCES_SUCCESS:
      return {
        ...state,
        competences: action.result,
        loading: false,
      };
    case COMPETENCES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case EDIT_USER:
      return {
        ...state,
        loading: true,
      };
    case EDIT_USER_SUCCESS:
      return {
        ...state,
        editUser: action.result,
        loading: false,
      };
    case EDIT_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case LOADING_TRAINER_REQUESTS:
      return {
        ...state,
        loading: true,
      };
    case LOADING_TRAINER_REQUESTS_SUCCESS:
      return {
        ...state,
        activities: {
          ...state.activities,
          requests: action.result,
        },
        loading: false,
      };
    case LOADING_TRAINER_REQUESTS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case LOADING_USER_UPCOMING:
      return {
        ...state,
        loading: true,
      };
    case LOADING_USER_UPCOMING_SUCCESS:
      return {
        ...state,
        activities: {
          ...state.activities,
          upcoming: action.result,
        },
        loading: false,
      };
    case LOADING_USER_UPCOMING_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case CHANGE_ACTIVITY:
      return {
        ...state,
        showSentMessage: false,
        loadingChangeActivity: true,
      };
    case CHANGE_ACTIVITY_SENT:
      return {
        ...state,
        showSentMessage: true,
        loadingChangeActivity: false,
      };
    case CHANGE_ACTIVITY_ERROR:
      return {
        ...state,
        loadingChangeActivity: false,
        error: action.error,
      };

    case LOADING_USER_CONNECTIONS:
      return {
        ...state,
        loading: true,
      };
    case LOADING_USER_CONNECTIONS_SUCCESS:
      return {
        ...state,
        userConnections: action.result,
        loading: false,
      };
    case LOADING_USER_CONNECTIONS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case LOADING_COUNTRIES:
      return {
        ...state,
      };
    case LOADING_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: action.result,
      };
    case LOADING_COUNTRIES_ERROR:
      return {
        ...state,
        error: action.error,
      };

    case LOADING_DISTRICTS:
      return {
        ...state,
      };
    case LOADING_DISTRICTS_SUCCESS:
      return {
        ...state,
        districts: action.result,
      };
    case LOADING_DISTRICTS_ERROR:
      return {
        ...state,
        error: action.error,
      };

    case LOADING_ADMIN_COMMENTS:
      return {
        ...state,
        adminCommentsLoading: true,
      };
    case LOADING_ADMIN_COMMENTS_SUCCESS:
      return {
        ...state,
        adminComments: action.result,
        adminCommentsLoading: false,
      };
    case LOADING_ADMIN_COMMENTS_ERROR:
      return {
        ...state,
        error: action.error,
        adminCommentsLoading: false,
      };

    case POST_ADMIN_COMMENTS:
      return {
        ...state,
        showPostComments: false,
      };
    case POST_ADMIN_COMMENTS_SUCCESS:
      return {
        ...state,
        showPostComments: true,
      };
    case POST_ADMIN_COMMENTS_ERROR:
      return {
        ...state,
        showPostComments: false,
        error: action.error,
      };

    case LOAD_TRAINER_REVIEWS:
      return {
        ...state,
        trainerReviews: {
          ...state.trainerReviews,
          loading: true,
        },
      };
    case LOAD_TRAINER_REVIEWS_SUCCESS:
      return {
        ...state,
        trainerReviews: {
          data: action.result,
          loading: false,
          error: false,
        },
      };
    case LOAD_TRAINER_REVIEWS_FAIL:
      return {
        ...state,
        trainerReviews: {
          ...state.trainerReviews,
          loading: false,
          error: true,
        },
      };
    case LOAD_TRAINER_REVIEW:
      return {
        ...state,
        trainerReview: {
          ...state.trainerReview,
          loading: true,
        },
      };
    case LOAD_TRAINER_REVIEW_SUCCESS:
      return {
        ...state,
        trainerReview: {
          data: action.result,
          error: false,
          loading: false,
        },
      };
    case LOAD_TRAINER_REVIEW_FAIL:
      return {
        ...state,
        trainerReview: {
          ...state.trainerReview,
          error: true,
          loading: false,
        },
      };
    case APPROVE_TRAINER_REVIEW:
      return {
        ...state,
        trainerReview: {
          ...state.trainerReview,
          loading: true,
        },
      };
    case APPROVE_TRAINER_REVIEW_SUCCESS:
      return {
        ...state,
        trainerReview: {
          ...state.trainerReview,
          error: false,
          loading: false,
        },
      };
    case APPROVE_TRAINER_REVIEW_FAIL:
      return {
        ...state,
        trainerReview: {
          ...state.trainerReview,
          error: true,
          loading: false,
        },
      };
    case REJECT_TRAINER_REVIEW:
      return {
        ...state,
        trainerReview: {
          ...state.trainerReview,
          loading: true,
        },
      };
    case REJECT_TRAINER_REVIEW_SUCCESS:
      return {
        ...state,
        trainerReview: {
          ...state.trainerReview,
          error: false,
          loading: false,
        },
      };
    case REJECT_TRAINER_REVIEW_FAIL:
      return {
        ...state,
        trainerReview: {
          ...state.trainerReview,
          error: true,
          loading: false,
        },
      };
    case SEARCH_TRAINER_REVIEWS:
      return {
        ...state,
        searchedTrainerReviews: {
          ...state.searchedTrainerReviews,
          loading: true,
        },
      };
    case SEARCH_TRAINER_REVIEWS_SUCCESS:
      return {
        ...state,
        searchedTrainerReviews: {
          data: action.result.reviews,
          loading: false,
          error: false,
        },
      };
    case SEARCH_TRAINER_REVIEWS_FAIL:
      return {
        ...state,
        searchedTrainerReviews: {
          ...state.searchedTrainerReviews,
          loading: false,
          error: true,
        },
      };
    default:
      return state;
  }
}

export const loadUsers = (type, newsLetter, order, skip, count) => ({
  type: API_REQUEST,
  types: [LOADING_USERS, LOADING_USERS_SUCCESS, LOADING_USERS_FAILED],
  call: () => listUsers(type, newsLetter, order, skip, count),
});

export const deleteUser = (role, id) => ({
  type: API_REQUEST,
  types: [DELETE_USER, DELETE_USER_SUCCESS, DELETE_USER_FAILED],
  call: () => deleteUserApi(role, id),
});

export const searchProfile = (searchField, searchTerm) => ({
  type: API_REQUEST,
  types: [SEARCH_PROFILE, SEARCH_PROFILE_SUCCESS, SEARCH_PROFILE_FAILED],
  call: () => getAdminSearchProfile(searchField, searchTerm),
});

export const editUserInfo = (role, id, data) => ({
  type: API_REQUEST,
  types: [EDIT_USER, EDIT_USER_SUCCESS, EDIT_USER_ERROR],
  call: () => editUserInformation(role, id, data),
});

export const resetCache = () => ({
  type: API_REQUEST,
  types: [RESET_CACHE, RESET_CACHE_SUCCESS, RESET_CACHE_ERROR],
  call: () => resetUserCache(),
});

export const loadTrainerActivity = id => ({
  type: API_REQUEST,
  types: [LOADING_TRAINER_ACTIVITY, LOADING_TRAINER_ACTIVITY_SUCCESS, LOADING_USERS_FAILED],
  call: () => getTrainerActivity(id),
});

export const loadTrainerStudents = id => ({
  type: API_REQUEST,
  types: [LOADING_USER_CONNECTIONS, LOADING_USER_CONNECTIONS_SUCCESS, LOADING_USER_CONNECTIONS_FAILED],
  call: () => getTrainerStudents(id),
});

export const loadTrainerRequests = id => ({
  type: API_REQUEST,
  types: [LOADING_TRAINER_REQUESTS, LOADING_TRAINER_REQUESTS_SUCCESS, LOADING_TRAINER_REQUESTS_FAILED],
  call: () => getTrainerRequests(id),
});

export const loadTrainerUpcoming = id => ({
  type: API_REQUEST,
  types: [LOADING_USER_UPCOMING, LOADING_USER_UPCOMING_SUCCESS, LOADING_USER_UPCOMING_FAILED],
  call: () => getTrainerUpcoming(id),
});

export const loadRiderUpcoming = id => ({
  type: API_REQUEST,
  types: [LOADING_USER_UPCOMING, LOADING_USER_UPCOMING_SUCCESS, LOADING_USER_UPCOMING_FAILED],
  call: () => getRiderUpcoming(id),
});

export const submitDeclineActivity = (id, declineMessage) => ({
  type: API_REQUEST,
  types: [CHANGE_ACTIVITY, CHANGE_ACTIVITY_SENT, CHANGE_ACTIVITY_ERROR],
  call: () => declineActivity(id, declineMessage),
});

export const submitAcceptActivity = (type, id, acceptMessage) => ({
  type: API_REQUEST,
  types: [CHANGE_ACTIVITY, CHANGE_ACTIVITY_SENT, CHANGE_ACTIVITY_ERROR],
  call: () => acceptActivity(type, id, acceptMessage),
});

export const submitCancelActivity = (id, cancelMessage) => ({
  type: API_REQUEST,
  types: [CHANGE_ACTIVITY, CHANGE_ACTIVITY_SENT, CHANGE_ACTIVITY_ERROR],
  call: () => cancelActivity(id, cancelMessage),
});

export const resetActivity = () => ({ type: CHANGE_ACTIVITY });

export const loadCompetences = () => ({
  type: API_REQUEST,
  types: [COMPETENCES, COMPETENCES_SUCCESS, COMPETENCES_ERROR],
  call: () => getCompetences(),
});

export const loadRiderTrainers = id => ({
  type: API_REQUEST,
  types: [LOADING_USER_CONNECTIONS, LOADING_USER_CONNECTIONS_SUCCESS, LOADING_USER_CONNECTIONS_FAILED],
  call: () => getRiderTrainers(id),
});

export const loadCountries = () => ({
  type: API_REQUEST,
  types: [LOADING_COUNTRIES, LOADING_COUNTRIES_SUCCESS, LOADING_COUNTRIES_ERROR],
  call: () => getCountries(),
});

export const loadDistricts = () => ({
  type: API_REQUEST,
  types: [LOADING_DISTRICTS, LOADING_DISTRICTS_SUCCESS, LOADING_DISTRICTS_ERROR],
  call: () => getDistricts(),
});

export const loadAdminComments = id => ({
  type: API_REQUEST,
  types: [LOADING_ADMIN_COMMENTS, LOADING_ADMIN_COMMENTS_SUCCESS, LOADING_ADMIN_COMMENTS_ERROR],
  call: () => getAdminComments(id),
});

export const sendAdminComments = commentObj => ({
  type: API_REQUEST,
  types: [POST_ADMIN_COMMENTS, POST_ADMIN_COMMENTS_SUCCESS, POST_ADMIN_COMMENTS_ERROR],
  call: () => postAdminComments(commentObj),
});

export const loadTrainerReviews = trainerId => ({
  type: API_REQUEST,
  types: [LOAD_TRAINER_REVIEWS, LOAD_TRAINER_REVIEWS_SUCCESS, LOAD_TRAINER_REVIEWS_FAIL],
  call: () => getNewlyAddedTrainerReviews(trainerId),
});

export const approveTrainerRecommendation = reviewId => ({
  type: API_REQUEST,
  types: [APPROVE_TRAINER_REVIEW, APPROVE_TRAINER_REVIEW_SUCCESS, APPROVE_TRAINER_REVIEW_FAIL],
  call: () => approveTrainerReview(reviewId),
});

export const rejectTrainerRecommendation = reviewId => ({
  type: API_REQUEST,
  types: [REJECT_TRAINER_REVIEW, REJECT_TRAINER_REVIEW_SUCCESS, REJECT_TRAINER_REVIEW_FAIL],
  call: () => rejectTrainerReview(reviewId),
});

export const searchTrainerReviews = status => ({
  type: API_REQUEST,
  types: [SEARCH_TRAINER_REVIEWS, SEARCH_TRAINER_REVIEWS_SUCCESS, SEARCH_TRAINER_REVIEWS_FAIL],
  call: () => getFilteredTrainerReviewsForAdmin(status),
});
