import React from 'react';
import './styles.scss';
import deleteImage from '../assets/delete.png';
import classNames from 'classnames';

const Modal = ({ image, title, message, onClose, buttons, children, double, isSmall, className }) => (
  <div className={classNames('new-modal', double && 'new-modal-no-bg')}>
    <div className={classNames('new-modal-inner', isSmall && 'new-modal-inner--small', className)}>
      <div className="new-modal-close-wrapper">
        <button type="button" className="new-modal-close-button" onClick={onClose}>
          <img src={deleteImage} alt="Close" />
        </button>
      </div>
      {title && <div className="new-modal-title">{title}</div>}
      {image && <img className="new-modal-image" src={image} alt="" />}
      {message && <div className="new-modal-message">{message}</div>}
      {children && <div className="new-modal-content">{children}</div>}
      {buttons && <div className="new-modal-button-wrapper">{buttons}</div>}
    </div>
  </div>
);
export default Modal;
