import React from 'react';

import { t } from '../../../i18n';
import UrlTo from '../../../url-to';

import './styles.scss';

import Menu from './Menu';
import SingleMenuItem from './SingleMenuItem';
import ParentMenuItem from './ParentMenuitem';
import ChildMenuItem from './ChildMenuItem';
import LoginButtonMenyItem from './LoginButtonMenuItem';
import SelectLanguageMemuItem from './SelectLanguageMenuItem';

const Header = () => {
  return (
    <Menu href={UrlTo.trainersOverview()}>
      <SingleMenuItem title={t('WEB_TRAINERS.HEADER.HOME')} href={UrlTo.offSiteHome()} />
      <ParentMenuItem
        id="parent_menu_item_trainers"
        title={t('WEB_TRAINERS.HEADER.TRAINERS')}
        href={UrlTo.offSiteTrainers()}
      >
        <ChildMenuItem
          title={t('WEB_TRAINERS.HEADER.TRAINERS__POCKET_OFFICE')}
          href={UrlTo.offSitePocketOffice()}
        />
        <ChildMenuItem
          title={t('WEB_TRAINERS.HEADER.TRAINERS__TUTORIALS')}
          href={UrlTo.offSiteTrainersTutorials()}
        />
        <ChildMenuItem
          title={t('WEB_TRAINERS.HEADER.TRAINERS__MARKETING_MATERIAL')}
          href={UrlTo.offSiteMarketingMaterials()}
        />
        <ChildMenuItem title={t('WEB_TRAINERS.HEADER.TRAINERS__ACTIVATE_PROMOCODE')} href={UrlTo.offSitePromocode()} />
      </ParentMenuItem>
      <ParentMenuItem
        id="parent_menu_item_riders"
        title={t('WEB_TRAINERS.HEADER.RIDERS')}
        href={UrlTo.offSiteRiders()}
      >
        <ChildMenuItem
          title={t('WEB_TRAINERS.HEADER.RIDERS__RIDER_PREMIUM')}
          href={UrlTo.offSiteRidersPremium()}
        />
        <ChildMenuItem
          title={t('WEB_TRAINERS.HEADER.RIDERS__TUTORIALS')}
          href={UrlTo.offSiteRidersTutorials()}
        />
        <ChildMenuItem
          title={t('WEB_TRAINERS.HEADER.RIDERS__ACTIVATE_PROMOCODE')}
          href={UrlTo.offSitePromocode()}
        />
        <ChildMenuItem
          title={t('WEB_TRAINERS.HEADER.RIDERS__BOOK_TEST_TRAINING')}
          href={UrlTo.offSiteContact()}
        />
        <ChildMenuItem
          title={t('WEB_TRAINERS.HEADER.BOOK_AND_CONNECT')}
          href={UrlTo.trainersOverview()}
        />
      </ParentMenuItem>
      <SingleMenuItem title={t('WEB_TRAINERS.HEADER.NEWS')} href={UrlTo.offSiteNews()} />
      <ParentMenuItem
        id="parent_menu_item_support"
        title={t('WEB_TRAINERS.HEADER.SUPPORT')}
        href={UrlTo.offSiteSupport()}
      >
        <ChildMenuItem
          title={t('WEB_TRAINERS.HEADER.SUPPORT__RIDESUM_SUPPORT')}
          href={UrlTo.offSiteCustomerSupport()}
        />
        <ChildMenuItem
          title={t('WEB_TRAINERS.HEADER.SUPPORT__CONTACT_US')}
          href={UrlTo.offSiteContact()}
        />
        <ChildMenuItem title={t('WEB_TRAINERS.HEADER.SUPPORT__Q_AND_A')} href={UrlTo.offSiteSupport()} />
      </ParentMenuItem>
      <LoginButtonMenyItem
        title={t('WEB_TRAINERS.HEADER.LOGIN_TRAINER')}
        href={UrlTo.offSiteTrainersLogin()}
      />
      <SelectLanguageMemuItem />
    </Menu>
  );
};

export default Header;
