import React from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { FullScreenBg, Loader, RouteChangeGATracker } from './components';
import Trainers from './trainer';
import UrlTo from './url-to';
import createStore from './store';
import Storage from './storage';
import { saveStore } from './store/getStore';

const history = createBrowserHistory();
export const store = createStore(history);
saveStore(store);

const isAdmin = () => Storage.role === 'Administrator';
const isTrainer = () => Storage.role === 'Trainer';

const NotFound = React.lazy(() => import('./404'));
const Login = React.lazy(() => import('./login'));
const TrainerStream = React.lazy(() => import('./stream/pages/trainer-stream'));
const RiderStream = React.lazy(() => import('./rider-stream'));
const RiderStreamOver = React.lazy(() => import('./rider-stream-over'));
const Calendar = React.lazy(() => import('./calendar'));
const Admin = React.lazy(() => import('./admin'));
const SearchProfile = React.lazy(() => import('./admin/components/search-profile'));
const SearchedTrainersReviews = React.lazy(() => import('./admin/components/searched-trainers-reviews'));
// FIXME: If you make this page lazy-loaded, the whole site gets broken... (especially digital courses)
// const Trainers = React.lazy(() => import('./trainer'));
const DigitalCourses = React.lazy(() => import('./digital-courses'));
const EditUser = React.lazy(() => import('./admin/components/edit-user'));
const AdministrateTrainerReview = React.lazy(() => import('./admin/components/edit-user/administrate-trainer-review'));
const Promocode = React.lazy(() => import('./promocode'));
const CoursesPayments = React.lazy(() => import('./admin/components/courses-payments'));
const CoursePaymentDetails = React.lazy(() => import('./admin/components/course-payment-details'));
const Sihs = React.lazy(() => import('./sihs'));
const SihsResult = React.lazy(() => import('./sihs/result'));
const AnalysesShare = React.lazy(() => import('./analyses/pages/share'));
const DunstanCreateWebMeeting = React.lazy(() => import('./dunstan/pages/dunstan-create-web-meeting'));
const DunstanCreateWebMeetingResult = React.lazy(() => import('./dunstan/pages/dunstan-create-web-meeting-result'));
const DunstanWebMeeting = React.lazy(() => import('./dunstan/pages/dunstan-web-meeting'));
const DunstanVideo = React.lazy(() => import('./dunstan/pages/dunstan-video'));
const GpsLive = React.lazy(() => import('./gps/pages/gps-live'));

const pageOrDefault = Page => navProps =>
  Storage.authToken ? (
    <Page {...navProps} />
  ) : (
    <Redirect to={UrlTo.login({ returnUrl: window.location.pathname + window.location.search })} />
  );

const defaultOrLogin = () => {
  if (Storage.authToken) {
    if (isAdmin()) {
      return <Admin />;
    }
    if (isTrainer()) {
      return <Calendar />;
    }
  }
  return <Login />;
};

const AppRouter = () => (
  <React.Suspense
    fallback={
      <FullScreenBg>
        <Loader />
      </FullScreenBg>
    }
  >
    <Router history={history}>
      <>
        <RouteChangeGATracker />
        <Switch>
          <Route exact path="/" render={() => defaultOrLogin()} />
          <Route path={UrlTo.trainer.stream()} render={pageOrDefault(TrainerStream)} />
          <Route path={UrlTo.rider.stream()} component={RiderStream} />
          <Route path={UrlTo.rider.streamOver()} component={RiderStreamOver} />
          <Route path="/calendar" render={pageOrDefault(Calendar)} />
          <Route path="/kalender" render={pageOrDefault(Calendar)} />
          <Route path="/admin" render={pageOrDefault(Admin)} />
          <Route path="/promocode" render={() => <Promocode />} />
          <Route path={UrlTo.admin.searchProfile()} render={pageOrDefault(SearchProfile)} />
          <Route path={UrlTo.admin.viewProfile(':userId')} render={pageOrDefault(EditUser)} />
          <Route path={UrlTo.admin.searchedTrainersReviews()} render={pageOrDefault(SearchedTrainersReviews)} />
          <Route
            path={UrlTo.admin.administrateTrainerReview(':reviewId')}
            render={pageOrDefault(AdministrateTrainerReview)}
          />
          <Route exact path={UrlTo.admin.coursesPayments()} render={pageOrDefault(CoursesPayments)} />
          <Route exact path={UrlTo.admin.coursePaymentDetails(':id')} render={pageOrDefault(CoursePaymentDetails)} />
          <Route path="/trainers" render={() => <Trainers />} />
          <Route path="/digitalakurser" render={() => <DigitalCourses />} />
          <Route path="/giftcard" exact component={Sihs} />
          <Route path="/giftcard/result" exact component={SihsResult} />
          <Route path="/analyses/:shareId" exact component={AnalysesShare} />
          <Route path={UrlTo.trainer.dunstanNewMeeting()} exact component={pageOrDefault(DunstanCreateWebMeeting)} />
          <Route
            path={UrlTo.trainer.dunstanNewMeetingResult()}
            exact
            component={pageOrDefault(DunstanCreateWebMeetingResult)}
          />
          <Route path="/dunstan" exact component={DunstanWebMeeting} />
          <Route path="/dunstan/video" exact component={pageOrDefault(DunstanVideo)} />
          <Route path="/gps" exact component={GpsLive} />
          <Route component={NotFound} />
        </Switch>
      </>
    </Router>
  </React.Suspense>
);

export default AppRouter;
