import React from 'react';
import RidesumLogo from '../../../components/ridesum-logo';

import './styles.scss';

const Menu = ({ children, href }) => (
  <nav className="trainer-header  navbar navbar-light bg-light navbar-expand-lg">
    <div className="trainer-header__container container-fluid">
      <a className="trainer-header__main-achor navbar-brand" href={href}>
        <RidesumLogo />
      </a>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        className="trainer-header__toggle-button navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#trainerHeaderCollapseNavbarWrapper"
        aria-controls="trainerHeaderCollapseNavbarWrapper"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <i className="trainer-header__toggle-button-icon fas fa-bars"></i>
      </a>
      <div
        className="trainer-header__collapse-navbar-wrapper  collapse navbar-collapse"
        id="trainerHeaderCollapseNavbarWrapper"
      >
        {/* desktop verion */}
        <ul className="trainer-header__collapse-navbar__desktop navbar-nav">{children}</ul>

        {/* mobile verion */}
        <div className="trainer-header__collapse-navbar__mobile accordion">{children}</div>
      </div>
    </div>
  </nav>
);

export default Menu;
