import { t } from 'i18n-js';
import React from 'react';
import { Button } from '../../../components';
import './styles.scss';

const SearchInput = ({ isNearMe, nameQuery, onChange, onSearch }) => {
  const handleInputChange = event => onChange(event.target);
  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      onSearch();
    }
  };
  return (
    <div className="search-input-component">
      <div className="search-input-component__input-row">
        <div className="search-input-component__input-wrapper">
          <i className="search-input-component__search-icon fa fa-search" aria-hidden="true" />
          <input
            name="nameQuery"
            className="search-input-component__search-text-input"
            type="search"
            value={nameQuery}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            placeholder={t('SEARCH_SCREEN.ENTER_TRAINER_NAME')}
            autoComplete="off"
          />
        </div>
        <Button text={t('SEARCH_SCREEN.SEARCH')} className="search-input-component__search-button" onClick={onSearch} />
      </div>
      {false && ( // not implemented by now and Mateusz asked to hide it RDS-2221
        <div className="search-input-component__near-me-wrapper">
          <div className="search-input-component__near-me-form-check">
            <input
              name="isNearMe"
              className="search-input-component__near-me-form-check-input"
              type="checkbox"
              id="flexCheckDefault"
              value={isNearMe}
              onChange={onChange}
            />
            <label className="search-input-component__near-me-form-check-label" htmlFor="flexCheckDefault">
              {t('SEARCH_TRAINER.SHOW_RESULTS_NEAR')}
            </label>
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchInput;
