import React, { Component } from 'react';
import { createLogger, flushLogs } from '../../utilities/log';
import { t } from '../../i18n';
import styles from './styles.module.scss';

const logger = createLogger('App');

class ErrorBoundary extends Component {
  state = {
    hasError: false,
  };

  componentDidCatch(error) {
    const logPayload = {
      error,
      route: window.location.href,
    };
    if (error.name === 'ChunkLoadError') {
      logger.warn('JS chunk loading failed', logPayload);
    } else if (error.code === 'CSS_CHUNK_LOAD_FAILED') {
      logger.warn('CSS chunk loading failed', logPayload);
    } else {
      logger.critical('Crash', logPayload);
    }
    flushLogs();
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (!this.state.hasError) {
      return this.props.children;
    }

    return (
      <div className={styles.container}>
        <h1 className={styles.title}>{t('ERROR_BOUNDARY.APP_CRASHED')}</h1>
        <p className={styles.message}>{t('ERROR_BOUNDARY.WE_WILL_PROCESS_PROBLEM')}</p>
        <p className={styles.message}>{t('ERROR_BOUNDARY.YOU_CAN_TRY_AGAIN')}</p>
      </div>
    );
  }
}
export default ErrorBoundary;
