import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import './styles.scss';

const CategoryCard = ({ interest, onClick, className, href }) => (
  <div className={className}>
    <Link to={href} style={{ textDecoration: 'none', display: 'block' }}>
      <div
        role="button"
        tabIndex="0"
        className={classNames('containerCardCategory')}
        style={{ backgroundImage: `url(${interest.coverPicture})` }}
        onClick={onClick}
      >
        {interest.displayName}
      </div>
    </Link>
  </div>
);
export default CategoryCard;
