import React from 'react';
import { Button } from '../../../components';
import { t } from '../../../i18n';
import CollapseMultiSelect from './CollapseMultiSelect';
import CollapsePriceSelect from './CollapsePriceSelect';
import SearchInput from './SearchInput';
import './styles.scss';

const createTitleWithImage = (imgSrc, text) => (
  <div className="search-trainers-component__title-wrapper">
    <img src={imgSrc} alt={text} />
    <span>{text}</span>
  </div>
);

const createTextTitle = (items, text) => {
  const selectedItems = items.filter(el => el.isSelected);
  return `${text} ${selectedItems.length ? `(${selectedItems.length})` : ''}`;
};

const createCountriesTitle = countries => {
  const selectedCountries = countries.filter(el => el.isSelected);
  switch (selectedCountries.length) {
    case 0:
      return t('SEARCH_SCREEN.COUNTRIES').toUpperCase();
    case 1:
      return createTitleWithImage(selectedCountries[0].flagUrl, selectedCountries[0].name);
    default:
      return createTitleWithImage(
        selectedCountries[0].flagUrl,
        `${selectedCountries[0].name} +${selectedCountries.length - 1}`,
      );
  }
};

const Search = ({ searchCondition, onChange, onSearch, onClearFilter, onClearAllFilters }) => (
  <div className="search-trainers-component">
    <h1 className="search-trainers-component__header_1">{t('SEARCH_SCREEN.SEARCH_YOUR_TRAINER')}</h1>
    <SearchInput
      showResultsNearMe={searchCondition.showResultsNearMe}
      nameQuery={searchCondition.nameQuery}
      onChange={onChange}
      onSearch={onSearch}
    />
    <div className="search-trainers-component__filters-wrapper">
      <CollapseMultiSelect
        name="countries"
        title={createCountriesTitle(searchCondition.countries)}
        data={searchCondition.countries}
        onChange={onChange}
        filterHasSelectedItems={searchCondition.countries.some(c => c.isSelected)}
        onClearFilter={onClearFilter}
      />
      {!!searchCondition.districts?.length && (
        <CollapseMultiSelect
          name="districts"
          title={createTextTitle(searchCondition.districts, t('SEARCH_SCREEN.DISTRICTS').toUpperCase())}
          data={searchCondition.districts}
          onChange={onChange}
          filterHasSelectedItems={searchCondition.districts.some(d => d.isSelected)}
          onClearFilter={onClearFilter}
        />
      )}
      <CollapseMultiSelect
        name="languages"
        title={createTextTitle(searchCondition.languages, t('SEARCH_SCREEN.LANGUAGES').toUpperCase())}
        data={searchCondition.languages}
        onChange={onChange}
        filterHasSelectedItems={searchCondition.languages.some(l => l.isSelected)}
        onClearFilter={onClearFilter}
      />
      <CollapseMultiSelect
        name="competences"
        title={createTextTitle(searchCondition.competences, t('SEARCH_SCREEN.COMPETENCES').toUpperCase())}
        data={searchCondition.competences}
        onChange={onChange}
        filterHasSelectedItems={searchCondition.competences.some(c => c.isSelected)}
        onClearFilter={onClearFilter}
        filterable
      />
      <CollapsePriceSelect
        name="priceValue"
        title={t('SEARCH_SCREEN.PRICE').toUpperCase()}
        subtitle={t('SEARCH_SCREEN.PER_45_MIN')}
        dataSource={searchCondition.priceRanges}
        value={searchCondition.priceValue}
        onChange={onChange}
        filterHasSelectedItems={searchCondition.priceValue}
        onClearFilter={onClearFilter}
      />
      <Button
        className="search-trainers-component__reset-filters-button"
        onClick={onClearAllFilters}
        text={t('SEARCH_SCREEN.CLEAR_ALL_FILTERS')}
      />
    </div>
    <Button
      id="search"
      type="green"
      className="search-trainers-component__search-button"
      onClick={onSearch}
      text={t('SEARCH_SCREEN.SEARCH')}
    />
  </div>
);

export default Search;
