export const getSearchDataSource = state => state.trainer.searchDataSource;
export const getSearchDataSourceLoading = state => state.trainer.loading;
export const getSearchDataSourceError = state => state.trainer.error;

export const getSearchDataSourceLanguages = state => state.trainer.searchDataSource.languages;

export const getSearchedTrainersState = state => state.trainer.searchedTrainers;
export const getSearchedTrainersList = state => state.trainer.searchedTrainers.data;

export const getHighlightedTrainersState = state => state.trainer.highlightedTrainers;
export const getStandardTrainersState = state => state.trainer.standardTrainers;

export const getInterests = state => state.trainer.interests;

export const getTrainerProfile = (state, trainerId) => state.trainer.profiles[trainerId];
export const getTrainerProfileLoading = state => state.trainer.profiles.loading;
export const getTrainerProfileError = state => state.trainer.profiles.error;

export const getSimilarTrainers = state => state.trainer.similarTrainers.data;
export const getSimilarTrainersLoading = state => state.trainer.similarTrainers.loading;
export const getSimilarTrainersError = state => state.trainer.similarTrainers.error;

export const getTrainerMaterials = state => state.trainer.materials.data;
export const getTrainerMaterialsLoading = state => state.trainer.materials.loading;
export const getTrainerMaterialsError = state => state.trainer.materials.error;

export const getTrainerReviews = state => state.trainer.reviews.data;
export const getTrainerReviewsLoading = state => state.trainer.reviews.loading;
export const getTrainerReviewsError = state => state.trainer.reviews.error;

export const getTrainerPhotoOriginal = trainer => trainer?.photo?.original;
export const getTrainerPhoto256x256 = trainer => trainer?.photo?.photo256x256 || getTrainerPhotoOriginal(trainer);
export const getTrainerPhoto72x72 = trainer => trainer?.photo?.photo72x72 || getTrainerPhoto256x256(trainer);
