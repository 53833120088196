import React from 'react';
import classNames from 'classnames';

import './styles.scss';

const ParentMenuItem = ({ id, href, onClick, title, imgSrc, children, dropDownMenuClassName }) => (
  <>
    {/* mobile version */}
    <div className="trainer-header__menu-item-parent trainer-header__menu-item-parent__mobile accordion-item">
      <div className="trainer-header__menu-item-parent-header accordion-header">
        <a
          className="trainer-header__menu-item-parent-header-link accordion-header"
          role="button"
          href={href}
          onClick={() => onClick && onClick()}
        >
          {imgSrc && (
            <span className="trainer-header__menu-item-parent-header-img-wrapper">
              <img className="trainer-header__menu-item-parent-header-img" src={imgSrc} alt={title} />
            </span>
          )}
          <span>{title}</span>
        </a>
        <button
          className="trainer-header__menu-item-parent-header-collapse-button"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#collups-${id}`}
          aria-expanded="false"
          aria-controls={`collups-${id}`}
        >
          <i className="trainer-header__menu-item-parent-header-collapse-button-icon fa fa-regular fa-chevron-down" />
        </button>
      </div>
      <div
        id={`collups-${id}`}
        className="trainer-header__menu-item-parent-collapse-panel  accordion-collapse collapse"
        aria-labelledby="panelsStayOpen-headingOne"
      >
        <ul className="trainer-header__menu-item-parent-collapse-panel-body accordion-body">{children}</ul>
      </div>
    </div>
    {/* desktop version */}
    <li className="trainer-header__menu-item-parent nav-item trainer-header__menu-item-parent__desktop dropdown">
      <a
        className="trainer-header__menu-item-parent-link nav-link "
        href={href}
        onClick={() => onClick && onClick()}
        id="trainerHeaderMenuItemParenLinkTrainers"
        role="button"
      >
        {imgSrc && (
          <span className="trainer-header__menu-item-parent-img-wrapper">
            <img className="trainer-header__menu-item-parent-img" src={imgSrc} alt={title} />
          </span>
        )}
        <span className="trainer-header__menu-item-parent-text">{title}</span>
        <span className="trainer-header__menu-item-parent-icon-wrapper">
          <i className="trainer-header__menu-item-parent-icon fa fa-regular fa-chevron-down" />
        </span>
      </a>
      <ul
        className={classNames(dropDownMenuClassName, 'trainer-header__menu-item-dropdown dropdown-menu')}
        aria-labelledby="trainerHeaderMenuItemParenLinkTrainers"
      >
        {children}
      </ul>
    </li>
  </>
);

export default ParentMenuItem;
