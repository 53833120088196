import React, { useState } from 'react';
import ReactStars from 'react-star-ratings';
import Modal from '../modal';
import { Row, Textarea, Button } from '..';

import { t } from '../../i18n';
import './styles.scss';
import { useDispatch } from 'react-redux';
import { submitSessionRating } from '../../stream/actions';

const RatingSession = ({ onResolve, activity }) => {
  const [videoRating, setVideoRating] = useState(0);
  const [videoComment, setVideoComment] = useState('');
  const [audioRating, setAudioRating] = useState(0);
  const [audioComment, setAudioComment] = useState('');
  const dispatch = useDispatch();

  const handleSubmit = () => {
    const rating = {
      sessionId: activity.sessionId,
      userId: activity.trainerId,
      activityId: activity.id,
      videoRating,
      videoComment,
      audioRating,
      audioComment,
    };
    dispatch(submitSessionRating(rating));
    onResolve();
  };

  return (
    <Modal title={t('RATE_YOUR_SESSION')} onClose={onResolve}>
      <div style={{ paddingLeft: 50 }}>
        <Row className="rating-row">
          <Row>
            <span className="rating-label-a">{t('DATE')}:</span>
          </Row>
          <span className="rating-label-b">{activity.date.split('T')[0]}</span>
        </Row>
        <Row className="rating-row">
          <Row>
            <span className="rating-label-a">{t('TIME')}:</span>
          </Row>
          <span className="rating-label-b">{activity.date.split('T')[1].slice(0, -4)}</span>
        </Row>
        <Row className="rating-row">
          <Row>
            <span className="rating-label-a">{t('DURATION')}:</span>
          </Row>
          <span className="rating-label-b">{activity.duration}</span>
        </Row>
        <Row className="rating-row">
          <Row>
            <span className="rating-label-a">{t('RIDER')}:</span>
          </Row>
          <span className="rating-label-b">{activity.riderName}</span>
        </Row>

        <Row className="rating-row" style={{ borderWidth: 2, borderColor: 'black' }}>
          <Row>
            <span className="rating-label-a-star">{t('HOW_WAS_THE_VIDEO_QUALITY')}:</span>
          </Row>
          <div style={{ marginLeft: 20 }}>
            <ReactStars
              rating={videoRating}
              numberOfStars={5}
              changeRating={newRating => setVideoRating(newRating)}
              name="videoRating"
              starHoverColor="rgb(243, 146, 0)"
              starRatedColor="rgb(243, 146, 0)"
              starDimension="40px"
              starSpacing="0px"
            />
          </div>
        </Row>

        <Textarea className="rating-input" value={videoComment} onChange={e => setVideoComment(e.target.value)} />

        <Row className="rating-row">
          <Row>
            <span className="rating-label-a-star">{t('HOW_WAS_THE_SOUND_QUALITY')}:</span>
          </Row>
          <div style={{ marginLeft: 20 }}>
            <ReactStars
              rating={audioRating}
              numberOfStars={5}
              changeRating={newRating => setAudioRating(newRating)}
              name="audioRating"
              starHoverColor="rgb(243, 146, 0)"
              starRatedColor="rgb(243, 146, 0)"
              starDimension="40px"
              starSpacing="0px"
            />
          </div>
        </Row>
        <Textarea className="rating-input" value={audioComment} onChange={e => setAudioComment(e.target.value)} />
        <div className="button-container">
          <Button text={t('SEND')} className="button-send" onClick={handleSubmit} />
        </div>
      </div>
    </Modal>
  );
};
export default RatingSession;
