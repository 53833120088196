import React from 'react';
import Container from 'react-bootstrap/Container';
import { useDispatch } from 'react-redux';
import { t } from '../../../i18n';
import HighlightedTrainerCard from '../../components/trainer-card/HighlightedTrainerCard';
import StandardTrainerCard from '../../components/trainer-card/StandardTrainerCard';
import CategoryCard from '../../components/category-card';
import TrainersCarousel from '../../components/trainers-carousel';
import UrlTo from '../../../url-to';
import useTrainersOverview from '../../useTrainersOverview';
import { logTrainerProfileViewed, TrainerProfileViewContext } from '../../statistics';
import { Helmet } from 'react-helmet';
import Subtitle from '../../components/subtitle';
import './styles.scss';

const OverviewScreen = () => {
  const {
    highlightedTrainers,
    highlightedTrainersHasMore,
    highlightedTrainersLoading,
    loadMoreHighlightedTrainers,
    standardTrainers,
    standardTrainersHasMore,
    standardTrainersLoading,
    loadMoreStandardTrainers,
    interests,
  } = useTrainersOverview();

  const dispatch = useDispatch();
  const logHighlightedCarouselItemPressed = id =>
    dispatch(logTrainerProfileViewed(id, TrainerProfileViewContext.highlightedCarousel));
  const logStandardCarouselItemPressed = id =>
    dispatch(logTrainerProfileViewed(id, TrainerProfileViewContext.standardCarousel));

  return (
    <Container className="trainer-overview-screen">
      <Helmet>
        <title>Ridesum - World's biggest horse trainer and expert database</title>
      </Helmet>
      <section aria-labelledby="highlightedTrainers">
        <Subtitle id="highlightedTrainers">{t('SEARCH_SCREEN.HIGHLIGHTED_TRAINERS')}</Subtitle>
        <TrainersCarousel
          trainers={highlightedTrainers}
          renderItem={trainer => (
            <HighlightedTrainerCard
              key={trainer.id}
              trainer={trainer}
              onSeeProfile={() => logHighlightedCarouselItemPressed(trainer.id)}
            />
          )}
          onLoadMore={loadMoreHighlightedTrainers}
          hasMore={highlightedTrainersHasMore}
          loading={highlightedTrainersLoading}
          PlaceholderComponent={HighlightedTrainerCard.Placeholder}
        />
      </section>
      <section aria-labelledby="standardTrainers">
        <Subtitle id="standardTrainers">{t('SEARCH_SCREEN.MORE_TRAINERS')}</Subtitle>
        <TrainersCarousel
          trainers={standardTrainers}
          renderItem={trainer => (
            <StandardTrainerCard
              key={trainer.id}
              trainer={trainer}
              onSeeProfile={() => logStandardCarouselItemPressed(trainer.id)}
            />
          )}
          onLoadMore={loadMoreStandardTrainers}
          hasMore={standardTrainersHasMore}
          loading={standardTrainersLoading}
          PlaceholderComponent={StandardTrainerCard.Placeholder}
        />
      </section>
      <section aria-labelledby="categories">
        <Subtitle id="categories">{t('SEARCH_SCREEN.CATEGORIES')}</Subtitle>
        <div className="row gx-3 gy-4">
          {interests?.map(item => (
            <CategoryCard
              key={item.id}
              href={UrlTo.categoryInfo(item.id, item.displayName)}
              interest={item}
              className="col-md-3"
            />
          ))}
        </div>
      </section>
    </Container>
  );
};

export default OverviewScreen;
