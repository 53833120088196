import dayjs from 'dayjs';
import { strftime, t } from '../i18n';

export const convertDate = date => {
  const year = strftime(new Date(date), '%Y');
  const month = strftime(new Date(date), '%b').toUpperCase();
  const yymmdd = new Date(date).toISOString().substring(0, 10);
  const day = new Date(date).getDate();
  const hour = strftime(new Date(date), '%H');
  const minute = strftime(new Date(date), '%M');
  const time = `${hour}:${minute}`;
  const fullDateTime = `${yymmdd}, ${hour}:${minute}`;
  const yearMonthDay = yymmdd;
  return { year, month, day, hour, minute, time, fullDateTime, yearMonthDay };
};

export const formatFlagTitle = title => {
  switch (title) {
    case 'training':
      return t('TRAINING_TYPE.training');
    case 'traditional_training':
      return t('TRAINING_TYPE.traditional_training');
    case 'feedback':
      return t('TRAINING_TYPE.feedback');
    case 'failed':
      return t('FAILED');
    case 'done':
      return t('DONE');
    case 'waiting':
    case 'Waiting':
      return t('WAITING');
    case t('TODAY'):
      return t('TODAY');
    case 'pending':
      return t('PENDING');
    case 'accepted':
      return t('ACCEPTED');
    case 'Trainer':
      return t('TRAINER');
    case 'Rider':
      return t('RIDER');
    case 'Free':
      return t('FREE');
    case 'Premium':
      return t('PREMIUM');
    case 'declined':
      return t('DECLINED');
    case 'cancelled':
      return t('CANCELLED');
    default:
      return '';
  }
};

export const getProductTitleById = productId => {
  switch (productId) {
    case 'premium.rider.basic':
      return `${t('PREMIUM')} ${t('BASIC')}`;
    case 'premium.rider.plus':
      return `${t('PREMIUM')} ${t('PLUS')}`;
    case 'premium.rider.diary.1month':
      return 'My Equi Toolkit 1m';
    case 'premium.rider.diary.6months':
      return 'My Equi Toolkit 6m';
    case 'premium.rider.diary.12months':
      return 'My Equi Toolkit 12m';
    case 'premium.rider.streaming.pro.1month':
      return 'Rider goes PRO 1m';
    case 'premium.rider.streaming.pro.3months':
      return 'Rider goes PRO 3m';
    case 'premium.rider.streaming.pro.6months':
      return 'Rider goes PRO 6m';
    case 'premium.rider.pro':
    case 'premium.trainer.pro':
      return `${t('PREMIUM')} ${t('PRO')}`;
    default:
      return `${t('PREMIUM')} (${productId})`;
  }
};

export const getProductTitle = user => {
  if (user) {
    const { premiumProductId, stripeAccountId, premium } = user;
    if (premium === 'active' || premium === 'cancelled') {
      return getProductTitleById(premiumProductId);
    }
    if (stripeAccountId) {
      return `${t('PREMIUM')} ${t('BASIC')}`;
    }
  }
  return t('FREE');
};

export const getPromocodeName = promocode => {
  const { id, productId, durationMonth } = promocode;
  const validFrom = dayjs(promocode.validFrom).format('YYYY-MM-DD');
  const validTo = dayjs(promocode.validTo).format('YYYY-MM-DD');
  const productTitle = getProductTitleById(productId);
  return t('PROMOCODE_DETAILED_TITLE', { id, productTitle, durationMonth, validFrom, validTo });
};
