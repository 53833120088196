import React from 'react';
import { Link } from 'react-router-dom';
import { ButtonLink } from '../../../components';
import { avatarIcon } from '../../../components/assets';
import { t } from '../../../i18n';
import UrlTo from '../../../url-to';
import { getTrainerPhoto72x72 } from '../../selectors';
import LanguagesList from '../languages-list';
import CompetencesList from './competences-list';
import LikesBlock from './likes-block';
import './styles.scss';

const StandardTrainerCard = ({ trainer, onSeeProfile }) => {
  const profileUrl = UrlTo.trainerProfile(trainer.id, trainer.name);
  return (
    <div className="trainers-trainer-card">
      <div className="trainers-trainer-card__standard-trainer-header">
        <Link to={profileUrl}>
          <img
            className="trainers-trainer-card__standard-trainer-avatar"
            src={getTrainerPhoto72x72(trainer) || avatarIcon}
            alt={trainer.name}
          />
        </Link>
        <div className="mainInfo">
          <div className="trainer-name-row">
            <b className="trainers-trainer-card__trainer-name" title={trainer.name}>
              {trainer.name}
            </b>
            <LikesBlock count={trainer.likes.amount} className="standard-trainer-likes" />
            {trainer.trainerReviewsData?.trainerAverageReviewRating && (
              <div className="trainers-trainer-card__reviews-average-rating trainers-trainer-card__reviews-average-rating_standard">
                {trainer.trainerReviewsData.trainerAverageReviewRating}
              </div>
            )}
          </div>
          <div className="oneLineBox company-name">{trainer.companyName}</div>
          <div className="oneLineBox">
            <LanguagesList languagesIds={trainer.languages} />
          </div>
          <div className="oneLineBox location">
            {trainer.city}, {trainer.country}
          </div>
        </div>
      </div>
      <div className="trainers-trainer-card__bottom-aligned-content">
        <div className="margin_bottom4">
          <CompetencesList competences={trainer.competences} />
        </div>
        <div className="border_line" style={{ marginBottom: 16 }} />
        <ButtonLink
          href={profileUrl}
          text={t('SEARCH_SCREEN.SEE_PROFILE')}
          type="green"
          className="trainers-trainer-card__see-profile"
          onClick={onSeeProfile}
        />
      </div>
    </div>
  );
};

StandardTrainerCard.Placeholder = () => (
  <div className="trainers-trainer-card placeholder-glow" style={{ minHeight: 209 }}>
    <div className="placeholder" />
  </div>
);

export default StandardTrainerCard;
