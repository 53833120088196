import * as actionTypes from './action-types';
import initialState from '../../../initial-state';

export default function checkoutReducer(state = initialState.giftCardCheckout, action) {
  switch (action.type) {
    case actionTypes.LOAD_GIFT_CARD_CHECKOUT:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case actionTypes.LOAD_GIFT_CARD_CHECKOUT_SUCCESS:
      return {
        ...state,
        data: action.result,
        isLoading: false,
      };
    case actionTypes.LOAD_GIFT_CARD_CHECKOUT_FAILED:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      };
    default:
      return state;
  }
}
