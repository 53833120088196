import React from 'react';
import classNames from 'classnames';
import './styles.scss';

const CollapsePanel = ({ className, title, subtitle, children }) => (
  <div className={classNames('collapse-panel-component', className)}>
    <button
      className="collapse-panel-component__collapse-button dropdown-toggle"
      type="button"
      id="dropdownMenuClickableInside"
      data-bs-toggle="dropdown"
      data-bs-auto-close="outside"
      aria-expanded="false"
      data-bs-offset="0,-2"
      data-display="static"
    >
      <div className="collapse-panel-component__collapse-button-title">
        <div className="collapse-panel-component__collapse-button-title-text-wrapper">
          {title}
          {!!subtitle && (
            <span className="collapse-panel-component__collapse-button-subtitle-text-wrapper">{subtitle}</span>
          )}
        </div>
        <div className="collapse-panel-component__collapse-button-title-icon-wrapper">
          <i className="collapse-panel-component__collapse-button-title-icon-down fas fa-chevron-down" />
          <i className="collapse-panel-component__collapse-button-title-icon-up fas fa-chevron-up" />
        </div>
      </div>
    </button>
    <ul
      className={classNames('collapse-panel-component__children-wrapper', 'dropdown-menu')}
      aria-labelledby="dropdownMenuClickableInside"
    >
      {children}
    </ul>
  </div>
);
export default CollapsePanel;
