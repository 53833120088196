import React, { Component } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

class Textarea extends Component {
  focus = e => {
    this.hasFocus = true;
    this.setSelectionIfNeeded(e.target);
  };

  blur = () => {
    this.hasFocus = false;
  };

  change = e => {
    if (this.isPlaceholding) {
      const index = e.target.value.indexOf(this.props.placeholder);
      if (index !== -1) {
        e.target.value = e.target.value.slice(0, index);
      }
    }
    if (this.props.onChange) {
      this.props.onChange(e);
    }
  };

  select = e => {
    if (this.isPlaceholding) {
      this.setSelectionIfNeeded(e.target);
      return false;
    }
    return null;
  };

  setSelectionIfNeeded = node => {
    if (this.hasFocus && this.isPlaceholding && (node.selectionStart !== 0 || node.selectionEnd !== 0)) {
      node.setSelectionRange(0, 0);
    } // if placeholder is visible, ensure cursor is at start of input
  };

  isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  render() {
    const { placeholder, value, className, ...props } = this.props;
    this.isPlaceholding = !value;

    return this.isSafari() ? (
      <textarea
        {...props}
        onFocus={this.focus}
        onBlur={this.blur}
        onChange={this.change}
        onSelect={this.select}
        className={classNames(className, !value && styles.placeholder)}
        value={value || placeholder}
      />
    ) : (
      <textarea {...this.props} />
    );
  }
}
export default Textarea;