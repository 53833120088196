import React, { useState } from 'react';
import ImageModal from './components/image-modal';
import './styles.scss';

const ImagePreview = ({ data }) => {
  const [showImageModal, setShowImageModal] = useState(false);

  return (
    <>
      <img className="image-preview" src={data.fileUrl} onClick={() => setShowImageModal(true)} alt="profile" />
      <ImageModal imageUrl={data.fileUrl} visible={showImageModal} onClose={() => setShowImageModal(false)} />
    </>
  );
};

export default ImagePreview;
