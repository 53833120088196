// HACK: Courses authentication should act like it's a separate domain:
//       otherwise trainers and admins could be authenticated in courses by mistake.
const isCoursesUrl =
  window.location.pathname.startsWith('/digitalakurser') || window.location.search.includes('digitalakurser');

const AUTH_TOKEN = isCoursesUrl ? '@RDS:Courses:AuthToken' : '@RDS:AuthToken';
const REFRESH_TOKEN = isCoursesUrl ? '@RDS:Courses:RefreshToken' : '@RDS:RefreshToken';
const REMEMBER_ME = isCoursesUrl ? '@RDS:Courses:RememberMe' : '@RDS:RememberMe';
const ROLE = isCoursesUrl ? '@RDS:Courses:Role' : '@RDS:Role';
const LOCALE = '@RDS:Locale';
const COURSES_PROMOCODE = '@RDS:Courses:Promocode';

let storage = localStorage.getItem(REMEMBER_ME) === 'true' ? localStorage : sessionStorage;

const Storage = {
  get authToken() {
    return storage.getItem(AUTH_TOKEN);
  },
  set authToken(value) {
    if (value) {
      storage.setItem(AUTH_TOKEN, value);
    } else {
      storage.removeItem(AUTH_TOKEN);
    }
  },

  get refreshToken() {
    return storage.getItem(REFRESH_TOKEN);
  },
  set refreshToken(value) {
    if (value) {
      storage.setItem(REFRESH_TOKEN, value);
    } else {
      storage.removeItem(REFRESH_TOKEN);
    }
  },

  get role() {
    return storage.getItem(ROLE);
  },
  set role(value) {
    if (value) {
      storage.setItem(ROLE, value);
    } else {
      storage.removeItem(ROLE);
    }
  },

  get rememberMe() {
    return localStorage.getItem(REMEMBER_ME);
  },
  set rememberMe(value) {
    if (value) {
      localStorage.setItem(REMEMBER_ME, 'true');
      storage = localStorage;
      return;
    }
    localStorage.removeItem(REMEMBER_ME);
    storage = sessionStorage;
  },

  get locale() {
    return storage.getItem(LOCALE);
  },
  set locale(value) {
    if (value) {
      storage.setItem(LOCALE, value);
    } else {
      storage.removeItem(LOCALE);
    }
  },

  courses: {
    get promocode() {
      return sessionStorage.getItem(COURSES_PROMOCODE);
    },
    set promocode(value) {
      sessionStorage.setItem(COURSES_PROMOCODE, value);
    },
  },
};
export default Storage;
