import * as actionTypes from './action-types';
import initialState from '../../../initial-state';

export default function profileReducer(state = initialState.profile, action) {
  switch (action.type) {
    case actionTypes.LOAD_COURSES_PROFILE:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case actionTypes.LOAD_COURSES_PROFILE_SUCCESS:
      return {
        ...state,
        data: { ...action.result },
        isLoading: false,
      };
    case actionTypes.LOAD_COURSES_PROFILE_FAIL:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      };
    default:
      return state;
  }
}
