import React from 'react';
import { Button, Modal } from '..';

const OkModal = ({ onResolve, title, message }) => (
  <Modal
    title={title}
    message={message}
    buttons={<Button text="OK" onClick={onResolve} />}
    onClose={onResolve}
    isSmall
  />
);

export default OkModal;
