import { API_REQUEST } from '../store/sagas/api';
import { getActivities, getActivityRequests, declineActivity, acceptActivity } from '../backend-api';

const LOADING_ACTIVITY = 'LOADING_ACTIVITY';
const LOADING_ACTIVITY_SUCCESS = 'LOADING_ACTIVITY_SUCCESS';
const LOADING_ACTIVITY_FAILED = 'LOADING_ACTIVITY_FAILED';

const LOAD_ACTIVITY_REQUESTS = 'LOAD_ACTIVITY_REQUESTS';
const LOAD_ACTIVITY_REQUESTS_SUCEESS = 'LOAD_ACTIVITY_REQUESTS_SUCEESS';
const LOAD_ACTIVITY_REQUESTS_FAILED = 'LOAD_ACTIVITY_REQUESTS_FAILED';

const LOADING_ACTIVITY_MORE = 'LOADING_ACTIVITY_MORE';
const LOADING_ACTIVITY_MORE_SUCCESS = 'LOADING_ACTIVITY_MORE_SUCCESS';
const LOADING_ACTIVITY_MORE_FAILED = 'LOADING_ACTIVITY_MORE_FAILED';

const FEEDBACK_SENT = 'calendar/FEEDBACK_SENT';

const DECLINE_ACTIVITY_REQUEST = 'DECLINE_ACTIVITY_REQUEST';
const DECLINE_ACTIVITY_REQUEST_SUCCESS = 'DECLINE_ACTIVITY_REQUEST_SUCCESS';
const DECLINE_ACTIVITY_REQUEST_FAILED = 'DECLINE_ACTIVITY_REQUEST_FAILED';

const ACCEPT_ACTIVITY_REQUEST = 'ACCEPT_ACTIVITY_REQUEST';
const ACCEPT_ACTIVITY_REQUEST_SUCCESS = 'ACCEPT_ACTIVITY_REQUEST_SUCCESS';
const ACCEPT_ACTIVITY_REQUEST_FAILED = 'ACCEPT_ACTIVITY_REQUEST_FAILED';

const initialState = {
  error: null,
  loading: false,
  loadingMore: false,
  activities: null,
  more: false,
  feedback: null,
  bookingRequests: {
    data: null,
    loading: false,
    error: false,
  },
};

export default function sessionReducer(state = initialState, action) {
  switch (action.type) {
    case LOADING_ACTIVITY:
      return { ...initialState, loading: true };

    case LOADING_ACTIVITY_MORE:
      return { ...state, loadingMore: true };

    case LOADING_ACTIVITY_SUCCESS:
      return {
        ...state,
        activities: action.result,
        loading: false,
        more: action.result.length === 15,
      };
    case LOADING_ACTIVITY_MORE_SUCCESS:
      return {
        ...state,
        loadingMore: false,
        activities: [...state.activities, ...action.result],
        more: action.result.length === 15,
      };
    case LOAD_ACTIVITY_REQUESTS: {
      return {
        ...state,
        bookingRequests: {
          ...state.bookingRequests,
          loading: true,
        },
      };
    }
    case LOAD_ACTIVITY_REQUESTS_SUCEESS: {
      return {
        ...state,
        bookingRequests: {
          ...state.bookingRequests,
          data: action.result,
          loading: false,
        },
      };
    }
    case LOAD_ACTIVITY_REQUESTS_FAILED: {
      return {
        ...state,
        bookingRequests: {
          ...state.bookingRequests,
          loading: false,
          error: true,
        },
      };
    }
    case FEEDBACK_SENT:
      return {
        ...state,
        activities: state.activities.filter(a => a.id !== action.activityId),
      };
    case DECLINE_ACTIVITY_REQUEST:
    case ACCEPT_ACTIVITY_REQUEST:
      return {
        ...state,
        bookingRequests: {
          ...state.bookingRequests,
          data: state.bookingRequests.data.filter(request => request.id !== action.id),
        },
      };
    case ACCEPT_ACTIVITY_REQUEST_SUCCESS:
      return {
        ...state,
        activities: [action.result, ...state.activities],
      };
    default:
      return state;
  }
}

export const loadActivities = () => ({
  type: API_REQUEST,
  types: [LOADING_ACTIVITY, LOADING_ACTIVITY_SUCCESS, LOADING_ACTIVITY_FAILED],
  call: () => getActivities(),
});

export const loadBookingRequests = () => ({
  type: API_REQUEST,
  types: [LOAD_ACTIVITY_REQUESTS, LOAD_ACTIVITY_REQUESTS_SUCEESS, LOAD_ACTIVITY_REQUESTS_FAILED],
  call: () => getActivityRequests(20, 0),
});

export const loadActivitiesMore = skip => ({
  type: API_REQUEST,
  types: [LOADING_ACTIVITY_MORE, LOADING_ACTIVITY_MORE_SUCCESS, LOADING_ACTIVITY_MORE_FAILED],
  call: () => getActivities(skip),
});

export const feedbackSent = activityId => ({
  type: FEEDBACK_SENT,
  activityId,
});

export const declineActivityRequest = (id, message) => ({
  type: API_REQUEST,
  types: [DECLINE_ACTIVITY_REQUEST, DECLINE_ACTIVITY_REQUEST_SUCCESS, DECLINE_ACTIVITY_REQUEST_FAILED],
  call: () => declineActivity(id, message),
  id,
});

export const acceptActivityRequest = (type, id, message) => ({
  type: API_REQUEST,
  types: [ACCEPT_ACTIVITY_REQUEST, ACCEPT_ACTIVITY_REQUEST_SUCCESS, ACCEPT_ACTIVITY_REQUEST_FAILED],
  call: () => acceptActivity(type, id, message),
  id,
});
