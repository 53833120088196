import I18n from 'i18n-js';
import dayjs from 'dayjs';
import sv from './sv';
import en from './en';
import UrlTo from '../url-to';

const Locale = {
  SWEDISH: 'sv-SE',
  ENGLISH: 'en-US',
};

const applyLocale = () => {
  const getLocaleFromDomain = () => {
    const shouldForceSwedish = document.location.pathname.includes(UrlTo.coursesHome());
    if (shouldForceSwedish) {
      return Locale.SWEDISH;
    }

    const isSwedishTld = document.location.host.endsWith('.se');
    if (isSwedishTld) {
      return Locale.SWEDISH;
    }
    return Locale.ENGLISH;
  };

  const language = getLocaleFromDomain();

  I18n.defaultLocale = Locale.ENGLISH;
  I18n.locale = language;
  I18n.fallbacks = true;
  I18n.translations = { sv, en };

  if (language === Locale.SWEDISH) {
    require(`dayjs/locale/sv`);
  }

  dayjs.locale(language);

  return I18n;
};

const setLocale = newLocale => {
  // If you need to add language switcher to a page which requires
  // localStorage/sessionStorage consider implementing a way
  // to pass the storages across domains
  switch (newLocale) {
    case Locale.SWEDISH:
      window.location.href = window.location.href.replace('ridesum.com', 'ridesum.se');
      break;
    case Locale.ENGLISH:
      window.location.href = window.location.href.replace('ridesum.se', 'ridesum.com');
      break;
    default:
      throw new Error('Unsupported locale.');
  }
};

applyLocale();

export const t = (k, o) => I18n.t(k, o);
export const strftime = (d, f) => I18n.strftime(d, f);

export const isSwedish = () => I18n.locale === Locale.SWEDISH;
export const isEnglish = () => I18n.locale === Locale.ENGLISH;
export const setEnglish = () => setLocale(Locale.ENGLISH);
export const setSwedish = () => setLocale(Locale.SWEDISH);

export default I18n;
