import { API_REQUEST } from '../store/sagas/api';
import {
  getSearchParameters,
  getTrainersOverview,
  getHighlightedTrainers,
  getStandardTrainers,
  postSearchTrainers,
  getProfileTrainer,
  getCategoryInfo,
  getSimilarTrainers,
  getTrainerMaterials,
  getApprovedTrainerReviews,
} from '../backend-api';

const LOADING_TRAINERS_OVERVIEW = 'LOADING_TRAINERS_OVERVIEW';
const LOADING_TRAINERS_OVERVIEW_SUCCESS = 'LOADING_TRAINERS_OVERVIEW_SUCCESS';
const LOADING_TRAINERS_OVERVIEW_FAILED = 'LOADING_TRAINERS_OVERVIEW_FAILED';

const LOADING_SEARCH_PARAMETERS = 'LOADING_SEARCH_PARAMETERS';
const LOADING_SEARCH_PARAMETERS_SUCCESS = 'LOADING_SEARCH_PARAMETERS_SUCCESS';
const LOADING_SEARCH_PARAMETERS_FAILED = 'LOADING_SEARCH_PARAMETERS_FAILED';

const SEARCH_TRAINERS = 'SEARCH_TRAINERS';
const SEARCH_TRAINERS_SUCCESS = 'SEARCH_TRAINERS_SUCCESS';
const SEARCH_TRAINERS_FAILED = 'SEARCH_TRAINERS_FAILED';

const LOAD_HIGHLIGHTED_TRAINERS = 'LOAD_HIGHLIGHTED_TRAINERS';
const LOAD_HIGHLIGHTED_TRAINERS_SUCCESS = 'LOAD_HIGHLIGHTED_TRAINERS_SUCCESS';
const LOAD_HIGHLIGHTED_TRAINERS_FAILED = 'LOAD_HIGHLIGHTED_TRAINERS_FAILED';

const LOAD_STANDARD_TRAINERS = 'LOAD_STANDARD_TRAINERS';
const LOAD_STANDARD_TRAINERS_SUCCESS = 'LOAD_STANDARD_TRAINERS_SUCCESS';
const LOAD_STANDARD_TRAINERS_FAILED = 'LOAD_STANDARD_TRAINERS_FAILED';

const LOAD_PROFILE_TRAINER = 'LOAD_PROFILE_TRAINER';
const LOAD_PROFILE_TRAINER_SUCCESS = 'LOAD_PROFILE_TRAINER_SUCCESS';
const LOAD_PROFILE_TRAINER_FAILED = 'LOAD_PROFILE_TRAINER_FAILED';

const LOAD_CATEGORY_INFO = 'LOAD_CATEGORY_INFO';
const LOAD_CATEGORY_INFO_SUCCESS = 'LOAD_CATEGORY_INFO_SUCCESS';
const LOAD_CATEGORY_INFO_FAILED = 'LOAD_CATEGORY_INFO_FAILED';

const LOAD_SIMILAR_TRAINERS = 'LOAD_SIMILAR_TRAINERS';
const LOAD_SIMILAR_TRAINERS_SUCCESS = 'LOAD_SIMILAR_TRAINERS_SUCCESS';
const LOAD_SIMILAR_TRAINERS_FAILED = 'LOAD_SIMILAR_TRAINERS_FAILED';

const LOAD_TRAINER_MATERIALS = 'BookAndConnect/LOAD_TRAINER_MATERIALS';
const LOAD_TRAINER_MATERIALS_SUCCESS = 'BookAndConnect/LOAD_TRAINER_MATERIALS_SUCCESS';
const LOAD_TRAINER_MATERIALS_FAIL = 'BookAndConnect/LOAD_TRAINER_MATERIALS_FAIL';

const LOAD_TRAINER_REVIEWS = 'BookAndConnect/LOAD_TRAINER_REVIEWS';
const LOAD_TRAINER_REVIEWS_SUCCESS = 'BookAndConnect/LOAD_TRAINER_REVIEWS_SUCCESS';
const LOAD_TRAINER_REVIEWS_FAIL = 'BookAndConnect/LOAD_TRAINER_REVIEWS_FAIL';

const initialState = {
  error: null,
  loading: false,
  searchDataSource: {
    countries: [],
    districts: [],
    languages: [],
    competences: [],
    priceRanges: [],
  },
  searchedTrainers: {
    loading: false,
    error: true,
    hasMore: false,
    data: [],
    skip: null,
    count: null,
  },
  highlightedTrainers: {
    hasMore: false,
    data: null,
    loading: false,
    error: false,
  },
  standardTrainers: {
    hasMore: false,
    data: null,
    loading: false,
    error: false,
  },
  interests: [],
  similarTrainers: {
    data: null,
    loading: false,
    error: false,
  },
  profiles: {
    loading: false,
    error: false,
  },
  categories: {
    loading: false,
    error: false,
  },
  materials: {
    data: [],
    loading: false,
    error: false,
  },
  reviews: {
    data: [],
    loading: false,
    error: false,
  },
};

export default function trainerReducer(state = initialState, action) {
  switch (action.type) {
    case LOADING_SEARCH_PARAMETERS:
      return { ...state, loading: true };
    case LOADING_SEARCH_PARAMETERS_SUCCESS:
      return {
        ...state,
        loading: false,
        searchDataSource: { ...action.result },
      };

    case LOADING_TRAINERS_OVERVIEW:
      return {
        ...state,
        highlightedTrainers: {
          ...state.highlightedTrainers,
          loading: true,
          error: false,
        },
        standardTrainers: {
          ...state.standardTrainers,
          loading: true,
          error: false,
        },
      };
    case LOADING_TRAINERS_OVERVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        highlightedTrainers: {
          ...state.highlightedTrainers,
          data: action.result.highlightedTrainers.data,
          hasMore: action.result.highlightedTrainers.hasMore,
          loading: false,
        },
        standardTrainers: {
          ...state.standardTrainers,
          data: action.result.standardTrainers.data,
          hasMore: action.result.standardTrainers.hasMore,
          loading: false,
        },
        interests: action.result.interests,
      };
    case SEARCH_TRAINERS:
      return {
        ...state,
        searchedTrainers: {
          ...state.searchedTrainers,
          loading: true,
          error: false,
        },
      };
    case SEARCH_TRAINERS_SUCCESS:
      return {
        ...state,
        searchedTrainers: {
          ...state.searchedTrainers,
          skip: action.result.skip,
          count: action.result.count,
          hasMore: action.result.hasMore,
          data: action.result.skip === 0 ? action.result.data : [...state.searchedTrainers.data, ...action.result.data],
          loading: false,
        },
      };
    case SEARCH_TRAINERS_FAILED:
      return {
        ...state,
        searchedTrainers: {
          ...state.searchedTrainers,
          loading: false,
          error: true,
        },
      };
    case LOAD_HIGHLIGHTED_TRAINERS:
      return {
        ...state,
        highlightedTrainers: {
          ...state.highlightedTrainers,
          loading: true,
          error: false,
        },
      };
    case LOAD_HIGHLIGHTED_TRAINERS_SUCCESS:
      return {
        ...state,
        highlightedTrainers: {
          ...state.highlightedTrainers,
          data: [...state.highlightedTrainers.data, ...action.result.data],
          hasMore: action.result.hasMore,
          loading: false,
        },
      };
    case LOAD_STANDARD_TRAINERS:
      return {
        ...state,
        standardTrainers: {
          ...state.standardTrainers,
          loading: true,
          error: false,
        },
      };
    case LOAD_STANDARD_TRAINERS_SUCCESS:
      return {
        ...state,
        standardTrainers: {
          ...state.standardTrainers,
          data: [...state.standardTrainers.data, ...action.result.data],
          hasMore: action.result.hasMore,
          loading: false,
        },
      };
    case LOAD_PROFILE_TRAINER:
      return {
        ...state,
        profiles: {
          ...state.profiles,
          loading: true,
          error: false,
        },
      };
    case LOAD_PROFILE_TRAINER_SUCCESS:
      return {
        ...state,
        profiles: {
          ...state.profiles,
          [action.result.id]: action.result,
          loading: false,
          error: false,
        },
      };
    case LOAD_PROFILE_TRAINER_FAILED:
      return {
        ...state,
        profiles: {
          ...state.profiles,
          loading: false,
          error: true,
        },
      };
    case LOAD_SIMILAR_TRAINERS:
      return {
        ...state,
        similarTrainers: {
          data: null,
          loading: true,
          error: false,
        },
      };
    case LOAD_SIMILAR_TRAINERS_SUCCESS:
      return {
        ...state,
        similarTrainers: {
          ...state.similarTrainers,
          data: action.result.data,
          loading: false,
        },
      };
    case LOAD_SIMILAR_TRAINERS_FAILED:
      return {
        ...state,
        similarTrainers: {
          ...state.similarTrainers,
          loading: false,
          error: true,
        },
      };
    case LOAD_CATEGORY_INFO:
      return {
        ...state,
        categories: {
          ...state.categories,
          loading: true,
          error: false,
        },
      };
    case LOAD_CATEGORY_INFO_SUCCESS:
      return {
        ...state,
        categories: {
          ...state.categories,
          [action.result.discipline?.id]: action.result,
          loading: false,
          error: false,
        },
      };
    case LOAD_CATEGORY_INFO_FAILED:
      return {
        ...state,
        categories: {
          ...state.categories,
          loading: false,
          error: true,
        },
      };
    case LOAD_TRAINER_MATERIALS:
      return {
        ...state,
        materials: {
          ...state.materials,
          loading: true,
          error: false,
        },
      };
    case LOAD_TRAINER_MATERIALS_SUCCESS:
      return {
        ...state,
        materials: {
          data: action.result.materials,
          loading: false,
          error: false,
        },
      };
    case LOAD_TRAINER_MATERIALS_FAIL:
      return {
        ...state,
        materials: {
          ...state.materials,
          loading: false,
          error: true,
        },
      };
    case LOAD_TRAINER_REVIEWS:
      return {
        ...state,
        reviews: {
          ...state.reviews,
          loading: true,
          error: false,
        },
      };
    case LOAD_TRAINER_REVIEWS_SUCCESS:
      return {
        ...state,
        reviews: {
          data: action.result,
          loading: false,
          error: false,
        },
      };
    case LOAD_TRAINER_REVIEWS_FAIL:
      return {
        ...state,
        reviews: {
          ...state.reviews,
          loading: false,
          error: true,
        },
      };
    default:
      return state;
  }
}

export const loadTrainersOverview = () => ({
  type: API_REQUEST,
  types: [LOADING_TRAINERS_OVERVIEW, LOADING_TRAINERS_OVERVIEW_SUCCESS, LOADING_TRAINERS_OVERVIEW_FAILED],
  call: () => getTrainersOverview(),
});

export const loadSearchParameters = () => ({
  type: API_REQUEST,
  types: [LOADING_SEARCH_PARAMETERS, LOADING_SEARCH_PARAMETERS_SUCCESS, LOADING_SEARCH_PARAMETERS_FAILED],
  call: () => getSearchParameters(),
});

export const searchTrainers = params => ({
  type: API_REQUEST,
  types: [SEARCH_TRAINERS, SEARCH_TRAINERS_SUCCESS, SEARCH_TRAINERS_FAILED],
  call: () => postSearchTrainers(params),
});

export const loadHighlitedTrainers = params => ({
  type: API_REQUEST,
  types: [LOAD_HIGHLIGHTED_TRAINERS, LOAD_HIGHLIGHTED_TRAINERS_SUCCESS, LOAD_HIGHLIGHTED_TRAINERS_FAILED],
  call: () => getHighlightedTrainers(params),
});

export const loadStandardTrainers = params => ({
  type: API_REQUEST,
  types: [LOAD_STANDARD_TRAINERS, LOAD_STANDARD_TRAINERS_SUCCESS, LOAD_STANDARD_TRAINERS_FAILED],
  call: () => getStandardTrainers(params),
});

export const loadProfileTrainer = id => ({
  type: API_REQUEST,
  types: [LOAD_PROFILE_TRAINER, LOAD_PROFILE_TRAINER_SUCCESS, LOAD_PROFILE_TRAINER_FAILED],
  call: () => getProfileTrainer(id),
});

export const loadSimilarTrainers = id => ({
  type: API_REQUEST,
  types: [LOAD_SIMILAR_TRAINERS, LOAD_SIMILAR_TRAINERS_SUCCESS, LOAD_SIMILAR_TRAINERS_FAILED],
  call: () => getSimilarTrainers(id),
});

export const loadCategoryInfo = id => ({
  type: API_REQUEST,
  types: [LOAD_CATEGORY_INFO, LOAD_CATEGORY_INFO_SUCCESS, LOAD_CATEGORY_INFO_FAILED],
  call: () => getCategoryInfo(id),
});

export const loadTrainerMaterials = trainerId => ({
  type: API_REQUEST,
  types: [LOAD_TRAINER_MATERIALS, LOAD_TRAINER_MATERIALS_SUCCESS, LOAD_TRAINER_MATERIALS_FAIL],
  call: () => getTrainerMaterials(trainerId),
});

export const loadTrainerReviews = trainerId => ({
  type: API_REQUEST,
  types: [LOAD_TRAINER_REVIEWS, LOAD_TRAINER_REVIEWS_SUCCESS, LOAD_TRAINER_REVIEWS_FAIL],
  call: () => getApprovedTrainerReviews(trainerId),
});
