import React from 'react';
import { t } from '../../../i18n';
import Modal from '../../modal';
import Button from '../../button';
import { mailImage } from '../../assets';

const FeedbackSentModal = ({ onResolve }) => (
  <Modal
    image={mailImage}
    title={t('YOUR_FEEDBACK_SENT')}
    message={t('RIDER_NOTIFIED')}
    onClose={onResolve}
    buttons={<Button text={t('OK')} type="green" onClick={onResolve} />}
  />
);
export default FeedbackSentModal;
