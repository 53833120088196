import React, { useEffect } from 'react';
import { withRouter, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactGA from 'react-ga4';
import { ScrollToTopOnNewPage } from '../components';
import Header from './components/header';
import Footer from '../components/common-footer/index';
import Search from './components/search';
import OverviewScreen from './screens/overview';
import SearchResultScreen from './screens/search-result';
import ProfileScreen from './screens/profile';
import TrainerReviewDetails from '../components/trainer-review-details';
import CategoryScreen from './screens/category';
import UrlTo from '../url-to';
import useTrainersSearch from './useTrainersSearch';
import './styles.scss';

const Trainers = ({ history, data }) => {
  const {
    searchCondition,
    handleChangeSearchCondition,
    handleSearch,
    handleSearchLoadMore,
    handleClearFilter,
    handleClearAllFilters,
  } = useTrainersSearch();

  useEffect(() => {
    document.body.style.backgroundColor = 'white';
  }, []);

  const searchAndFollowSearchPage = () => {
    handleSearch();

    ReactGA.event({ category: 'Trainers', action: 'Search' });

    if (history.location.pathname === UrlTo.searchResults()) {
      // To make one 'back' press redirect to previous non-search result page
      history.replace(UrlTo.searchResults());
      return;
    }
    history.push(UrlTo.searchResults());
  };

  return (
    <>
      <ScrollToTopOnNewPage />
      <Header />
      <div className="container-fluid">
        <Search
          searchCondition={searchCondition}
          onChange={handleChangeSearchCondition}
          onSearch={searchAndFollowSearchPage}
          onClearFilter={handleClearFilter}
          onClearAllFilters={handleClearAllFilters}
        />
      </div>
      <Switch>
        <Route exact path={UrlTo.trainersOverview()} render={() => <OverviewScreen />} />
        <Route
          path={UrlTo.searchResults()}
          render={() => (
            <SearchResultScreen
              trainers={data.searchedTrainers.data}
              onShowMore={handleSearchLoadMore}
              loading={data.searchedTrainers.loading}
              hasMore={data.searchedTrainers.hasMore}
            />
          )}
        />
        <Route path={UrlTo.trainerProfile(':id', '')} render={() => <ProfileScreen />} />
        <Route
          path={UrlTo.categoryInfo(':id', '')}
          render={() => <CategoryScreen isLoading={data.categories.loading} hasError={data.categories.error} />}
        />
        <Route
          path={UrlTo.trainerReviewDetails(':reviewId')}
          render={navProps => <TrainerReviewDetails {...navProps} />}
        />
      </Switch>
      <Footer />
    </>
  );
};

const mapStateToProps = state => ({
  data: state.trainer,
});

export default withRouter(connect(mapStateToProps)(Trainers));
