import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadHighlitedTrainers, loadStandardTrainers, loadTrainersOverview } from './actions';
import { getHighlightedTrainersState, getInterests, getStandardTrainersState } from './selectors';
import usePrevious from './usePrevious';
import i18n from '../i18n';

const useTrainersOverview = () => {
  const dispatch = useDispatch();
  const prevLocale = usePrevious(i18n.locale);

  const highlightedTrainersData = useSelector(getHighlightedTrainersState);
  const highlightedTrainers = highlightedTrainersData.data;
  const highlightedTrainersLoading = highlightedTrainersData.loading;
  const highlightedTrainersHasMore = highlightedTrainersData.hasMore;

  const standardTrainersData = useSelector(getStandardTrainersState);
  const standardTrainers = standardTrainersData.data;
  const standardTrainersLoading = standardTrainersData.loading;
  const standardTrainersHasMore = standardTrainersData.hasMore;

  const interests = useSelector(getInterests);

  useEffect(() => {
    if (prevLocale?.current !== prevLocale || !highlightedTrainers) {
      dispatch(loadTrainersOverview());
    }
  }, [i18n.locale]);

  const overviewPageSize = 12;

  const loadMoreHighlightedTrainers = useCallback(() => {
    dispatch(loadHighlitedTrainers({ skip: highlightedTrainers?.length ?? 0, take: overviewPageSize }));
  }, [highlightedTrainers]);

  const loadMoreStandardTrainers = useCallback(() => {
    dispatch(loadStandardTrainers({ skip: standardTrainers?.length ?? 0, take: overviewPageSize }));
  }, [standardTrainers]);

  return {
    highlightedTrainers,
    highlightedTrainersHasMore,
    highlightedTrainersLoading,
    loadMoreHighlightedTrainers,

    standardTrainers,
    standardTrainersHasMore,
    standardTrainersLoading,
    loadMoreStandardTrainers,

    interests,
  };
};

export default useTrainersOverview;
