import React from 'react';

import './styles.scss';

const ChildMenuItem = ({ href, onClick, title, imgSrc }) => (
  <>
    {/* desktop version */}
    <li className="trainer-header__menu-item-dropdown-item trainer-header__menu-item-child__desktop">
      <a
        href={href}
        onClick={() => onClick && onClick()}
        className="trainer-header__menu-item-dropdown-item-link dropdown-item"
      >
        {imgSrc ? (
          <span className="trainer-header__menu-item-dropdown-item-img-wrapper">
            <img className="trainer-header__menu-item-dropdown-item-img" src={imgSrc} alt={title} />
          </span>
        ) : (
          <span className="trainer-header__menu-item-dropdown-item-icon-wrapper">
            <i className="trainer-header__menu-item-dropdown-item-icon fa fa-regular fa-chevron-right" />
          </span>
        )}
        <span className="trainer-header__menu-item-dropdown-item-text">{title}</span>
      </a>
    </li>
    {/* mobile version */}
    <li className="trainer-header__menu-item-child trainer-header__menu-item-child__mobile">
      <a className="trainer-header__menu-item-child-link" href={href} onClick={() => onClick && onClick()}>
        {imgSrc ? (
          <span className="trainer-header__menu-item-child-img-wrapper">
            <img className="trainer-header__menu-item-child-img" src={imgSrc} alt={title} />
          </span>
        ) : (
          <span className="trainer-header__menu-item-child-link-icon-wrapper">
            <i className="trainer-header__menu-item-child-link-icon bi bi-dash"></i>
          </span>
        )}

        <span className="trainer-header__menu-item-child-link-title">{title}</span>
      </a>
    </li>
  </>
);

export default ChildMenuItem;
