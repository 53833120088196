import React from 'react';
import { ButtonLink } from '../../../../components';
import { t } from '../../../../i18n';
import UrlTo from '../../../../url-to';
import styles from './styles.module.scss';

const Prices = ({ trainer, onBookTrainer }) => {
  const prices = [
    !!trainer.price45Min && { title: t('SEARCH_SCREEN.PER_X_MIN', { minutes: '45 min' }), price: trainer.price45Min },
    ...trainer.prices.map(({ title, price }) => ({ title: t('SEARCH_SCREEN.PER_X_MIN', { minutes: title }), price })),
  ].filter(p => p);
  return (
    <div className={styles.container}>
      {prices.length > 0 && (
        <>
          <h5 className={styles.title}>{t('SEARCH_SCREEN.LENGTH_AND_TRAINING_PRICE')}</h5>
          {prices.map(({ title, price }) => (
            <div key={title + price}>
              <hr />
              <div className={styles['price-row']}>
                <div>{title}</div>
                <b className={styles['price-value']}>
                  {trainer.currency || 'SEK'} {price}
                </b>
              </div>
            </div>
          ))}
        </>
      )}
      <ButtonLink
        href={UrlTo.bookTrainer(trainer.id)}
        onClick={onBookTrainer}
        text={t('SEARCH_SCREEN.BOOK_TRAINER')}
        type="green"
        className={styles['book-button']}
      />
    </div>
  );
};

export default Prices;
