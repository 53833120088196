let store;

export function saveStore(newStore) {
  store = newStore;
}

/**
 * Avoids circular dependencies when used inside files
 * upon which the Redux store is dependant
 * @returns {{
 *   dispatch(action: object): Promise | void;
 *   getState(): object;
 * }}
 */
export default function getStore() {
  return store;
}
