import React from 'react';
import range from 'lodash/range';
import './styles.scss';

const Star = ({ image }) => {
  return <img className="stars-row__star" src={image} alt="star" />;
};

const StarsRow = ({ filledStarIcon, outlinedStarIcon, value, isReadOnly }) => {
  return (
    <div className="stars-row">
      {range(1, 6).map(item => (
        <Star key={item} image={value && item <= value ? filledStarIcon : outlinedStarIcon} isReadOnly={isReadOnly} />
      ))}
    </div>
  );
};

export default StarsRow;
