import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import 'bootstrap/dist/js/bootstrap.bundle';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './global.scss';
import ErrorBoundary from './components/error-boundary';
import AppRouter, { store } from './router';
import { PromiseDialogProvider } from './utilities/promise-dialog';

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <PromiseDialogProvider>
        <AppRouter />
      </PromiseDialogProvider>
    </Provider>
  </ErrorBoundary>,
  document.getElementById('root'),
);
