import * as actionTypes from './action-types';
import initialState from '../../../initial-state';

export default function checkoutReducer(state = initialState.checkout, action) {
  switch (action.type) {
    case actionTypes.LOAD_COURSES_CHECKOUT:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case actionTypes.LOAD_COURSES_CHECKOUT_SUCCESS:
      return {
        ...state,
        data: { ...action.result },
        isLoading: false,
      };
    case actionTypes.LOAD_COURSES_CHECKOUT_FAIL:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      };
    case actionTypes.UPDATE_COURSES_CHECKOUT_PRICE: {
      return {
        ...state,
        data: {
          ...state.data,
          price: {
            ...action.data,
          },
        },
      };
    }
    default:
      return state;
  }
}
