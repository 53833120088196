import React from 'react';
import ImagePreview from '../image-preview';
import VideoPreview from '../video-preview';
import DocumentPreview from '../document-preview';

const FilePreview = ({ data }) => {
  if (data.contentType.startsWith('image')) {
    return <ImagePreview data={data} />;
  }

  if (data.contentType.startsWith('video')) {
    return <VideoPreview data={data} />;
  }

  return <DocumentPreview data={data} />;
};

export default FilePreview;
