import React, { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import { connect } from 'react-redux';
import { t } from '../../i18n';
import { Column, Button, Loader, Textarea } from '../../components';
import { starOrangeFilled, starOrangeOutlined } from '../../components/assets';
import { interpolate } from '../../utilities';
import { loadTrainerReview, approveTrainerRecommendation, rejectTrainerRecommendation } from './actions';
import { successGreenCircleIcon } from '../../components/assets';
import StarsRow from '../../components/stars-row';
import './styles.scss';

const TrainerReviewDetails = ({
  match,
  history,
  review,
  loadTrainerReview,
  approveTrainerRecommendation,
  rejectTrainerRecommendation,
  hasError,
  isLoading,
  userIsAdmin,
}) => {
  const [showSuccessOperationAlert, setShowSuccessOperationAlert] = useState(false);
  const [successOperationMessage, setSuccessOperationMessage] = useState();
  const reviewId = match.params?.reviewId;

  useEffect(() => {
    loadTrainerReview(reviewId);
  }, [reviewId]);

  const handleApproveReview = async () => {
    await approveTrainerRecommendation(reviewId);
    setSuccessOperationMessage('Review has been successfully approved.');
    setShowSuccessOperationAlert(true);
  };

  const handleRejectReview = async () => {
    await rejectTrainerRecommendation(reviewId);
    setSuccessOperationMessage('Review has been rejected.');
    setShowSuccessOperationAlert(true);
  };

  const loadingIndicatorMustBeRendered = isLoading && !hasError && !showSuccessOperationAlert;
  const reviewDetailsMustBeRendered = !isLoading && !hasError && !showSuccessOperationAlert && review;
  const errorMustBeRendered = hasError && !isLoading && !showSuccessOperationAlert;
  const successOperationAlertMustBeRendered = showSuccessOperationAlert && !isLoading && !hasError;

  const renderLoadingIndication = () => (
    <div className="trainer-review-details__main-info-wrapper">
      <Loader />
    </div>
  );

  const renderReviewDetails = () => {
    return (
      <div className="trainer-review-details__main-info-wrapper">
        {userIsAdmin && (
          <div className="trainer-review-details__title">
            {interpolate(t('TRAINER_REVIEW_DETAILS_TITLE'), {
              trainerName: review?.trainerName ?? '',
              authorName: review?.authorName ?? '',
            })}
          </div>
        )}
        <div>
          <span className="trainer-review-details__question-text">
            {interpolate(t('TRAINER_REVIEW_FIRST_QUESTION'), { trainerName: review?.trainerName ?? '' })}
          </span>
          <div className="trainer-review-details__stars-row-wrapper">
            <StarsRow
              filledStarIcon={starOrangeFilled}
              outlinedStarIcon={starOrangeOutlined}
              value={review?.firstQuestionAnswer}
            />
          </div>
        </div>

        {review?.secondQuestionAnswer && (
          <div>
            <span className="trainer-review-details__question-text">
              {interpolate(t('TRAINER_REVIEW_SECOND_QUESTION'), { trainerName: review?.trainerName ?? '' })}
            </span>
            <div className="trainer-review-details__stars-row-wrapper">
              <StarsRow
                filledStarIcon={starOrangeFilled}
                outlinedStarIcon={starOrangeOutlined}
                value={review.secondQuestionAnswer}
              />
            </div>
          </div>
        )}

        {review?.thirdQuestionAnswer && (
          <div>
            <span className="trainer-review-details__question-text">
              {interpolate(t('TRAINER_REVIEW_THIRD_QUESTION'), { trainerName: review?.trainerName ?? '' })}
            </span>
            <div className="trainer-review-details__stars-row-wrapper">
              <StarsRow
                filledStarIcon={starOrangeFilled}
                outlinedStarIcon={starOrangeOutlined}
                value={review.thirdQuestionAnswer}
              />
            </div>
          </div>
        )}

        {review?.fourthQuestionAnswer && (
          <div>
            <span className="trainer-review-details__question-text">
              {interpolate(t('TRAINER_REVIEW_FOURTH_QUESTION'), { trainerName: review?.trainerName ?? '' })}
            </span>
            <div className="trainer-review-details__stars-row-wrapper">
              <StarsRow
                filledStarIcon={starOrangeFilled}
                outlinedStarIcon={starOrangeOutlined}
                value={review.fourthQuestionAnswer}
              />
            </div>
          </div>
        )}

        {review?.textFeedback && (
          <div>
            <span className="trainer-review-details__question-text">{t('TRAINER_TEXT_FEEDBACK_LABEL')}</span>
            <div>
              <Textarea
                className="trainer-review-details__text-feedback"
                value={review.textFeedback}
                placeholder={t('ADD_MESSAGE')}
                disabled
              />
            </div>
          </div>
        )}

        {userIsAdmin && review?.isAnonymous && (
          <div>
            <span className="trainer-review-details__anonymous-review-text">{t('RIDER_LEFT_ANONYMOUS_REVIEW')}</span>
          </div>
        )}

        {userIsAdmin && (
          <div className="trainer-review-details__decision-buttons-wrapper">
            <Button
              className="trainer-review-details__decision-button"
              type="green"
              text={t('APPROVE')}
              onClick={handleApproveReview}
            />
            <Button
              className="trainer-review-details__decision-button"
              type="red"
              text={t('REJECT')}
              onClick={handleRejectReview}
            />
          </div>
        )}
      </div>
    );
  };

  const renderSuccessOperationAlert = () => {
    return (
      <div className="trainer-review-details__success-operation-wrapper">
        <img style={{ marginBottom: 15 }} src={successGreenCircleIcon} alt="success-operation" />
        <Alert variant="info">{successOperationMessage}</Alert>
        <Button type="green" text={t('OK')} onClick={() => history.goBack()} />
      </div>
    );
  };

  return (
    <Column className="trainer-review-details">
      <div className="trainer-review-details__centering-wrapper">
        {loadingIndicatorMustBeRendered && renderLoadingIndication()}
        {reviewDetailsMustBeRendered && renderReviewDetails()}
        {errorMustBeRendered && <Alert variant="danger">{t('SERVER_ERROR')}</Alert>}
        {successOperationAlertMustBeRendered && !hasError && renderSuccessOperationAlert()}
      </div>
    </Column>
  );
};

export default connect(
  state => ({
    review: state.trainerReview.data,
    hasError: state.trainerReview.error,
    isLoading: state.trainerReview.loading,
  }),
  {
    loadTrainerReview,
    approveTrainerRecommendation,
    rejectTrainerRecommendation,
  },
)(TrainerReviewDetails);
