import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { getSearchDataSourceLanguages } from '../../selectors';
import styles from './styles.module.scss';

const LanguagesList = ({ languagesIds, allLanguages, className }) => {
  const languagesToShow = allLanguages?.filter(l => languagesIds?.includes(l.id));

  return (
    <div className={classNames(styles.container, className)}>
      {languagesToShow?.map(l => (
        <img key={l.id} src={l.flagUrl} alt={l.name} className={styles['flag-image']} title={l.name} />
      ))}
    </div>
  );
};

export default connect(state => ({
  allLanguages: getSearchDataSourceLanguages(state),
}))(LanguagesList);
