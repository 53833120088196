import classNames from 'classnames';
import React from 'react';
import './styles.scss';

const MultiSelectList = ({ data, onToggleItem }) => (
  <div className="multi-select-list">
    {data.map(item => (
      <li key={item.id} className="multi-select-list__toggle-button-wrapper">
        <button
          type="button"
          className={classNames(
            'multi-select-list__toggle-button',
            'dropdown-item',
            'toggle-item-component',
            { active: item.isSelected },
          )}
          data-bs-toggle="button"
          onClick={() => onToggleItem(item.id)}
        >
          {item.name}
        </button>
      </li>
    ))}
  </div>
);

export default MultiSelectList;
