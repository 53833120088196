import React from 'react';
import { file } from '../../../../../../components/assets';
import { clipLongFileNameButPreserveExtension, formatBytesSize } from '../../../../../../utilities';
import './styles.scss';

const DocumentPreview = ({ data }) => {
  return (
    <a
      className="document-preview"
      href={data.fileUrl}
      download={data.fileName}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img className="document-preview__icon" src={file} alt="file" />
      <div className="document-preview__file-info">
        <span className="document-preview__file-name" title={data.fileName}>
          {clipLongFileNameButPreserveExtension(data.fileName)}
        </span>
        <span className="document-preview__file-size">{formatBytesSize(data.fileSize)}</span>
      </div>
    </a>
  );
};

export default DocumentPreview;
