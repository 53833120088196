import ReactGA from 'react-ga4';

ReactGA.initialize('G-4W7FY6MDHS');
const openTokApiKey = process.env.REACT_APP_OPEN_TOK_API_KEY || '46471462';

const settings = {
  apiUrl: process.env.REACT_APP_RDS_API_URL || 'https://staging-api.ridesum.se',
  openTokApiKey,
  currency: 'kr',
  facebookPixelId: '1073039943075647',
  adminEmails: [
    'admin@ridesum.se',
    'mateusz.dziurla@ridesum.com',
    'alva.mardell@ridesum.com',
    'karin@ridesum.com',
    'team@ridesum.se',
    'celie.gunnarsson@ridesum.com',
  ],
};

export default settings;

export const CURRENCY_ARRAY = ['SEK', 'EUR', 'DKK', 'NOK', 'USD', 'GBP'];

export const TRAINER = 'Trainer';
export const RIDER = 'Rider';
