import React, { useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import i18n, { t } from '../../../i18n';
import { Loader } from '../../../components';
import Contacts from './contacts';
import AboutTrainer from './about-trainer';
import Prices from './prices';
import { loadProfileTrainer, loadSimilarTrainers, loadTrainerMaterials, loadTrainerReviews } from '../../actions';
import MixedTrainersList from '../../components/mixed-trainers-list';
import ProfileMaterials from './materials';
import TrainerReviews from './reviews';
import {
  getSearchDataSourceLanguages,
  getSimilarTrainers,
  getSimilarTrainersError,
  getSimilarTrainersLoading,
  getTrainerProfile,
  getTrainerProfileError,
  getTrainerProfileLoading,
  getTrainerMaterials,
  getTrainerMaterialsLoading,
  getTrainerMaterialsError,
  getTrainerReviews,
  getTrainerReviewsLoading,
  getTrainerReviewsError,
} from '../../selectors';
import defaultHeaderPhotoImage from './assets/defaultHeaderPhoto.png';
import { logTrainerBookPressed, logTrainerProfileViewed, TrainerProfileViewContext } from '../../statistics';
import { getTrainerPhoto256x256 } from '../../selectors';
import './styles.scss';

const ProfileScreen = ({
  match,
  loadProfileTrainer,
  loadTrainerMaterials,
  loadTrainerReviews,
  profile,
  similarTrainers,
  trainerMaterials,
  trainerReviews,
  loadSimilarTrainers,
  logTrainerProfileViewed,
  logTrainerBookPressed,
  isLoading,
  hasError,
  trainerMaterialsLoading,
  trainerMaterialsError,
  trainerReviewsLoading,
  trainerReviewsError,
  allLanguages,
}) => {
  const trainerId = match.params?.id;
  const reviewsEnabled = false;

  const loadTrainerInfo = async () => {
    loadProfileTrainer(trainerId);
    loadSimilarTrainers(trainerId);
    loadTrainerMaterials(trainerId);
    if (reviewsEnabled) {
      loadTrainerReviews(trainerId);
    }
  };

  useEffect(() => {
    loadTrainerInfo();
  }, [trainerId, i18n.locale]);

  const handleBookTrainer = () => logTrainerBookPressed(trainerId);

  if ((hasError || trainerMaterialsError || trainerReviewsError) && !isLoading) {
    return (
      <Container fluid>
        <div className="informational-container">
          <Alert variant={'danger'}>{t('TRAINER_PROFILE_SCREEN.ERROR_MESSAGE')}</Alert>
        </div>
      </Container>
    );
  }

  if (isLoading || trainerMaterialsLoading || trainerReviewsLoading || !profile) {
    return (
      <Container fluid>
        <div className="informational-container">
          <Loader />
        </div>
      </Container>
    );
  }

  const competencesCommaSeparated = profile.competences.map(c => c.name).join(', ');
  const competencesTextForTitle = profile.competences.length ? `(${competencesCommaSeparated})` : '';
  const languagesCommaSeparated = allLanguages
    ?.filter(l => profile.languages?.includes(l.id))
    .map(l => l.name)
    .join(', ');
  const metaKeywords = [profile.name, competencesCommaSeparated, languagesCommaSeparated].filter(x => x).join(', ');

  return (
    <div className="trainer-profile">
      <Helmet>
        <title>{`${profile.name} ${competencesTextForTitle} - Ridesum`}</title>
        <meta name="description" content={`${profile.name} - Ridesum - Trainer database ${profile.summary || ''}`} />
        <meta name="keywords" content={metaKeywords} />
        <meta property="og:title" content={profile.name} />
        <meta property="og:description" content={profile.summary} />
        <meta property="og:type" content="profile" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={getTrainerPhoto256x256(profile)} />
        <meta property="og:image:width" content="256" />
        <meta property="og:image:height" content="256" />
        <meta property="og:image:alt" content="Trainer avatar" />
        <meta property="profile:first_name" content={profile.name.split(' ')[0]} />
        <meta property="profile:last_name" content={profile.name.split(' ')[1]} />
      </Helmet>
      <img
        className="trainer-profile__header-image"
        src={profile.headerPhoto || defaultHeaderPhotoImage}
        alt={profile.name}
      />
      <Container>
        <div className="profile-info">
          <div>
            <AboutTrainer trainer={profile} />
            <hr />
            <div>
              <p className="font-size16-weight600">{t('SEARCH_SCREEN.SKILLS')}</p>
              {profile.competences.map(({ id, name }, index) => (
                <b key={id} className="skills-text_premium color10">
                  <i className="fa fa-circle fa-3x fs9mr3" />
                  <span className="font-size14-weight600 ml1mr20">
                    {name}
                    {index < profile.competences?.length - 1 ? ', ' : ''}
                  </span>
                </b>
              ))}
            </div>
            <hr />
            <div>
              <p className="font-size16-weight600">{t('SUM')}</p>
              <pre className="summary">{profile.summary || t('SEARCH_SCREEN.HAVE_NO_WRITTEN')}</pre>
            </div>
            <ProfileMaterials materials={trainerMaterials} />
            {reviewsEnabled && <TrainerReviews reviews={trainerReviews} />}
          </div>
          <div className="sidebar">
            <Prices trainer={profile} onBookTrainer={handleBookTrainer} />
            <Contacts trainer={profile} />
          </div>
        </div>
        {similarTrainers?.length > 0 && (
          <section className="highlighted_trainers">
            <p className="highlighted-trainers-title">{t('SEARCH_SCREEN.HIGHLIGHTED_TRAINERS')}</p>
            <MixedTrainersList
              trainers={similarTrainers}
              onSeeProfile={id => logTrainerProfileViewed(id, TrainerProfileViewContext.similar)}
            />
          </section>
        )}
      </Container>
    </div>
  );
};

const mapStateToProps = (state, { match }) => ({
  profile: getTrainerProfile(state, match.params?.id),
  isLoading: getTrainerProfileLoading(state),
  hasError: getTrainerProfileError(state),
  similarTrainers: getSimilarTrainers(state),
  similarTrainersLoading: getSimilarTrainersLoading(state),
  similarTrainersError: getSimilarTrainersError(state),
  allLanguages: getSearchDataSourceLanguages(state),
  trainerMaterials: getTrainerMaterials(state),
  trainerMaterialsLoading: getTrainerMaterialsLoading(state),
  trainerMaterialsError: getTrainerMaterialsError(state),
  trainerReviews: getTrainerReviews(state),
  trainerReviewsLoading: getTrainerReviewsLoading(state),
  trainerReviewsError: getTrainerReviewsError(state),
});

const mapDispatchToProps = {
  loadProfileTrainer,
  loadSimilarTrainers,
  logTrainerProfileViewed,
  logTrainerBookPressed,
  loadTrainerMaterials,
  loadTrainerReviews,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfileScreen));
