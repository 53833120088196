import React from 'react';
import classNames from 'classnames';
import { thumbsUp } from '../../../../components/assets';
import styles from './styles.module.scss';

const LikesBlock = ({ className, count }) => {
  return (
    <div className={classNames(styles['likes-block'], className)}>
      <span>{count}</span>
      <img src={thumbsUp} alt="Likes" />
    </div>
  );
};

export default LikesBlock;
