import React from 'react';
import classNames from 'classnames';
import { t } from '../../../i18n';
import { CURRENCY_ARRAY } from '../../../constants';
import { checkIcon, uncheckIcon } from '../../../stream/assets';
import { arrowDownIcon } from '../../assets';
import styles from './styles.module.scss';

/**
 * @typedef {{
 *   isEnabled: boolean,
 *   amount: string,
 *   currency: string,
 *   vat: string,
 * }} FeedbackPaymentValues,
 */

/**
 * @type {React.FC<{
 *   values: FeedbackPaymentValues,
 *   onValuesChanged: (newValues: FeedbackPaymentValues) => void,
 *   activityDuration: number,
 * }>}
 */
const FeedbackPayment = ({ values, onValuesChanged, activityDuration }) => {
  const renderCurrencyOption = item => (
    <button
      type="button"
      key={item}
      className={styles.currencyDropdownOptionButton}
      onClick={() => onValuesChanged({ ...values, currency: item })}
    >
      {item}
    </button>
  );

  return (
    <div className={styles.container}>
      <button
        type="button"
        className={styles.enabledCheckbox}
        onClick={() => onValuesChanged({ ...values, isEnabled: !values.isEnabled })}
      >
        {values.isEnabled ? (
          <>
            <img alt="checked payments" className={styles.enabledCheckboxIconChecked} src={checkIcon} />
            {t('SHARE_FEEDBACK_WITH_PAYMENTS')}
          </>
        ) : (
          <>
            <img alt="unchecked payments" className={styles.enabledCheckboxIconUnchecked} src={uncheckIcon} />
            {t('SHARE_FEEDBACK_WITH_PAYMENTS')}
          </>
        )}
      </button>
      <div className={classNames(styles.invoiceConfigSection, !values.isEnabled && styles.disabledSection)}>
        <div className={styles.activityInfo}>
          {t('RIDESUM_TRAINING')} {!!activityDuration && `${activityDuration}min`}
        </div>
        <div className={styles.amountAndCurrency}>
          <input
            type="number"
            className={styles.amountInput}
            value={values.amount}
            onChange={e => onValuesChanged({ ...values, amount: e.target.value })}
            disabled={!values.isEnabled}
          />

          <div className={classNames(styles.currency, !values.isEnabled && styles.disabledCurrency)}>
            <div className={styles.currencyButton}>
              <button type="button" className={styles.currencyButtonInner} disabled={!values.isEnabled}>
                <span className={styles.currencyButtonText}>{values.currency}</span>
                <img alt="Arrow down" className={styles.currencyButtonArrowDown} src={arrowDownIcon} />
              </button>
            </div>
            {values.isEnabled && (
              <div className={styles.currencyDropdown}>{CURRENCY_ARRAY.map(item => renderCurrencyOption(item))}</div>
            )}
          </div>
        </div>
        <div className={styles.vatWrapper}>
          <span className={styles.vatLabel}>{t('INCLUDE_VAT')}</span>
          <input
            type="number"
            className={styles.vatInput}
            value={values.vat}
            onChange={e => onValuesChanged({ ...values, vat: e.target.value })}
            disabled={!values.isEnabled}
            min={0}
            max={99}
          />
          <span className={styles.vatLabel}>%</span>
        </div>
      </div>
    </div>
  );
};

export default FeedbackPayment;
