import { API_REQUEST } from '../../store/sagas/api';
import { getTrainerReview, approveTrainerReview, rejectTrainerReview } from '../../backend-api';

const LOAD_TRAINER_REVIEW = 'LOAD_TRAINER_REVIEW';
const LOAD_TRAINER_REVIEW_SUCCESS = 'LOAD_TRAINER_REVIEW_SUCCESS';
const LOAD_TRAINER_REVIEW_FAIL = 'LOAD_TRAINER_REVIEW_FAIL';

const APPROVE_TRAINER_REVIEW = 'APPROVE_TRAINER_REVIEW';
const APPROVE_TRAINER_REVIEW_SUCCESS = 'APPROVE_TRAINER_REVIEW_SUCCESS';
const APPROVE_TRAINER_REVIEW_FAIL = 'APPROVE_TRAINER_REVIEW_FAIL';

const REJECT_TRAINER_REVIEW = 'REJECT_TRAINER_REVIEW';
const REJECT_TRAINER_REVIEW_SUCCESS = 'REJECT_TRAINER_REVIEW_SUCCESS';
const REJECT_TRAINER_REVIEW_FAIL = 'REJECT_TRAINER_REVIEW_FAIL';

const initialState = {
  data: null,
  error: false,
  loading: false,
};

export default function trainerReviewReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_TRAINER_REVIEW:
      return {
        ...state,
        loading: true,
      };
    case LOAD_TRAINER_REVIEW_SUCCESS:
      return {
        data: action.result,
        error: false,
        loading: false,
      };
    case LOAD_TRAINER_REVIEW_FAIL:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case APPROVE_TRAINER_REVIEW:
      return {
        ...state,
        loading: true,
      };
    case APPROVE_TRAINER_REVIEW_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
      };
    case APPROVE_TRAINER_REVIEW_FAIL:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case REJECT_TRAINER_REVIEW:
      return {
        ...state,
        loading: true,
      };
    case REJECT_TRAINER_REVIEW_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
      };
    case REJECT_TRAINER_REVIEW_FAIL:
      return {
        ...state,
        error: true,
        loading: false,
      };
    default:
      return state;
  }
}

export const loadTrainerReview = reviewId => ({
  type: API_REQUEST,
  types: [LOAD_TRAINER_REVIEW, LOAD_TRAINER_REVIEW_SUCCESS, LOAD_TRAINER_REVIEW_FAIL],
  call: () => getTrainerReview(reviewId),
});

export const approveTrainerRecommendation = reviewId => ({
  type: API_REQUEST,
  types: [APPROVE_TRAINER_REVIEW, APPROVE_TRAINER_REVIEW_SUCCESS, APPROVE_TRAINER_REVIEW_FAIL],
  call: () => approveTrainerReview(reviewId),
});

export const rejectTrainerRecommendation = reviewId => ({
  type: API_REQUEST,
  types: [REJECT_TRAINER_REVIEW, REJECT_TRAINER_REVIEW_SUCCESS, REJECT_TRAINER_REVIEW_FAIL],
  call: () => rejectTrainerReview(reviewId),
});
