import React from 'react';
import { Range } from 'rc-slider';
import classNames from 'classnames';
import { t } from 'i18n-js';
import CollapsePanel from './CollapsePanel';
import ResetFilterButton from './reset-filter-button';
import 'rc-slider/assets/index.css';
import './styles.scss';

const CollapsePriceSelect = ({
  name,
  value,
  dataSource,
  className,
  title,
  subtitle,
  onChange,
  onClearFilter,
  filterHasSelectedItems,
}) => {
  const priceFilterDisabled = {
    currency: t('SEARCH_SCREEN.NO_CURRENCY_FILTER'),
    isRangeSelectionDisabled: true,
    from: 0,
    to: 100,
  };

  const currenciesList = [priceFilterDisabled, ...(dataSource || [])];
  const selectedCurrency = dataSource?.find(el => el.currency === value?.currency) || priceFilterDisabled;

  const handleCurrencyChange = event => {
    onChange({ name, value: { ...dataSource.find(el => el.currency === event.target.value) } });
  };

  const handlePriceRangeChange = data =>
    onChange({ name, value: { currency: value.currency, from: data[0], to: data[1] } });

  const rangeMarks = value ? { [value.from]: value.from, [value.to]: value.to } : {};

  return (
    <CollapsePanel
      name={name}
      className={classNames('collapse-price-select-component', className)}
      title={title}
      subtitle={subtitle}
    >
      <li className="collapse-price-select-component__select_wrapper">
        <select
          className="collapse-price-select-component__select form-select"
          onChange={handleCurrencyChange}
          value={selectedCurrency.currency}
        >
          {currenciesList.map(item => (
            <option
              key={item.currency}
              className="collapse-price-select-component__select-option"
              value={item.currency}
            >
              {item.currency}
            </option>
          ))}
        </select>
      </li>
      <li className="collapse-price-select-component__range-wrapper">
        <Range
          className="collapse-price-select-component__range"
          marks={rangeMarks}
          min={selectedCurrency.from}
          max={selectedCurrency.to}
          value={[value?.from ?? 0, value?.to ?? 100]}
          onChange={handlePriceRangeChange}
          disabled={selectedCurrency?.isRangeSelectionDisabled}
        />
      </li>
      {filterHasSelectedItems && <ResetFilterButton className={'mt-4'} onClick={() => onClearFilter(name)} />}
    </CollapsePanel>
  );
};
export default CollapsePriceSelect;
