import { takeEvery, put } from 'redux-saga/effects';

export const API_REQUEST = 'API_REQUEST';

function* api({ types, call, deferred, ...rest }) {
  let REQUEST;
  let SUCCESS;
  let FAILURE;

  if (types) {
    if (types.length === 3) {
      [REQUEST, SUCCESS, FAILURE] = types;
    } else if (types.length === 2) {
      [REQUEST, SUCCESS] = types;
    } else {
      [SUCCESS] = types;
    }
  }

  if (REQUEST) {
    yield put({ ...rest, type: REQUEST });
  }

  try {
    const result = yield call();

    if (SUCCESS) {
      yield put({ ...rest, type: SUCCESS, result });
    }
    if (deferred && typeof deferred.resolve === 'function') {
      deferred.resolve(result);
    }
  } catch (error) {
    if (FAILURE) {
      yield put({ ...rest, type: FAILURE, error });
    }
    if (deferred && typeof deferred.reject === 'function') {
      deferred.reject(error);
    }
  }
}

export default function* saga() {
  yield takeEvery(API_REQUEST, api);
}
