import React from 'react';
import clamp from 'lodash/clamp';
import './styles.scss';

const ProgressBar = ({ progress, className, ...otherProps }) => (
  <div className={`progress-bar-background ${className}`} {...otherProps}>
    <div className="progress-bar-front" style={{ width: `${clamp(progress, 0, 1) * 100}%` }}>
      <div className="progress-bar-color" />
    </div>
  </div>
);
export default ProgressBar;
