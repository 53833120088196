/* eslint-disable react/no-array-index-key */
import React from 'react';
import HighlightedTrainerCard from '../trainer-card/HighlightedTrainerCard';
import StandardTrainerCard from '../trainer-card/StandardTrainerCard';
import styles from './styles.module.scss';

const MixedTrainersList = ({ trainers, showPlaceholders, onSeeProfile }) => {
  const highlightedTrainers = trainers && trainers.filter(t => t.isHighlighted);
  const standardTrainers = trainers && trainers.filter(t => !t.isHighlighted);

  return (
    <div className={styles.container}>
      {highlightedTrainers?.length > 0 && (
        <div className={styles.grid}>
          {highlightedTrainers.map(trainer => (
            <HighlightedTrainerCard key={trainer.id} trainer={trainer} onSeeProfile={() => onSeeProfile(trainer.id)} />
          ))}
        </div>
      )}
      {(standardTrainers?.length > 0 || showPlaceholders) && (
        <div className={styles.grid}>
          {standardTrainers?.length > 0 &&
            standardTrainers.map(trainer => (
              <StandardTrainerCard key={trainer.id} trainer={trainer} onSeeProfile={() => onSeeProfile(trainer.id)} />
            ))}
          {showPlaceholders && [...Array(12)].map((_, i) => <StandardTrainerCard.Placeholder key={i} />)}
        </div>
      )}
    </div>
  );
};

export default MixedTrainersList;
