import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import styles from './styles.module.scss';

const CompetencesList = ({ competences, showBullets }) => {
  const maxNumberOfSkills = 2;
  const visibleCompetencesText = competences
    ?.slice(0, maxNumberOfSkills)
    ?.map(c => (showBullets ? `● ${c.name}` : c.name))
    ?.join(', ');

  const hiddenCompetencesCount = competences ? Math.max(0, competences.length - maxNumberOfSkills) : 0;
  const hiddenCompetencesText =
    hiddenCompetencesCount > 0
      ? competences
        .slice(maxNumberOfSkills)
        .map(c => c.name)
        .join(', ')
      : 0;

  return (
    <div className={styles.container}>
      <div className={styles.text} title={visibleCompetencesText}>
        {visibleCompetencesText}
      </div>
      {hiddenCompetencesCount > 0 && (
        <OverlayTrigger placement="bottom" delay={{ hide: 300 }} overlay={<Tooltip>{hiddenCompetencesText}</Tooltip>}>
          <div className={styles['more-competences']}>+{hiddenCompetencesCount}</div>
        </OverlayTrigger>
      )}
    </div>
  );
};

export default CompetencesList;
