import React, { useCallback, useContext, useState } from 'react';

const PromiseDialogContext = React.createContext();

let promiseDialogFunction = () => Promise.resolve();

export const PromiseDialogProvider = ({ children }) => {
  const [dialogsQueue, setDialogsQueue] = useState([]);

  promiseDialogFunction = useCallback(
    (DialogComponent, dialogProps = {}) =>
      new Promise((resolve, reject) => {
        setDialogsQueue(oldState => [
          ...oldState,
          {
            component: DialogComponent,
            dialogProps,
            resolve,
            reject,
          },
        ]);
      }),
    [],
  );

  const currentDialog = dialogsQueue[0];

  const handleResolve = useCallback(
    value => {
      currentDialog.resolve(value);
      setDialogsQueue(oldQueue => oldQueue.slice(1));
    },
    [currentDialog],
  );
  const handleReject = useCallback(
    reason => {
      currentDialog.reject(reason);
      setDialogsQueue(oldQueue => oldQueue.slice(1));
    },
    [currentDialog],
  );

  const CurrentDialogComponent = currentDialog?.component;
  return (
    <PromiseDialogContext.Provider value={promiseDialogFunction}>
      {children}
      {!!CurrentDialogComponent && (
        <CurrentDialogComponent onResolve={handleResolve} onReject={handleReject} {...currentDialog.dialogProps} />
      )}
    </PromiseDialogContext.Provider>
  );
};

export function usePromiseDialog() {
  return useContext(PromiseDialogContext);
}
/**
 * Renders custom dialog React component passing 'onResolve'/'onReject' props to it
 * and resolves/rejects returned promise when the component calls either of these functions.
 * @template TProp
 * @template TResolve
 * @param {React.ComponentType<TProp | { onResolve: (value: TResolve) => void }>} dialogComponent - Dialog React component
 * @param {TProp} dialogProps - Props to pass to the dialog component
 * @returns {Promise<TResolve>}
 */
const promiseDialog = (dialogComponent, dialogProps = {}) => promiseDialogFunction(dialogComponent, dialogProps);
export default promiseDialog;
