import React from 'react';

import './styles.scss';

const Note = ({ loading, label, text }) =>
  !loading ? (
    <div className="note-label">
      {label}:<span className="note-text"> {text}</span>
    </div>
  ) : null;
export default Note;
