/* eslint-disable indent */
import React, { useEffect, useRef } from 'react';
import debounce from 'lodash/debounce';
import HighlightedTrainerCard from '../trainer-card/HighlightedTrainerCard';
import { Button } from '../../../components';
import { t } from '../../../i18n';
import './styles.scss';

const TrainersCarousel = ({
  trainers,
  renderItem,
  onLoadMore,
  hasMore,
  loading,
  placeholdersCount = 12,
  PlaceholderComponent = HighlightedTrainerCard.Placeholder,
}) => {
  const itemsContainerRef = useRef();
  const lastItemRef = useRef();

  useEffect(() => {
    if (loading || !hasMore || !onLoadMore) {
      return () => {};
    }

    let isEffectFinished = false;
    const loadMoreDebounced = debounce(() => {
      if (!isEffectFinished) {
        onLoadMore();
      }
    }, 200);
    const handleCarouselScoll = e => {
      const scrollRight = e.target.scrollWidth - e.target.scrollLeft;
      const carouselWidth = e.target.clientWidth;
      const lastItemWidth = lastItemRef.current?.clientWidth;
      const isLastItemSeen = scrollRight <= carouselWidth + lastItemWidth;
      if (isLastItemSeen && loadMoreDebounced) {
        loadMoreDebounced();
      }
    };

    itemsContainerRef.current.addEventListener('scroll', handleCarouselScoll);
    return () => {
      isEffectFinished = true;
      itemsContainerRef.current?.removeEventListener('scroll', handleCarouselScoll);
    };
  }, [hasMore, loading, onLoadMore]);

  const itemsToRender = !loading
    ? trainers
    : [
        ...(trainers || []),
        ...[...Array(placeholdersCount)].map((_, i) => ({
          id: `placeholder${i}`,
          isPlaceholder: true,
        })),
      ];

  return (
    <div>
      <div className="trainers-carousel__items-container" ref={itemsContainerRef}>
        {itemsToRender?.map((trainer, index) => (
          <div
            key={trainer.id}
            className="trainers-carousel__item"
            ref={itemRef => {
              if (index === (trainers?.length ?? 0) - 1) {
                lastItemRef.current = itemRef;
              }
            }}
          >
            {!trainer.isPlaceholder ? renderItem(trainer) : <PlaceholderComponent />}
          </div>
        ))}
      </div>

      <div className="trainers-carousel__see-more-container">
        {hasMore ? (
          <Button text={t('SEARCH_SCREEN.SHOW_MORE')} type="white" disabled={loading} onClick={() => onLoadMore?.()} />
        ) : null}
      </div>
    </div>
  );
};

export default TrainersCarousel;
