import React from 'react';
import classNames from 'classnames';
import * as yup from 'yup';
import { withFormik } from 'formik';
import UrlTo from '../../url-to';
import { t } from '../../i18n';
import { appStoreImg, googlePlayImg, checkoutServicesImg } from './assets';
import './styles.scss';

const EMAIL_FIELD = 'EMAIL';
const LANGUAGE_FIELD = 'MMERGE2';
const ROLE_FIELD = 'group[4243]';

const validationSchema = yup.object().shape({
  [EMAIL_FIELD]: yup
    .string()
    .trim()
    .email(t('COMMON_FOOTER.SUBSCRIPTION_ERRORS.INVALID_EMAIL'))
    .required(t('COMMON_FOOTER.SUBSCRIPTION_ERRORS.REQUIRED_FIELD')),
  [LANGUAGE_FIELD]: yup
    .string()
    .trim()
    .required(t('COMMON_FOOTER.SUBSCRIPTION_ERRORS.REQUIRED_FIELD')),
});

const Footer = ({ values, errors, setFieldValue, handleSubmit }) => {
  return (
    <div className="footer">
      <div className="footer__flex-wrapper">
        <div className="footer__info-block">
          <h2>{t('COMMON_FOOTER.QUICK_LINKS')}</h2>
          <ul className="footer__web-navigation-wrapper">
            <li>
              <a href={UrlTo.offSiteTrainers()}>{t('COMMON_FOOTER.TRAINER')}</a>
            </li>
            <li>
              <a href={UrlTo.offSiteRiders()}>{t('COMMON_FOOTER.RIDER')}</a>
            </li>
            <li>
              <a href={UrlTo.coursesHome()}>{t('COMMON_FOOTER.DIGITAL_COURSES')}</a>
            </li>
            <li>
              <a href={UrlTo.trainersOverview()}>{t('COMMON_FOOTER.SEARCH_TRAINER')}</a>
            </li>
            <li>
              <a href={UrlTo.offSiteNews()}>{t('COMMON_FOOTER.NEWS')}</a>
            </li>
            <li>
              <a href={UrlTo.offSiteCustomerSupport()}>{t('COMMON_FOOTER.INSTRUCTIONAL_VIDEOS')}</a>
            </li>
            <li>
              <a href={UrlTo.offSiteContact()}>{t('COMMON_FOOTER.CONTACT')}</a>
            </li>
            <li>
              <a href={UrlTo.offSiteCustomerSupport()}>{t('COMMON_FOOTER.SUPPORT_WITH_RIDESUM')}</a>
            </li>
          </ul>
          <div className="footer__bottom-icons-wrapper">
            <div className="footer__app-links-wrapper">
              <a href={UrlTo.appStoreRidesumApplication()}>
                <img src={appStoreImg} className="footer__app-link-image" alt="app-store-logo" />
              </a>
              <a href={UrlTo.googlePlayRidesumApplication()}>
                <img src={googlePlayImg} className="footer__app-link-image" alt="play-market-logo" />
              </a>
            </div>
            <img src={checkoutServicesImg} className="footer__checkout-services-img" alt="checkout-services" />
          </div>
        </div>
        <div className="footer__info-block">
          <h2>{t('COMMON_FOOTER.ABOUT_RIDESUM')}</h2>
          <div className="footer__about-ridesum-wrapper">
            <ul className="footer__web-navigation-wrapper">
              <li>
                <a href={UrlTo.offSiteWorkWithUs()}>{t('COMMON_FOOTER.JOB')}</a>
              </li>
              <li>
                <a href={UrlTo.offSiteTeam()}>{t('COMMON_FOOTER.TEAM')}</a>
              </li>
              <li>
                <a href={UrlTo.offSiteStory()}>{t('COMMON_FOOTER.STORY')}</a>
              </li>
              <li>
                <a href={UrlTo.offSitePrivacyPolicy()}>{t('COMMON_FOOTER.PRIVACY_POLICY')}</a>
              </li>
              <li>
                <a href={UrlTo.offSiteTermsOfUse()}>{t('COMMON_FOOTER.TERMS_OF_USE')}</a>
              </li>
            </ul>
            <div className="footer__copyright">{t('COMMON_FOOTER.COPYRIGHT')}</div>
          </div>
        </div>
        <div className="footer__info-block">
          <form
            id="footer__mailchimp-form"
            className="footer__subscription-form"
            action="https://ridesum.us18.list-manage.com/subscribe/post?u=121105d66a6ababb06230831b&id=8da570c009"
            method="post"
            target="_blank"
            onSubmit={handleSubmit}
          >
            <h2 className="footer__subscription-title">{t('COMMON_FOOTER.SUBSCRIBE_LABEL')}</h2>
            <div className="footer__required-indication-wrapper">
              <span className="asterisk">*</span> {t('COMMON_FOOTER.INDICATES_REQUIRED_LABEL')}
            </div>
            <div className="footer__subscription-input-wrapper">
              <label htmlFor={EMAIL_FIELD}>
                {t('COMMON_FOOTER.EMAIL_LABEL')} <span className="asterisk">*</span>
              </label>
              <input
                name={EMAIL_FIELD}
                className={classNames(errors && errors[EMAIL_FIELD] && 'input-with-error')}
                value={values[EMAIL_FIELD]}
                onChange={event => setFieldValue(EMAIL_FIELD, event.target.value)}
                onBlur={() => setFieldValue(EMAIL_FIELD, values[EMAIL_FIELD].trim())}
              />
              {errors && errors[EMAIL_FIELD] && <div className="error">{errors[EMAIL_FIELD]}</div>}
            </div>
            <div className="footer__subscription-input-wrapper">
              <label htmlFor={`mce-${LANGUAGE_FIELD}`}>
                {t('COMMON_FOOTER.LANGUAGE_LABEL')} <span className="asterisk">*</span>
              </label>
              <select
                id={`mce-${LANGUAGE_FIELD}`}
                name={LANGUAGE_FIELD}
                className={classNames(errors && errors[LANGUAGE_FIELD] && 'input-with-error')}
                value={values[LANGUAGE_FIELD]}
                onChange={event => setFieldValue(LANGUAGE_FIELD, event.target.value)}
                onBlur={() => setFieldValue(LANGUAGE_FIELD, values[LANGUAGE_FIELD])}
              >
                {['', 'English', 'Swedish'].map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </select>
              {errors && errors[LANGUAGE_FIELD] && <div className="error">{errors[LANGUAGE_FIELD]}</div>}
            </div>
            <div className="footer__subscription-input-wrapper">
              <label htmlFor={`mce-${ROLE_FIELD}`}>{t('COMMON_FOOTER.RIDER_OR_TRAINER_LABEL')}</label>
              <select id={`mce-${ROLE_FIELD}`} name={ROLE_FIELD}>
                {['', 'Rider', 'Trainer/Coach'].map((item, index) => (
                  <option key={index} value={index++}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
            <div id="footer__captcha" className="footer__captcha">
              {t('COMMON_FOOTER.CAPTCHA_LABEL')}
            </div>
            <button type="submit" className="footer__subscribe-button">
              {t('COMMON_FOOTER.SUBSCRIBE_LABEL')}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default withFormik({
  mapPropsToValues: () => ({
    [EMAIL_FIELD]: '',
    [LANGUAGE_FIELD]: '',
    [ROLE_FIELD]: '',
  }),
  validationSchema,
  validateOnChange: false,
  validateOnBlur: false,
  handleSubmit: async () => {
    const form = document.getElementById('footer__mailchimp-form');
    form.submit();
    document.getElementById('footer__captcha').style.display = 'block';
  },
})(Footer);
