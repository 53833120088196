import { API_REQUEST } from '../store/sagas/api';
import { getActivity, getOpenTokToken, ratingSession, logStreamingQualityMetrics } from '../backend-api';
import { closeFullscreen, openFullscreen } from '../utilities/fullscreen';

export const FULL_SCREEN = 'FULL_SCREEN';
export const LOAD_ACTIVITY = 'LOAD_ACTIVITY';
export const GET_TOKEN = 'GET_TOKEN';
export const CONFIRM_EXIT = 'CONFIRM_EXIT';
export const MUTE = 'MUTE';
export const FULL_SCREEN_NOTES = 'FULL_SCREEN_NOTES';
export const USER_CONNECTED = 'USER_CONNECTED';
export const USER_DISCONNECTED = 'USER_DISCONNECTED';
export const FINISH = 'FINISH';
export const PUBLISH_VIDEO = 'PUBLISH_VIDEO';
export const ZOOM = 'ZOOM';
export const SCALE = 'SCALE';
export const PAYMENT_CHANGED = 'PAYMENT_CHANGED';

const SUBMIT_SESSION_RATING = 'SUBMIT_SESSION_RATING';
const LOG_STREAMING_QUALITY_METRICS = 'LOG_STREAMING_QUALITY_METRICS';

const initialState = {
  fullScreen: false,
  muted: false,
  showExitConfirmation: false,
  token: null,
  publishAudio: true,
  fullScreenNotes: false,
  rider: null,
  publishVideo: true,
  zoom: false,
  scale: 100,
};

const streamReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_ACTIVITY:
      return {
        ...initialState,
      };
    case GET_TOKEN:
      return { ...state, token: action.result.token };
    case CONFIRM_EXIT:
      return { ...state, showExitConfirmation: action.value };
    case MUTE:
      return { ...state, publishAudio: action.value };
    case FULL_SCREEN:
      return { ...state, fullScreen: action.value };
    case FULL_SCREEN_NOTES:
      return { ...state, fullScreenNotes: action.value };
    case USER_CONNECTED: {
      const user = action.value;
      return { ...state, rider: user.type === 'Rider' ? user : state.rider };
    }
    case USER_DISCONNECTED: {
      const isRider = action.value.id === state.rider.id;
      return { ...state, rider: isRider ? null : state.rider };
    }
    case PUBLISH_VIDEO:
      return { ...state, publishVideo: action.value };
    case ZOOM:
      return { ...state, zoom: action.value };
    case SCALE:
      return { ...state, scale: action.value };
    default:
      return state;
  }
};
export default streamReducer;

export const loadActivity = activityId => ({
  type: API_REQUEST,
  types: [LOAD_ACTIVITY],
  call: () => getActivity('training', activityId),
});

export const getToken = sessionId => ({
  type: API_REQUEST,
  types: [GET_TOKEN],
  call: () => getOpenTokToken(sessionId),
});

export const confirmExit = value => ({ type: CONFIRM_EXIT, value });

export const mute = value => ({ type: MUTE, value });

export const changeFullScreen = value => {
  if (value) {
    const element = document.getElementById('video');
    openFullscreen(element);
  } else {
    closeFullscreen();
  }
  return setFullScreenState(value);
};

export const setFullScreenState = value => ({ type: FULL_SCREEN, value });

export const toggleFullScreenNotes = value => ({ type: FULL_SCREEN_NOTES, value });

export const userConnected = value => ({ type: USER_CONNECTED, value });

export const userDisconnected = value => ({ type: USER_DISCONNECTED, value });

export const toggleVideo = value => {
  return { type: PUBLISH_VIDEO, value };
};

export const toggleZoom = value => ({ type: ZOOM, value });

const SCALE_STEP = 25;
const SCALE_MAX = 300;

export const scaleSet = value => dispatch => {
  const subscribers = document.getElementsByClassName('OT_subscriber');
  if (subscribers.length === 1) {
    const subscriberStyle = subscribers[0].style;
    subscriberStyle.width = `${value}%`;
    subscriberStyle.height = `${value}%`;
    subscriberStyle.left = `${(100 - value) / 2}%`;
    subscriberStyle.top = `${(100 - value) / 2}%`;
  }
  dispatch({ type: SCALE, value });
};

export const scalePlus = () => (dispatch, getState) => {
  const { scale } = getState().stream;
  const newScale = scale < SCALE_MAX ? scale + SCALE_STEP : scale;
  dispatch(scaleSet(newScale));
};

export const scaleMinus = () => (dispatch, getState) => {
  const { scale } = getState().stream;
  const newScale = scale > 100 ? scale - SCALE_STEP : scale;
  dispatch(scaleSet(newScale));
};

export const submitSessionRating = rating => ({
  type: API_REQUEST,
  types: [SUBMIT_SESSION_RATING],
  call: () => ratingSession(rating),
});

export const logStreamingQuality = metrics => ({
  type: API_REQUEST,
  types: [LOG_STREAMING_QUALITY_METRICS],
  call: () => logStreamingQualityMetrics(metrics),
});
