import React, { useState } from 'react';
import classNames from 'classnames';
import { t } from '../../i18n';
import Modal from '../modal';
import Button from '../button';
import Textarea from '../textarea';
import AudioFeedbackBanner from './audio-feedback-banner';
import FeedbackPayment from './feedback-payment';
import { useCurrentUserService } from '../../login/services/CurrentUserService';
import ActivitiesConstants from '../../stream/ActivitiesConstants';
import styles from './styles.module.scss';

/**
 * @typedef {{
 *   feedbackText: string,
 *   invoiceText: string,
 *   invoice: import('./feedback-payment').FeedbackPaymentValues?,
 * }} WriteFeedbackValues
 */

/**
 * @typedef {{
 *   type: 'CANCEL',
 * } | {
 *   type: 'SAVE_DRAFT',
 *   data: WriteFeedbackValues,
 * } | {
 *   type: 'SUBMIT',
 *   data: WriteFeedbackValues,
 * }} WriteFeedbackResult
 */

/**
 * @type {React.FC<{
 *   initialValues: WriteFeedbackValues,
 *   onAutoSaveValues(values: WriteFeedbackValues): void,
 *   canObtainAudioFeedback: boolean,
 *   activityDuration: number,
 *   onResolve(result: WriteFeedbackResult): void,
 * }>}
 */
const WriteFeedbackModal = ({
  initialValues,
  onAutoSaveValues,
  canObtainAudioFeedback,
  activityDuration,
  onResolve,
}) => {
  const [values, setValues] = useState(initialValues);
  const user = useCurrentUserService();
  const isInsurance = user.data?.id === ActivitiesConstants.INSURANCE_TRAINER_ID;

  /**
   * @param {WriteFeedbackValues} newValues
   */
  const handleValuesChanged = newValues => {
    setValues(newValues);
    onAutoSaveValues(newValues);
  };

  return (
    <Modal
      title={t('YOUR_FEEDBACK')}
      message={!isInsurance ? `${t('WRITE_FEEDBACK_TO_RIDER_HERE')} ${t('YOU_CAN_SAVE_AND_SEND_LATER')}` : ''}
      onClose={() => onResolve({ type: 'CANCEL' })}
      buttons={
        <React.Fragment>
          <Button
            text={
              <span>
                <i className="fa fa-save" /> {t('SAVE')}
              </span>
            }
            onClick={() => onResolve({ type: 'SAVE_DRAFT', data: values })}
            className={styles.draftButton}
          />
          <Button
            text={
              <span>
                <i className="fas fa-check" /> {t('SEND')}
              </span>
            }
            onClick={() => onResolve({ type: 'SUBMIT', data: values })}
            className={styles.sendButton}
          />
        </React.Fragment>
      }
    >
      {canObtainAudioFeedback && <AudioFeedbackBanner />}
      <div className={styles.labelText}>
        {t(!isInsurance ? 'FEEDBACK_ON_VIDEO' : 'TRAINER_STREAM.MESSAGE_TO_CUSTOMER')}
      </div>
      <Textarea
        className={styles.multilineTextInput}
        value={values.feedbackText}
        placeholder={!isInsurance ? t('FEEDBACK_TEMPLATE') : ''}
        onChange={e => handleValuesChanged({ ...values, feedbackText: e.target.value })}
      />
      {!!values.invoice && (
        <FeedbackPayment
          values={values.invoice}
          onValuesChanged={invoice => handleValuesChanged({ ...values, invoice })}
          activityDuration={activityDuration}
        />
      )}
      {!!values.invoice && (
        <div className={classNames(styles.invoiceMessage, !values.invoice.isEnabled && styles.invoiceMessageDisabled)}>
          <div className={styles.labelText}>{t('INVOICE_MESSAGE')}:</div>
          <Textarea
            className={styles.multilineTextInput}
            value={values.invoiceText}
            onChange={e => handleValuesChanged({ ...values, invoiceText: e.target.value })}
            placeholder={t('INVOICE_MESSAGE_PLACEHOLDER')}
            maxLength="255"
            disabled={!values.invoice.isEnabled}
          />
        </div>
      )}
    </Modal>
  );
};
export default WriteFeedbackModal;
