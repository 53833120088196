import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import login from '../login/actions';
import stream from '../stream/actions';
import calendar from '../calendar/actions';
import admin from '../admin/actions';
import trainer from '../trainer/actions';
import coursesHome from '../digital-courses/screens/home/redux/reducer';
import coursesSpecificCourse from '../digital-courses/screens/specific-course/redux/reducer';
import coursesCheckout from '../digital-courses/screens/checkout/redux/reducer';
import coursesGiftCardCheckout from '../digital-courses/screens/gift-card-checkout/redux/reducer';
import watchLesson from '../digital-courses/screens/watch-lesson/redux/reducer';
import coursesCurrentUser from '../digital-courses/current-user/reducer';
import coursesProfile from '../digital-courses/screens/profile/redux/reducer';
import trainerReview from '../components/trainer-review-details/actions';

export default combineReducers({
  login,
  routing: routerReducer,
  stream,
  calendar,
  admin,
  trainer,
  coursesCurrentUser,
  coursesHome,
  coursesSpecificCourse,
  coursesCheckout,
  coursesGiftCardCheckout,
  watchLesson,
  coursesProfile,
  trainerReview,
});
