import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { t } from '../../i18n';
import { logout } from '../../login/actions';
import Delete from './assets/delete.png';
import { isSafari, isChrome } from '../../utilities';
import RidesumLogo from '../ridesum-logo';
import UserAvatar from '../../digital-courses/components/user-avatar';
import classNames from 'classnames';
import { useCurrentUserService } from '../../login/services/CurrentUserService';
import './styles.scss';

const Header = ({ active, title }) => {
  const [showLogout, setShowLogout] = useState(false);
  const [showBrowserWarning, setShowBrowserWarning] = useState(!isSafari && !isChrome);
  const dispatch = useDispatch();
  const currentUser = useCurrentUserService();

  const closeBrowserWarning = () => setShowBrowserWarning(false);

  const browserWarning = showBrowserWarning && (
    <div className="browser-warning">
      <div className="browser-warning__text">{t('WARNING_MESSAGE')}</div>
      <div
        className="browser-warning__close"
        onKeyPress={() => closeBrowserWarning()}
        onClick={() => closeBrowserWarning()}
        role="button"
        tabIndex={0}
      >
        <img src={Delete} alt="close" className="browser-warning__close__image" />
      </div>
    </div>
  );

  if (!currentUser.data) {
    return (
      <>
        {browserWarning}
        <div className="root-header" />
      </>
    );
  }

  return (
    <>
      {browserWarning}
      <div className="root-header">
        <div className="root-header__logoWrap">
          <Link to="/">
            <RidesumLogo titleClassName="root-header__logoTitle" />
          </Link>
        </div>
        <div className="root-header__links">
          <Link to="/" className={classNames('root-header__link', active && 'root-header__link--active')}>
            {title || t('UPCOMING')}
          </Link>
        </div>
        <div className="root-header__userWrap" onClick={() => setShowLogout(old => !old)} role="button">
          <UserAvatar
            photoUrl={currentUser.data.photoAltSizes.photo72x72}
            userName={currentUser.data.name}
            className="root-header__photo"
          />
          <div className="root-header__profile">
            <span className="root-header__name">{currentUser.data.name}</span>
            <span className="root-header__email">{currentUser.data.email}</span>
          </div>
          <OverlayTrigger
            show={showLogout}
            placement="bottom"
            rootCloseEvent={() => setShowLogout(false)}
            overlay={
              <Popover className="root-header__logoutPopover">
                <Link
                  to="#"
                  className="root-header__logoutPopoverLink"
                  onClick={() => {
                    dispatch(logout());
                    setShowLogout(false);
                  }}
                >
                  <i className="fas fa-sign-out-alt" />{' '}
                  <span className="root-header__logoutPopoverText">{t('LOGOUT')}</span>
                </Link>
              </Popover>
            }
          >
            <i className="fas fa-chevron-down root-header__logoutArrow" />
          </OverlayTrigger>
        </div>
      </div>
    </>
  );
};

export default Header;
