import React from 'react';
import { ridesumLogo, ridesumIconRounded } from '../assets';
import styles from './styles.module.scss';
import classNames from 'classnames';

const RidesumLogo = ({ iconClassName, titleClassName }) => {
  return (
    <div className={styles.logo}>
      <img src={ridesumIconRounded} className={classNames(styles['app-icon'], iconClassName)} alt="Ridesum" />
      <img src={ridesumLogo} className={classNames(styles.title, titleClassName)} alt="Ridesum" />
    </div>
  );
};

export default RidesumLogo;
