export const isFullscreenSupported = () =>
  document.exitFullscreen || document.mozCancelFullScreen || document.webkitExitFullscreen || document.msExitFullscreen;

export const openFullscreen = domElement => {
  if (domElement.requestFullscreen) {
    domElement.requestFullscreen();
  } else if (domElement.mozRequestFullScreen) {
    /* Firefox */
    domElement.mozRequestFullScreen();
  } else if (domElement.webkitRequestFullscreen) {
    /* Chrome, Safari and Opera */
    domElement.webkitRequestFullscreen();
  } else if (domElement.msRequestFullscreen) {
    /* IE/Edge */
    domElement.msRequestFullscreen();
  }
};

export const closeFullscreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.mozCancelFullScreen) {
    /* Firefox */
    document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) {
    /* Chrome, Safari and Opera */
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) {
    /* IE/Edge */
    document.msExitFullscreen();
  }
};

export const getFullscreenElement = () => {
  return document.fullscreenElement || document.webkitFullscreenElement;
};

export const addFullscreenChangeListener = handler => {
  document.addEventListener('fullscreenchange', handler);
  document.addEventListener('webkitfullscreenchange', handler);
  return () => {
    document.removeEventListener('fullscreenchange', handler);
    document.removeEventListener('webkitfullscreenchange', handler);
  };
};
