import React, { useState } from 'react';
import MultiSelectList from '../multi-select-list';
import filterIcon from '../../../assets/filter.svg';
import { t } from '../../../../i18n';
import './styles.scss';

const FilteringMultiSelectList = ({ data, onToggleItem }) => {
  const [nameFilter, setNameFilter] = useState('');

  const nameFilterLower = nameFilter.trim().toLowerCase();
  const filteredData = !nameFilter ? data : data.filter(item => item.name.toLowerCase().startsWith(nameFilterLower));

  return (
    <div className="filtering-multiselect-list">
      <input
        type="text"
        value={nameFilter}
        onChange={e => setNameFilter(e.target.value)}
        placeholder={t('SEARCH_SCREEN.FILTER')}
      />
      <img src={filterIcon} alt={t('SEARCH_SCREEN.FILTER')} />
      <hr />
      <div className="filtering-multiselect-list__scrollable-list">
        <MultiSelectList data={filteredData} onToggleItem={onToggleItem} />
      </div>
      <hr />
    </div>
  );
};

export default FilteringMultiSelectList;
