import React from 'react';
import classNames from 'classnames';
import './styles.scss';

const getNameAbbreviation = name => {
  const firstCharsOfTwoWords = name.match(/(\w).*?\b(\w)/);
  if (firstCharsOfTwoWords) {
    return firstCharsOfTwoWords[1] + firstCharsOfTwoWords[2];
  }
  const anyTwoChars = name.match(/(\w).*?(\w)/);
  if (anyTwoChars) {
    return anyTwoChars[1] + anyTwoChars[2];
  }
  return ':)'; // I hope there are no users who have 1 or 0 word characters in their name
};

const UserAvatar = ({ photoUrl, userName, className }) => {
  if (photoUrl) {
    return <img src={photoUrl} alt={userName} className={classNames('profile-avatar__image', className)} />;
  }
  const nameAbbreviation = getNameAbbreviation(userName);
  return (
    <div className={classNames('profile-avatar__name-abbreviation', className)}>
      <span>{nameAbbreviation}</span>
    </div>
  );
};

export default UserAvatar;
