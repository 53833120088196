import React from 'react';
import { loaderGreen } from '../assets';
import './styles.scss';

const Loader = () => (
  <div className="loader__container">
    <img className="loader__image" src={loaderGreen} alt="Loading" />
  </div>
);

export default Loader;
