import React from 'react';
import Container from 'react-bootstrap/Container';
import { useDispatch } from 'react-redux';
import { t } from '../../../i18n';
import BottomScrollObserver from '../../components/bottom-scroll-observer';
import MixedTrainersList from '../../components/mixed-trainers-list';
import { logTrainerProfileViewed, TrainerProfileViewContext } from '../../statistics';
import NoTrainersFound from './no-trainers-found';
import styles from './styles.module.scss';

const SearchResultScreen = ({ trainers, onShowMore, hasMore, loading }) => {
  const dispatch = useDispatch();
  const handleSeeProfile = id => dispatch(logTrainerProfileViewed(id, TrainerProfileViewContext.search));

  const isLoadingMoreAllowed = hasMore && !loading;

  const isNoResultsFound = !loading && trainers != null && trainers.length === 0;
  if (isNoResultsFound) {
    return <NoTrainersFound />;
  }

  return (
    <Container className={styles.container}>
      <h1 className="searchLabel">{t('SEARCH_RESULT_SCREEN.TRAINERS')}</h1>
      <BottomScrollObserver onEndReached={isLoadingMoreAllowed && onShowMore}>
        <MixedTrainersList trainers={trainers} showPlaceholders={loading} onSeeProfile={handleSeeProfile} />
      </BottomScrollObserver>
    </Container>
  );
};

export default React.memo(SearchResultScreen);
