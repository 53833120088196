import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Button from '../button';
import styles from './styles.module.scss';

const ButtonLink = ({ text, href, onClick = null, type, style, className, linkClassName, disabled }) => {
  const isExternalRoute = href?.startsWith('http');
  if (isExternalRoute) {
    return (
      <a href={href} className={classNames(styles.link, linkClassName)} onClick={onClick}>
        <Button text={text} type={type} style={style} className={className} disabled={disabled} />
      </a>
    );
  }
  return (
    <Link to={href} className={classNames(styles.link, linkClassName)} onClick={onClick}>
      <Button text={text} type={type} style={style} className={className} disabled={disabled} />
    </Link>
  );
};

export default ButtonLink;
