import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';
import { t } from '../../../i18n';
import UrlTo from '../../../url-to';
import { Loader } from '../../../components';
import CategoryCard from '../../components/category-card';
import CardVideo from '../../components/cardVideo/CardVideo';
import VideoCarousel from '../../components/video-carousel';
import Subtitle from '../../components/subtitle';
import { loadCategoryInfo } from '../../actions';
import CategoryHeader from './category-header';
import MixedTrainersList from '../../components/mixed-trainers-list';
import { logInterestViewed, logTrainerProfileViewed, TrainerProfileViewContext } from '../../statistics';
import useTrainersOverview from '../../useTrainersOverview';
import { Helmet } from 'react-helmet';
import './styles.scss';

const CategoryScreen = ({
  match,
  loadCategoryInfo,
  category,
  interests,
  logTrainerProfileViewed,
  logInterestViewed,
  isLoading,
  hasError,
}) => {
  const interestId = match.params?.id;

  useEffect(() => {
    loadCategoryInfo(interestId);
    logInterestViewed(interestId);
  }, [interestId]);

  useTrainersOverview(); // loads all categories

  if (hasError && !isLoading) {
    return (
      <Container fluid>
        <div className="informational-container">
          <Alert variant={'danger'}>{t('CATEGORY_SCREEN.ERROR_MESSAGE')}</Alert>
        </div>
      </Container>
    );
  }
  if (isLoading || !category) {
    return (
      <Container fluid>
        <div className="informational-container">
          <Loader />
        </div>
      </Container>
    );
  }
  return (
    <Container className="trainer-catergories">
      <Helmet>
        <title>{`${category.discipline.displayName} - Ridesum`}</title>
        <meta name="description" content={category.discipline.longDescription} />
      </Helmet>
      <div>
        <CategoryHeader category={category.discipline} />
        <div style={{ marginTop: 20 }}>{category.discipline.longDescription}</div>
        {!!category.featuredTrainers?.length && (
          <>
            <Subtitle>{t('TRAINERS')}</Subtitle>
            <MixedTrainersList
              trainers={category.featuredTrainers}
              onSeeProfile={id => logTrainerProfileViewed(id, TrainerProfileViewContext.interest)}
            />
          </>
        )}
        {!!category.featuredVideos?.length && (
          <>
            <Subtitle>{t('CATEGORY_SCREEN.VIDEOS')}</Subtitle>
            <VideoCarousel
              videos={category.featuredVideos}
              renderItem={video => <CardVideo key={video.id} video={video} />}
              loading={false}
            />
          </>
        )}
      </div>
      <Subtitle>{t('SEARCH_SCREEN.CATEGORIES')}</Subtitle>
      <div className="row gx-3 gy-4">
        {interests?.map(item => (
          <CategoryCard
            key={item.id}
            href={UrlTo.categoryInfo(item.id, item.displayName)}
            interest={item}
            className="col-md-3"
          />
        ))}
      </div>
    </Container>
  );
};

const mapDispatchToProps = {
  loadCategoryInfo,
  logTrainerProfileViewed,
  logInterestViewed,
};

const mapStateToProps = (state, { match }) => ({
  category: state.trainer.categories?.[match.params?.id],
  interests: state.trainer.interests,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CategoryScreen));
