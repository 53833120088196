import React from 'react';
import './styles.scss';

const VideoPreview = ({ data }) => {
  return (
    <div className="video-preview">
      <video src={data.fileUrl} controls />
    </div>
  );
};

export default VideoPreview;
