import React from 'react';
import SingleMenuItem from './SingleMenuItem';

import './styles.scss';

const LoginButtonMenuItem = ({ href, title }) => (
  <>
    {/* desktop version */}
    <li className="trainer-header__menu-item-login trainer-header__menu-item-login__desktop nav-item">
      <a className="trainer-header__menu-item-login-button btn" type="button" href={href}>
        <span className="trainer-header__menu-item-login-button-icon-wrapper">
          <i className="trainer-header__menu-item-login-button-icon fas fa-solid fa-chevron-circle-right" />
        </span>
        <span className="trainer-header__menu-item-login-button-text">{title}</span>
      </a>
    </li>
    {/* mobile version */}
    <div className="trainer-header__menu-item-login__mobile">
      <SingleMenuItem title={title} href={href} />
    </div>
  </>
);

export default LoginButtonMenuItem;
