import React from 'react';
import Modal from 'react-bootstrap/Modal';
import './styles.scss';

const ImageModal = ({ imageUrl, onClose, visible }) => {
  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      className="image-preview-modal"
      size="lg"
      onHide={onClose}
      show={visible}
      centered
    >
      <img className="image-preview-modal__image" src={imageUrl} alt="profile" />
    </Modal>
  );
};

export default ImageModal;
