import React, { useRef } from 'react';
import './styles.scss';

const VideoCarousel = ({ videos, renderItem }) => {
  const itemsContainerRef = useRef();

  return (
    <div>
      <div className="video-carousel__items-container" ref={itemsContainerRef}>
        {videos?.map(item => (
          <div key={item.id} className="video-carousel__item">
            {renderItem(item)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default VideoCarousel;
